import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import orderItems from '../utils/orderItems';
import orderSessionNotes from '../utils/orderSessionNotes';
import ninoxTableGetRow from './../utils/ninoxTableGetRow';
import { BackButton } from './BackButton';
import { BookingsContentSingleTherapistUpdateNoteModal } from './BookingsContentSingleTherapistUpdateNoteModal';

const today = new Date(Date.now());
const todayDate = new Date();
const currentYear = Number(moment(todayDate).format('YY'));
const currentMonth = Number(moment(todayDate).format('MM'));

const currentSchoolYear =
  currentMonth >= 9
    ? {
        startYear: currentYear,
        endYear: currentYear + 1,
        yearRef: currentYear + '/' + (currentYear + 1),
      }
    : {
        startYear: currentYear - 1,
        endYear: currentYear,
        yearRef: currentYear - 1 + '/' + currentYear,
      };
const BookingsContentSingleTherapist = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  const theDate = moment(today).format('YYYY-MM-DD');
  const theTime = moment(today).format('HH:mm');
  const newNoteDefault = {
    Date: theDate,
    Time: theTime,
    'Type of contact': '1:1 session',
    Notes: '',
    'Name and Role': localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
    Caseload: sessionId,
    Role: 1,
    'Direct Hours': '0',
    'Indirect Hours': '0',
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [dateAndTime, setDateAndTime] = useState<any>(null);
  const [formattedDate, setFormattedDate] = useState<any>(today);
  const [formattedTime, setFormattedTime] = useState<any>(today);
  const [formattedDateAndTime, setFormattedDateAndTime] = useState<any>('');
  const [notes, setNotes] = useState<any>([]);
  const [student, setStudent] = useState<any>();
  const [editNote, setEditNote] = useState<any>(0);
  const [updatedNote, setUpdatedNote] = useState<any>('');
  const [newNote, setNewNote] = useState<any>(newNoteDefault);
  const [sessionNotes, setSessionNotes] = useState<any>([]);
  const [sessionNotesSchoolYears, setSessionNotesSchoolYears] = useState<any>([]);
  const [yearFilter, setYearFilter] = useState(currentSchoolYear);
  const [noteLogs, setNoteLogs] = useState<any>([]);
  const newNoteLogDefault = {
    Email: localStorage.getItem('accountEmail'),
    'Sessions + Notes': 'session_note ' + sessionId,
    Text: newNote.Notes,
    accountNumber: sessionId,
    submittedAt: theDate + ' ' + theTime,
    'Session type': newNote['Type of contact'],
  };
  const [newNoteNewLog, setNewNoteNewLog] = useState<any>(newNoteLogDefault);
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolType = queryParameters.get('schoolType');

  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const therapistCaseNotesMsEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const therapistCaseNotesSsEP = process.env.REACT_APP_UL_STUDENTS_SEN;
  const therapistSessionNotesMsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
  const therapistSessionNotesSsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;
  const mSNoteLogs = process.env.REACT_APP_UL_LOGS_MAINSTREAM;
  const sSNoteLogs = process.env.REACT_APP_UL_LOGS_SEN;
  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;

  const baseSessionNotesQuery = `let caseload_id := ${sessionId};
  let students := (select 'Sessions + Notes')[Caseload.Id = caseload_id];
  concat(students);
  `;
  const baseSessionNoteLogsQuery = `let caseload_id := ${sessionId};
  let logs := (select 'Log')[accountNumber = caseload_id];
  concat(logs);
  `;

  const getAllQueryEP = schoolType === 'RegularSchool' ? msQueryEP : ssQueryEP;
  const getAllSessionNotesQuery =
    schoolType === 'RegularSchool' ? baseSessionNotesQuery : baseSessionNotesQuery;
  const getCurrentSchoolsNoteLogs =
    schoolType === 'RegularSchool' ? baseSessionNoteLogsQuery : baseSessionNoteLogsQuery;
  const StudentEP =
    schoolType === 'RegularSchool' ? therapistCaseNotesMsEP : therapistCaseNotesSsEP;
  const NotesEP =
    schoolType == 'RegularSchool' ? therapistSessionNotesMsEP : therapistSessionNotesSsEP;
  const NoteLogsEP = schoolType === 'RegularSchool' ? mSNoteLogs : sSNoteLogs;

  function handleModalOpen(rowId: any) {
    setOpenedModal(true);
    setTheModal(rowId);
  }

  const toast = useToast();
  const navigate = useNavigate();

  const handleTypeOfContact = (e: any) => {
    setNewNote({ ...newNote, 'Type of Contact': e.target.value });
  };
  const handleRole = (e: any) => {
    setNewNote({ ...newNote, Role: e.target.value });
  };

  const handleDirectMinutes = (e: any) => {
    setNewNote({ ...newNote, 'Direct Hours': e.target.value });
  };

  const handleIndirectMinutes = (e: any) => {
    setNewNote({ ...newNote, 'Indirect Hours': e.target.value });
  };

  const handleNoteDetails = (e: any) => {
    let inputValue = e.target.value;
    setNewNote({ ...newNote, Notes: inputValue });
  };
  const handleDateChange = (e: any) => {
    let inputValue = e;
    // console.log(e);
    // new Date().toLocaleDateString('en-GB', {hour: '2-digit', minute: '2-digit', second: '2-digit'})}}
    // let newTime = addHours(inputValue, 1);
    setDateAndTime(inputValue);

    const theDate = moment(inputValue).format('YYYY-MM-DD');
    const theTime = moment(inputValue).format('HH:mm');
    const theDateAndTime = moment(inputValue).format('DD/MM/YYYY HH:mm');
    setNewNote({
      ...newNote,
      Date: theDate,
      Time: theTime,
    });
    setFormattedDate(theDate);
    setFormattedTime(theTime);
    setFormattedDateAndTime(theDateAndTime);
  };

  const typeOfContacts = [
    '1:1 session',
    'Assessment',
    'Observation',    
    'Group session',
    'Meeting/ Discussion',
    'Telephone',
    'Email',
    'Other',
    'DNA',
  ];
  const addNote = (e: any) => {
    try {
      ninoxTableAddRow(NotesEP, { fields: newNote }, bearerToken).then((data) => {
        addNoteLog(e, newNote, data.id);
        setNewNote(newNoteDefault);
        // setNewNote({ ...newNoteDefault, 'Direct Hours': 0, 'Indirect Hours': 0 });
        getSessionNotesFilteredLatestYear();
        setFormattedDateAndTime('');
        setDateAndTime(null);
        toast({
          title: 'Note successfully added.',
          description: 'Your note has been added to the system.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Notes error',
        description: 'You note was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const addNoteLog = (e: any, newNote: any, parentNoteId: any) => {
    try {
      ninoxTableAddRow(
        NoteLogsEP,
        {
          fields: {
            Email: localStorage.getItem('accountEmail'),
            accountNumber: sessionId,
            submittedAt: moment(today).format('YYYY-MM-DD HH:mm'),
            'Sessions + Notes': `${parentNoteId}`,
            Text: newNote.Notes,
            'Session type': `session_note ${parentNoteId}`,
          },
        },
        bearerToken
      ).then(() => {
        toast({
          title: 'Log successfully added.',
          description: 'Your log has been added to the system.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        setNewNoteNewLog(newNoteLogDefault);
        getAllNotesLogs();
      });
    } catch {
      toast({
        title: 'Notes error',
        description: 'You note was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const roles = ['SLT', 'SLTA', 'Student'];
  const txFirstName =
    localStorage.getItem('firstName') !== undefined ? localStorage.getItem('firstName') : '';
  const txLastName =
    localStorage.getItem('lastName') !== undefined ? localStorage.getItem('lastName') : '';

  let notesSchoolYears = [
    sessionNotesSchoolYears &&
      sessionNotesSchoolYears.map((i: any, k: any) => {
        let month = Number(moment(i?.fields['Date']).format('MM'));
        let year = Number(moment(i?.fields['Date']).format('YY'));
        return month < 9 ? year - 1 + '/' + year : year + '/' + (year + 1);
      }),
  ];
  let yearsForFilterListSchoolYears = notesSchoolYears[0];
  let uniqueYearsSchoolYears = yearsForFilterListSchoolYears
    .sort()
    .reverse()
    .filter((element: any, index: any) => {
      return yearsForFilterListSchoolYears.indexOf(element) === index;
    });

  function applySelectedSchoolYear(selectedYear: string) {
    console.log(selectedYear);
    let sYear = Number(selectedYear?.slice(0, 2));
    let eYear = Number(selectedYear?.slice(-2));

    const currentYearFilter = {
      startYear: sYear,
      endYear: eYear,
      yearRef: sYear + '/' + eYear,
    };
    setYearFilter(currentYearFilter);
  }
  function getSessionNotesFilteredLatestYear() {
    sessionNotes &&
      getAllRowsByEncodedQuery(getAllQueryEP, getAllSessionNotesQuery).then((data: any) => {
        const allSessionNotes = data.split(',');
        console.log('getAllRowsByEncodedQuery: ', allSessionNotes);

        data.length > 0 &&
          getMultipleApiData(NotesEP!, bearerToken!, allSessionNotes).then((data: any) => {
            setSessionNotes(
              data
                .filter((i: any) => i.fields['Caseload'] === sessionId)
                .filter(
                  (i: any) =>
                    (Number(moment(i.fields['Date']).format('YY')) === yearFilter.startYear &&
                      Number(moment(i.fields['Date']).format('MM')) >= 9) ||
                    (Number(moment(i.fields['Date']).format('YY')) === yearFilter.endYear &&
                      Number(moment(i.fields['Date']).format('MM')) < 9)
                )
                .sort(orderSessionNotes)
            );
            setSessionNotesSchoolYears(data.filter((i: any) => i.fields['Caseload'] === sessionId));
            console.log(
              'Mapped getAllRowsByEncodedQuery:',
              data
                .filter((i: any) => i.fields['Caseload'] === sessionId)
                .filter(
                  (i: any) =>
                    (Number(moment(i.fields['Date']).format('YY')) === yearFilter.startYear &&
                      Number(moment(i.fields['Date']).format('MM')) >= 9) ||
                    (Number(moment(i.fields['Date']).format('YY')) === yearFilter.endYear &&
                      Number(moment(i.fields['Date']).format('MM')) < 9)
                )
                .sort(orderSessionNotes)
            );
            let notesSchoolYears = [
              data &&
                data.map((i: any, k: any) => {
                  let month = Number(moment(i?.fields['Date']).format('MM'));
                  let year = Number(moment(i?.fields['Date']).format('YY'));
                  return month < 9 ? year - 1 + '/' + year : year + '/' + (year + 1);
                }),
            ];
            let yearsForFilterListSchoolYears = notesSchoolYears[0];
            let uniqueYearsSchoolYears = yearsForFilterListSchoolYears
              .sort()
              .reverse()
              .filter((element: any, index: any) => {
                return yearsForFilterListSchoolYears.indexOf(element) === index;
              });
            console.log('After load', uniqueYearsSchoolYears[0]);
            applySelectedSchoolYear(uniqueYearsSchoolYears[0]);
            getAllNotesLogs();
            // getAllRowsById(NoteLogsEP).then((ndata) => {
            //   setNoteLogs(ndata.filter((i: any) => i.fields.accountNumber == String(sessionId)));
            // });
          });
      });
  }
  function getAllSessionNotes() {
    sessionNotes &&
      getAllRowsByEncodedQuery(getAllQueryEP, getAllSessionNotesQuery).then((data: any) => {
        const allSessionNotes = data.split(',');
        data.length > 0 &&
          getMultipleApiData(NotesEP!, bearerToken!, allSessionNotes).then((data: any) => {
            setSessionNotes(
              data
                .filter((i: any) => i.fields['Caseload'] === sessionId)
                .filter(
                  (i: any) =>
                    (Number(moment(i.fields['Date']).format('YY')) === yearFilter.startYear &&
                      Number(moment(i.fields['Date']).format('MM')) >= 9) ||
                    (Number(moment(i.fields['Date']).format('YY')) === yearFilter.endYear &&
                      Number(moment(i.fields['Date']).format('MM')) < 9)
                )
                .sort(orderSessionNotes)
            );
            setSessionNotesSchoolYears(data.filter((i: any) => i.fields['Caseload'] === sessionId));
            let notesSchoolYears = [
              data &&
                data.map((i: any, k: any) => {
                  let month = Number(moment(i?.fields['Date']).format('MM'));
                  let year = Number(moment(i?.fields['Date']).format('YY'));
                  return month < 9 ? year - 1 + '/' + year : year + '/' + (year + 1);
                }),
            ];
            let yearsForFilterListSchoolYears = notesSchoolYears[0];
            let uniqueYearsSchoolYears = yearsForFilterListSchoolYears
              .sort()
              .reverse()
              .filter((element: any, index: any) => {
                return yearsForFilterListSchoolYears.indexOf(element) === index;
              });
          });
      });
  }
  function getAllNotesLogs() {
    noteLogs &&
      getAllRowsByEncodedQuery(getAllQueryEP, baseSessionNoteLogsQuery).then((data: any) => {
        const allNotesLogs = data.split(',');
        console.log('getAllRowsByEncodedQuery allNotesLogs: ', allNotesLogs);

        data.length > 0 &&
          getMultipleApiData(NoteLogsEP!, bearerToken!, allNotesLogs).then((data: any) => {
            console.log('Mapped note logs:', data);
            setNoteLogs(data.filter((i: any) => i.fields.accountNumber == String(sessionId)));
          });
      });
  }
  function getCurrentStudent() {
    ninoxTableGetRow(String(StudentEP!), Number(sessionId), bearerToken).then((data: any) => {
      setStudent(data);
    });
  }
  useEffect(() => {
    setNewNote(newNoteDefault);
    setNewNote({ ...newNote, 'Type of Contact': '1:1 session', 'Indirect Hours': 0, Role: 1 });
    getAllSessionNotes();
    getCurrentStudent();
  }, [schoolType, notes, yearFilter]);

  useEffect(() => {
    getSessionNotesFilteredLatestYear();
    getAllNotesLogs();
    getCurrentStudent();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        {/* <Box>{JSON.stringify(newNote)}</Box> */}
        <Box p={6} bg="white">
          <Box p={2} mb={5}>
            <HStack spacing={5}>
              <BackButton navigate={navigate} />
              <Heading> {student?.fields['Student/Patient Name']}</Heading>
            </HStack>
          </Box>
          <Flex overflowX={'scroll'}>
            <Stat
              bg={'brand.200'}
              color={'white'}
              p={5}
              borderRadius={5}
              mb={10}
              mr={5}
              h={'120px'}
            >
              <StatLabel h={14}>Total entries</StatLabel>
              <StatNumber>{sessionNotes.length}</StatNumber>
            </Stat>
            {typeOfContacts.map((v: any, k: number) => (
              <Stat bg={'gray.100'} p={5} borderRadius={5} mr={5} mb={10} h={'120px'} key={k}>
                <StatLabel h={14}>{v}</StatLabel>
                <StatNumber>
                  {sessionNotes.filter((i: any) => i?.fields['Type of contact'] === `${v}`).length}
                </StatNumber>
              </Stat>
            ))}
          </Flex>
          <Flex>
            <Box w={'40%'} p={'5'}>
              <HStack>
                <FormControl mb={'5'}>
                  <FormLabel>Add session notes as:</FormLabel>
                  <Input value={`${txFirstName}  ${txLastName}`} isDisabled />
                </FormControl>
              </HStack>
              <FormControl mb={'5'}>
                <FormLabel>Type of Contact: </FormLabel>
                <Select
                  isRequired
                  onChange={(e) => handleTypeOfContact(e)}
                  value={newNote['Type of Contact']}
                >
                  {typeOfContacts?.map((i: any, k: number) => (
                    <option value={i} key={k}>
                      {i}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb={'5'}>
                <FormLabel>Select role: </FormLabel>
                <Select isRequired onChange={(e) => handleRole(e)} value={newNote['Role']}>
                  {roles?.map((i: any, k: number) => (
                    <option value={k + 1} key={k}>
                      {i}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Time and date: </FormLabel>
                <HStack mb={5}>
                  <DatePicker
                    selected={dateAndTime}
                    timeIntervals={15}
                    onChange={handleDateChange}
                    showTimeSelect
                    dateFormat="Pp"
                    customInput={<Button>Select date and time</Button>}
                    required={true}
                  />
                  <Text>{formattedDateAndTime}</Text>
                </HStack>
              </FormControl>
              <HStack mb={5}>
                <FormControl>
                  <FormLabel>Direct minutes: </FormLabel>
                  <HStack mb={5}>
                    <NumberInput size="lg" maxW={32} value={newNote['Direct Hours']} min={0}>
                      <NumberInputField onChange={(e) => handleDirectMinutes(e)} />
                    </NumberInput>
                  </HStack>
                </FormControl>
                <FormControl>
                  <FormLabel>Indirect minutes:</FormLabel>
                  <HStack mb={5}>
                    <NumberInput size="lg" maxW={32} min={0} value={newNote['Indirect Hours']}>
                      <NumberInputField onChange={(e) => handleIndirectMinutes(e)} />
                    </NumberInput>
                  </HStack>
                </FormControl>
              </HStack>
              <FormControl>
                <FormLabel>Notes: </FormLabel>
                <Textarea
                  isRequired
                  placeholder="Enter your note details here"
                  size="3xl"
                  p={'5'}
                  onChange={(e) => handleNoteDetails(e)}
                  value={newNote.Notes}
                  mb={'5'}
                />
              </FormControl>
              <FormControl>
                <Button
                  onClick={(e) => addNote(e)}
                  bg={'brand.300'}
                  _hover={{
                    bg: 'brand.200',
                  }}
                  color={'white'}
                  isDisabled={newNote.Notes.length === 0 || dateAndTime === null ? true : false}
                  mt={'5'}
                >
                  Add note
                </Button>
              </FormControl>
            </Box>

            <Box w={'60%'} p={'1'} borderColor={'#cccccc'} borderRadius={'5'}>
              <Flex justifyContent={'flex-end'} alignItems={'right'}>
                <Box mt={8}>
                  <Button
                    bg={'brand.300'}
                    color={'white'}
                    _hover={{
                      background: 'brand.200',
                    }}
                    onClick={() => {
                      navigate('/edit-student/' + sessionId + '?schoolType=' + schoolType);
                    }}
                    mr={5}
                  >
                    Manage
                  </Button>
                  <Button
                    bg={'brand.300'}
                    color={'white'}
                    _hover={{
                      background: 'brand.200',
                    }}
                    onClick={() => {
                      navigate('/toms-rating/' + sessionId + '?schoolType=' + schoolType);
                    }}
                    mr={5}
                  >
                    TOMS
                  </Button>
                </Box>
                <Box w={'1xl'} mr={'5'} mb={5}>
                  <FormLabel fontWeight={'bold'}>School year: </FormLabel>
                  <Select
                    name="yearFilter"
                    isRequired
                    onChange={(e: any) => applySelectedSchoolYear(e.target.value)}
                    value={yearFilter.yearRef}
                  >
                    {uniqueYearsSchoolYears.map((i: any, k: number) => (
                      <option value={i}>{i}</option>
                    ))}
                  </Select>
                </Box>
              </Flex>

              <Tabs variant="soft-rounded" colorScheme="green" align="end">
                <TabList>
                  <Tab>Notes</Tab>
                  <Tab>Note Logs</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <TableContainer className="sessionNotesTableContainer">
                      <Table variant="striped" colorScheme={'green'} bg={'white'}>
                        {sessionNotes?.length <= 0 && (
                          <TableCaption>No notes posted yet!</TableCaption>
                        )}
                        <Thead position={'sticky'} top={-1} left={0} bg={'white'}>
                          <Tr bg={' className="smallSessionNoteColumns"white'}>
                            <Th className="smallSessionNoteColumnHeaders">Session Date</Th>
                            <Th className="smallSessionNoteColumnHeaders">Session Time</Th>
                            <Th className="smallSessionNoteColumnHeaders">Type of contact</Th>
                            <Th className="largeSessionNoteColumn">Session Notes</Th>
                            <Th>Direct Minutes</Th>
                            <Th>Indirect Minutes</Th>
                            <Th>Name</Th>
                            <Th>Role</Th>
                            <Th>Created At</Th>
                          </Tr>
                        </Thead>
                        <Tbody height={'60vh'} overflowY={'scroll'}>
                          {sessionNotes &&
                            sessionNotes.sort(orderItems).map((i: any, k: any) => {
                              let myfields = i.fields;
                              return (
                                <Tr
                                  key={k}
                                  onClick={() => {
                                    handleModalOpen(i.id);
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <Td className="smallSessionNoteColumns">
                                    {myfields && i.fields['Date']}
                                  </Td>
                                  <Td className="smallSessionNoteColumns">
                                    {myfields && i.fields['Time']}
                                  </Td>
                                  <Td className="smallSessionNoteColumns">
                                    {myfields && i.fields['Type of contact']}
                                  </Td>
                                  <Td style={{ minWidth: '400px!important' }}>
                                    {myfields && i.fields['Notes']}
                                  </Td>
                                  <Td>{myfields && i.fields['Direct Hours']}</Td>
                                  <Td>{myfields && i.fields['Indirect Hours']}</Td>
                                  <Td>
                                    {myfields && i.fields['Name and Role'] === undefined
                                      ? 'Not provided'
                                      : i.fields['Name and Role']}
                                  </Td>
                                  <Td>{myfields && i.fields['Role']}</Td>
                                  <Td>{moment(i.createdAt).format('YYYY/MM/DD HH:mm')}</Td>
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    {sessionNotes &&
                      sessionNotes
                        // ?.filter((i: any) => i?.fields['Caseload'] === sessionId)
                        .map((i: any, k: number) => (
                          <BookingsContentSingleTherapistUpdateNoteModal
                            key={i?.id}
                            note={i}
                            id={i?.id}
                            modalState={openedModal}
                            theModal={theModal}
                            setTheModal={setTheModal}
                            setOpenedModal={setOpenedModal}
                            updatedNote={updatedNote}
                            setUpdatedNote={setUpdatedNote}
                            schoolType={schoolType}
                            therapistSessionNotesRegularSchoolsEP={therapistSessionNotesMsEP}
                            therapistSessionNotesSENSchoolsEP={therapistSessionNotesSsEP}
                            toast={toast}
                            bearerToken={bearerToken}
                            sessionNotes={sessionNotes}
                            setSessionNotes={setSessionNotes}
                            sessionId={sessionId}
                            signedInUserEmail={signedInUserEmail}
                            noteLogs={noteLogs}
                            setNoteLogs={setNoteLogs}
                            setYearFilter={setYearFilter}
                            getAllNotesLogs={getAllNotesLogs}
                            getAllSessionNotes={getAllSessionNotes}
                          />
                        ))}
                  </TabPanel>
                  <TabPanel>
                    <TableContainer className="sessionNotesTableContainer">
                      <Table variant="striped" colorScheme={'green'}>
                        {noteLogs?.length <= 0 && (
                          <TableCaption>No logs recorded yet!</TableCaption>
                        )}
                        <Thead>
                          <Tr>
                            <Th>id</Th>
                            <Th maxWidth={'200px'} overflow={'scroll'}>
                              Text
                            </Th>
                            <Th>Sessions type</Th>
                            <Th>accountNumber</Th>
                            <Th>submittedAt</Th>
                            <Th>Sessions + Notes</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {noteLogs &&
                            noteLogs.sort(orderItems).map((i: any, k: any) => {
                              let myfields = i.fields;
                              // console.log(noteLogs);
                              return (
                                <Tr
                                  key={k}
                                  onClick={() => {
                                    handleModalOpen(i.id);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Td>{i.id}</Td>
                                  <Td maxWidth={'200px'} overflow={'scroll'}>
                                    {myfields && i.fields['Text']}
                                  </Td>
                                  <Td>{myfields && i.fields['Session type']}</Td>
                                  <Td>{myfields && i.fields['accountNumber']}</Td>
                                  <Td>
                                    {myfields &&
                                      moment(i.fields['submittedAt']).format('YYYY/MM/DD HH:mm')}
                                  </Td>
                                  <Td>{myfields && i.fields['Sessions + Notes']}</Td>
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentSingleTherapist;
