import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Grid,
  Text,
  Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from '../../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../../utils/ninoxTableUpdateRow';
// import { privateForm } from '../interfaces/privateForm';
import { auth } from './../../auth/fireBaseSetup';

const CreateAccount = ({ title, nextStep }: any) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const ul_cl_id = queryParameters.get('ul_cl');
  const [user, setUser]: any = useState();
  const [signUpError, setSignUpError] = useState<any>('');
  const [edit, setEdit] = useState(0);

  const defaultForm = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    postCode: '',
    password: '',
    cpassword: '',
  };
  const [form, setForm] = useState<any>(defaultForm);
  const clientToUpdate = {
    'Account status': true,
  };
  function updateClientInfo() {
    ninoxTableUpdateRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(ul_cl_id),
      { fields: clientToUpdate },
      String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
    ).then((data: any) => {
      getUserData();
      setEdit(0);
    });
  }

  const createAccount = async () => {
    console.log('Account creation triggered');
    try {
      updateClientInfo();
      await auth.createUserWithEmailAndPassword(form.email, form.password);
      console.log('Private client account created!');
    } catch (error) {
      console.error(error);
      setSignUpError(error);
      console.log('Account creation failed');
    }
  };
  function sighUpAndRedirect() {
    createAccount();
    setEdit(2);
  }

  function getUserData() {
    ninoxTableGetRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(ul_cl_id),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      setUser(data);
      setForm({
        ...form,
        email: data?.fields['Primary Email Address'],
      });
    });
  }
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Box w="100%" p={4}>
      {/* <Heading as="h3" size="lg">
        {title}
      </Heading> */}
      <Text mb={'5'}>Select a password for your account</Text>
      {user?.fields['Account status'] ? (
        <Box color={'blue.500'} fontWeight={'bold'}>
          You seem to have already set a password for your account
        </Box>
      ) : (
        <Box display={'flex'} flexDirection={'row'}>
          <Box w={'30%'} mr={5}>
            <FormControl>
              <FormLabel htmlFor="password" fontWeight={'bold'}>
                Password
              </FormLabel>
              <Input
                id="password"
                placeholder="Enter password"
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                value={form?.password}
                type="password"
                isRequired={true}
                bg={'white'}
              />
            </FormControl>
          </Box>
          <Box w={'30%'} mr={5}>
            <FormControl>
              <FormLabel htmlFor="password" fontWeight={'bold'}>
                Confirm Password
              </FormLabel>
              <Input
                id="cpassword"
                placeholder="Enter password again"
                onChange={(e) => setForm({ ...form, cpassword: e.target.value })}
                value={form?.cpassword}
                type="password"
                isRequired={true}
                bg={'white'}
              />
            </FormControl>
          </Box>
          <Box w={'30%'} mr={5}>
            <FormControl>
              <Button
                bg={'brand.300'}
                color={'white'}
                _hover={{
                  bg: 'brand.200',
                }}
                width={'200px'}
                onClick={() => {
                  sighUpAndRedirect();
                }}
                mt={8}
                isDisabled={
                  form.password === form.cpassword ||
                  form.password === null ||
                  form.cpassword === null
                    ? false
                    : true
                }
              >
                Set password
              </Button>
            </FormControl>
          </Box>
        </Box>
      )}

      {signUpError && (
        <Box color={'red.500'} mb={10} fontWeight={'bold'} mt={5}>
          {signUpError?.code === 'auth/email-already-in-use'
            ? 'It seems like you already have an account with us.'
            : signUpError?.code === 'auth/missing-password'
            ? 'Missing password! Password is a mandatory field. Please enter a password'
            : signUpError?.code === 'auth/weak-password'
            ? 'Weak password! Please enter a stronger password'
            : signUpError?.code}
        </Box>
      )}

      <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
        <Button
          variant={'defaultButton'}
          mt={5}
          onClick={() => nextStep(2)}
          isDisabled={user?.fields['Account status'] ? false : true}
          _hover={{ backgroundColor: 'brand.200' }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
export default CreateAccount;
