import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Grid,
  Text,
  Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from './../../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from './../../utils/ninoxTableUpdateRow';
// import { privateForm } from '../interfaces/privateForm';

const StepOne = ({ form, setForm, nextStep }: any) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const ul_cl_id = queryParameters.get('ul_cl');
  const [user, setUser]: any = useState();
  const [signUpError, setSignUpError] = useState<any>('');
  const [edit, setEdit] = useState(0);

  function getUserData() {
    ninoxTableGetRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(ul_cl_id),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      setUser(data);
      setForm({
        ...form,
        firstName: data?.fields['First Name'],
        lastName: data?.fields['Last Name'],
        email: data?.fields['Primary Email Address'],
        contactNumber: data?.fields['Phone'],
        addressLineOne: data?.fields['Address'],
        addressLineTwo: data?.fields['Xero Contact Street Address'],
        city: data?.fields['Xero Contact Area Address'],
        postCode: data?.fields['Xero Contact Area Address'],
        password: '',
        cpassword: '',
      });
    });
  }

  const clientToUpdate = {
    'First Name': form.firstName,
    'Last Name': form.lastName,
    'Primary Email Address': form.email,
    Phone: form.contactNumber,
    Address: form.addressLineOne,
    'Xero Contact Street Address': form.addressLineTwo,
    // 'Xero Contact Street Address': form.city,
    // 'Xero Contact Street Address': form.postCode,
  };

  function updateClientInfo() {
    ninoxTableUpdateRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(ul_cl_id),
      { fields: clientToUpdate },
      String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
    ).then((data: any) => {
      console.log('saved:' + data);
      getUserData();
      setEdit(0);
    });
  }
  {
    signUpError && (
      <Box color={'red.500'} mb={10} fontWeight={'bold'}>
        {signUpError?.code === 'auth/email-already-in-use'
          ? 'You already have an account with us. Have you tried sigining in?'
          : signUpError?.code === 'auth/missing-password'
          ? 'Missing password! Password is a mandatory field. Please enter a password'
          : signUpError?.code === 'auth/weak-password'
          ? 'Weak password! Please enter a stronger password'
          : signUpError?.code}
      </Box>
    );
  }

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Box w="100%" p={4}>
      {/* <Heading as="h3" size="lg">
        {title}
      </Heading> */}
      <HStack>
        <Text mb={'5'} maxW={'80%'}>
          Please provide your personal information, including your name, address, and contact
          number. Subsequently, you will also create your login credentials.
        </Text>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          // justifyContent={'right'}
          mb={'5'}
          width={'20%'}
        >
          <FormControl mb="5%" display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
            <FormLabel htmlFor="" fontWeight={'bold'}></FormLabel>
            {edit === 0 ? (
              <Button
                variant={'defaultButton'}
                onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
                ml={5}
              >
                Edit
              </Button>
            ) : (
              <HStack ml={5}>
                <Button
                  variant={'defaultButton'}
                  onClick={() => updateClientInfo()}
                  isDisabled={
                    form.firstName.length <= 0 ||
                    form.lastName.length <= 0 ||
                    form.email.length <= 0 ||
                    form.contactNumber.length <= 0
                      ? true
                      : false
                  }
                  _hover={{ backgroundColor: 'brand.300' }}
                >
                  Save
                </Button>
                <Button
                  variant={'defaultButton'}
                  onClick={() => {
                    if (edit === 0) {
                      setEdit(1);
                    } else {
                      setEdit(0);
                      getUserData(); // Call getUserData only when edit is set to 1
                    }
                  }}
                >
                  Cancel
                </Button>
              </HStack>
            )}
          </FormControl>
        </Box>
      </HStack>

      <Box display={'flex'} flexDirection={'row'}>
        <Box w={'30%'} mr={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="first-name" fontWeight={'bold'}>
              First name
            </FormLabel>
            {edit === 0 ? (
              user?.fields['First Name']
            ) : (
              <Input
                id="first-name"
                placeholder="First name"
                value={form.firstName}
                onChange={(e) => setForm({ ...form, firstName: e.target.value })}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="last-name" fontWeight={'bold'}>
              Last name
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Last Name']
            ) : (
              <Input
                id="last-name"
                placeholder="Last name"
                value={form.lastName}
                onChange={(e) => setForm({ ...form, lastName: e.target.value })}
              />
            )}
          </FormControl>
        </Box>

        <Box w={'30%'} mr={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="email" fontWeight={'bold'}>
              Email
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Primary Email Address']
            ) : (
              <Input
                id="email"
                placeholder="Email address"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="contact-number" fontWeight={'bold'}>
              Contact number
            </FormLabel>
            {edit === 0 ? (
              user?.fields.Phone
            ) : (
              <Input
                id="contact-number"
                placeholder="Contact number"
                value={form.contactNumber}
                onChange={(e) => setForm({ ...form, contactNumber: e.target.value })}
              />
            )}
          </FormControl>
        </Box>
        <Box w={'30%'} mr={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="address-line-1" fontWeight={'bold'}>
              Full address &amp; Postcode
            </FormLabel>
            {edit === 0 ? (
              user?.fields.Address === undefined ? (
                'Not provided'
              ) : (
                user?.fields.Address
              )
            ) : (
              <Input
                id="address-line-1"
                placeholder="Address line 1"
                value={form.addressLineOne}
                onChange={(e) => setForm({ ...form, addressLineOne: e.target.value })}
              />
            )}
          </FormControl>

          {/* <FormControl mb="5%">
            <FormLabel htmlFor="address-line-2" fontWeight={'bold'}>
              Address line 2
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Xero Contact Street Address'] === undefined ? (
                'Not provided'
              ) : (
                user?.fields['Xero Contact Street Address']
              )
            ) : (
              <Input
                id="address-line-2"
                placeholder="Address line 2"
                value={form.addressLineTwo}
                onChange={(e) => setForm({ ...form, addressLineTwo: e.target.value })}
              />
            )}
          </FormControl> */}

          {/* <FormControl mb="5%">
            <FormLabel htmlFor="city" fontWeight={'bold'}>
              City
            </FormLabel>
            <Input
              id="city"
              placeholder="City"
              value={form.city}
              onChange={(e) => setForm({ ...form, city: e.target.value })}
            />
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="post-code" fontWeight={'bold'}>
              Post code
            </FormLabel>
            <Input
              id="post-code"
              placeholder="Post code"
              value={form.postCode}
              onChange={(e) => setForm({ ...form, postCode: e.target.value })}
            />
          </FormControl> */}
        </Box>
      </Box>

      <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
        <Button
          variant={'defaultButton'}
          mt={5}
          onClick={() => nextStep(1)}
          _hover={{ backgroundColor: 'brand.200' }}
          isDisabled={
            form.firstName.length <= 0 ||
            form.lastName.length <= 0 ||
            form.email.length <= 0 ||
            form.contactNumber.length <= 0
              ? true
              : false
          }
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};
export default StepOne;
