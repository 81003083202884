import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Modal,
  ModalOverlay,
  Stack,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
  useDisclosure,
  Progress,
  Select,
  Spinner,
} from '@chakra-ui/react';

import { CloseIcon, Search2Icon } from '@chakra-ui/icons';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import {
  avgInteractionsHint,
  gendersHint,
  nextSessionHint,
  showDischargedHint,
  therapyLocationHint,
  totalPrivateClientsHint,
} from '../constants/clientHints';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getAllRowsById from '../utils/getAllRowsById';
import getMultipleApiData from '../utils/getMultipleApiData';
import getMultipleApiDataNonAsync from '../utils/getMultipleApiDataNonAsync';
import sortDataByParameter from '../utils/sortDataByParameter';
import sorterIcon from '../utils/sorterIcon';
import { AddClientsForm } from './AddClientsForm';
import { BackButton } from './BackButton';
import SelectRS from 'react-select';

const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;
interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
// type StateContextType = {
//   userDetails: userSessionDataProps | null;
//   setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
// };
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

interface UserMeta {
  firstName: string;
  lastName: string;
  accessLevel: string;
  accountType: string;
  messages: number[];
  stats: number[];
  invoices: number[];
}
interface Note {
  nid: number;
  session_id: number;
  note_type: string;
  note_owner: number;
  note_details: string;
  posted_at: Date;
  modified_at: Date;
}
const today = new Date();
const formattedToday = moment(new Date()).format('YYYY-MM-DD');
// const now = moment(new Date()); //todays date

const BookingsContentClients = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserProps) => {
  // const { userDetails, updateUser } = useContext(userDetailsContext);
  // updateUser(signedInUserId);

  const newNoteDefault = {
    nid: 0,
    session_id: 0,
    note_type: 'brief_note',
    note_owner: signedInUserId,
    note_details: '',
    posted_at: today,
    modified_at: today,
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [loadingClients, setLoadingClients] = useState<number>(0);
  const [loadingAllClients, setLoadingAllClients] = useState<number>(0);
  const [genders, setGenders] = useState<any>({});
  const [notes, setNotes] = useState<Note[] | []>([]);
  const [newNote, setNewNote] = useState<Note>(newNoteDefault);
  const [patients, setPatients] = useState<any>([]);
  const [caseNotes, setCaseNotes] = useState<any>([]);
  const [caseNotesMyClients, setCaseNotesMyClients] = useState<any>([]);
  const [caseNotesAllClients, setCaseNotesAllClients] = useState<any>([]);
  const [revertDischarge, setRevertDischarge] = useState<number>(0);
  const [sessionNotes, setSessionNotes] = useState<any>([]);
  const [schools, setSchools] = useState<any>([]);
  const [senSchools, setSenSchools] = useState<any>([]);
  const [tomsRating, setTomsRating] = useState<any>([]);
  const [allClassYears, setAllClassYears] = useState<[]>([]);
  const [showAddStudent, setShowAddStudent] = useState<number>(0);
  const [schoolFilter, setSchoolFilter] = useState(0);
  const [allSchoolFilter, setAllSchoolFilter] = useState(0);
  const [showDischarged, setShowDischarged] = useState<number>(0);

  const [size, setSize] = useState('md');
  const [rows, setRows] = useState<any>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueLowerCase, setSearchValueLowerCase] = useState<string>('');
  const [activeSort, setActiveSort] = useState<string>('None');
  const [activeSearch, setActiveSearch] = useState(0);
  const [notesPerSchool, setNotesPerSchool] = useState(0);
  const [sorter, setSorter] = useBoolean();
  const [schoolSelected, setSchoolSelected] = useState<any>('');
  const [caseNoteCount, setCaseNoteCount] = useState<any>([]);
  const [studentAges, setStudentAges] = useState<any>([]);
  const [showAllColumns, setShowAllColumns] = useState<number>(0);
  const [nextSessionData, setNextSessionData] = useState<any>([]);
  const [caseNoteCountTracker, setCaseNoteCountTracker] = useState<number>(0);
  interface ItemClientNextSession {
    clientId: string;
    sessionDateAndTime: number;
  }
  interface ItemClientLastSession {
    clientId: string;
    sessionDateAndTime: number;
  }

  const [clientNextSessions, setClientNextSessions] = useState<Array<ItemClientNextSession>>([]);
  const [clientLastSessions, setClientLastSessions] = useState<Array<ItemClientLastSession>>([]);

  const [loadingCns, setLoadingCns] = useState(0);
  const [loadingCls, setLoadingCls] = useState(0);

  const handleSearch = (e: any) => {
    let searchInput = e.target.value;
    let lowerSearchInput = searchInput.toLowerCase();
    setSearchValue(searchInput);
    setSearchValueLowerCase(lowerSearchInput);
    // console.log(lowerSearchInput);
    setActiveSearch(1);
  };

  function clearSearch() {
    setSearchValue('');
    setSearchValueLowerCase('');
    setActiveSearch(0);
    getAllRowsByEncodedQuery(getAllStudentsEP, getAllStudentsQuery).then((data: any) => {
      const allStudents = data.split(',');
      // console.log(allStudents);
      data?.length > 0 &&
        getMultipleApiData(getCurrentSchoolsStudents!, bearerToken!, allStudents).then(
          (data: any) => {
            setCaseNotes(
              data
                .filter(
                  (i: any) =>
                    i.fields['Date discharged'] === undefined ||
                    i.fields['Date discharged'] === null ||
                    i.fields['Date discharged'] === ''
                )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['First Name'].localeCompare(a?.fields['First Name'])
                    : a?.fields['First Name'].localeCompare(b?.fields['First Name'])
                )
            );
            setLoadingClients(1);

            getCaseNoteCount(
              data
                .filter(
                  (i: any) =>
                    i.fields['Date discharged'] === undefined ||
                    i.fields['Date discharged'] === null ||
                    i.fields['Date discharged'] === ''
                )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['First Name'].localeCompare(a?.fields['First Name'])
                    : a?.fields['First Name'].localeCompare(b?.fields['First Name'])
                )
            );
          }
        );
      // getCaseNoteCount(allStudents);
    });
  }

  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const studentClasses = ['N', 'R', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const IsolatedModal = ({ modalState }: any) => {
    return (
      <Box as="section">
        <Modal
          onClose={() => {
            setTheModal(0);
            setOpenedModal(false);
          }}
          size={'2xl'}
          isOpen={modalState}
        >
          <ModalOverlay />
          <AddClientsForm
            schoolFilter={schoolFilter}
            setSchoolFilter={setSchoolFilter}
            setCaseNotes={setCaseNotes}
            regularSchoolsEP={regularSchoolsEP}
            senSchoolsEP={senSchoolsEP}
            therapistCaseNotesRegularSchoolsSEP={therapistCaseNotesRegularSchoolsSEP}
            therapistCaseNotesSENSchoolsEP={therapistCaseNotesSENSchoolsEP}
            studentClasses={studentClasses}
            tabIndex={tabIndex}
            setTheModal={setTheModal}
            setOpenedModal={setOpenedModal}
          />
        </Modal>
      </Box>
    );
  };

  function handleModalOpen(school: string) {
    // console.log('We added a student to ' + school);
    setOpenedModal(true);
    // console.log(openedModal);
  }
  function handleModalClose(rowId: any, rowSession: any) {
    // console.log('We tried to close ' + rowId + ' and the data was ' + JSON.stringify(rowSession));
    setOpenedModal(false);
    setTheModal(rowId);
    // console.log(openedModal);
  }
  // const [overlay, setOverlay] = useState<ReactNode>(<IsolatedModal session={0} />);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allProfilesFullUrl = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const userStats = userMeta && userMeta?.stats;
  const therapistsTable = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolID = queryParameters.get('schoolID');
  const schoolType = queryParameters.get('schoolType');
  const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  const accountType = schoolType === 'RegularSchool' ? 'Mainstream' : 'SEN Schools';
  const schoolInView = Number(schoolID);
  const therapistSessionNotesRegularSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
  const therapistSessionNotesSENSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;

  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;

  // console.log(clientFields.fields?.map((i: any) => i.name));
  // ${Number(schoolID)}
  const baseMsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['Mainstream School'.Id = school_id];
concat(students)`;

  const baseSsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['SEN schools'.Id = school_id];
concat(students)`;

  const getAllStudentsEP = schoolType === 'RegularSchool' ? msQueryEP : ssQueryEP;
  const getAllStudentsQuery = schoolType === 'RegularSchool' ? baseMsQuery : baseSsQuery;
  const getCurrentSchoolsStudents =
    schoolType === 'RegularSchool' ? caseNotesMainstream : caseNotesSEN;

  const txAccountNumber = Number(localStorage.getItem('accountNumber'));

  // Clients query EP:
  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

  // Clients query:
  const therapist_id = txAccountNumber;

  const clientsQuery = `
  let therapist_id := ${therapist_id};
  let a := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.id = therapist_id and 'Date discharged' = null];
  let string := [""];
  for i in a do
    string := array(string, [i.Id + "_" + i.'First Name' + " " + i.'Last Name'])
  end;
  slice(string, 1, length(string) + 1)`;

  const clientsQueryOld = `let therapist_id := ${therapist_id};
    let students := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.id = therapist_id]
    concat(students);
    `;

  // const allClientsQuery = `let a := (select 'Central Client Database - ALL STAFF USAGE');
  // let string := [""];
  // for i in a do
  // 	string := array(string, [i.Id + "_" + i.'First Name' + "_" + i.'Last Name'])
  // end;
  // slice(string, 1, length(string) + 1)`;

  // const allClientsQuery = `let a := (select 'Central Client Database - ALL STAFF USAGE');
  // let string := [""];
  // for i in a do
  //   string := array(string, [i.Id+"_"])
  // end;
  // "[" + slice(string, 1, length(string) + 1) + "]"`;

  // ('1357 - Mark Smith');
  // [
  //   {
  //     id: '1357',
  //     firstName: 'Mark',
  //     lastName: 'Smith',
  //   },
  //   {
  //     id: '1358',
  //     firstName: 'Ahad',
  //     lastName: 'Ali',
  //   },
  // ];

  // Client query data EP:
  const clientsListEP = process.env.REACT_APP_CLIENTS_LIST_EP;

  // const getAllStudents = tabIndex === 0 ? caseNotesMainstream : caseNotesSEN;

  const NotesEP =
    schoolType == 'RegularSchool'
      ? therapistSessionNotesRegularSchoolsEP
      : therapistSessionNotesSENSchoolsEP;

  function getCaseNoteCount(students: any) {
    // console.log('students: ', students);
    getAllRowsById(NotesEP).then((data) => {
      const myData = data;
      // setSessionNotes(myData);
      setCaseNoteCount([]);
      students.map((i: any, k: any) => {
        // for each student get array of casenotes
        setStudentAges(
          students.map((t: any, k: any) => {
            const born = moment(t?.fields['DOB']).format('YYYY-MM-DD');
            var a = moment(formattedToday);
            var b = moment(born);
            const dobTest = a.diff(b, 'years');

            return dobTest;
          })
        );
        myData.filter((z: any) => Number(z.fields['Caseload']) === i.id)?.length > 0 &&
          setCaseNoteCount((caseNoteCount: any) => [
            ...caseNoteCount,
            myData.filter((z: any) => Number(z.fields['Caseload']) === i.id)?.length,
          ]);
        // setCaseNoteCountTracker(1);
        // console.log(myData.filter((z: any) => Number(z.fields['Caseload']) === i.id));
      });
    });
  }

  useEffect(() => {
    setSignedInUserType('clinical');

    const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
    const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
    const EP = process.env.REACT_APP_USER_DATA;
    let params = new URLSearchParams();
    let table = 'Sessions + Notes';
    params.set('query', table);
    const notesCountRegSchool = `${process.env.REACT_APP_MS_QUERY_EP}?` + params;
    const notesCountSenSchool = `${process.env.REACT_APP_SS_QUERY_EP}?query=let%20a%20%3A%3D%20${schoolID}%3B%20count((select%20'Sessions%20%2B%20Notes')%5BCaseload.'SEN%20Schools'.Id%20%3D%20a%5D)`;
    const NotesCountEP = schoolType == 'RegularSchool' ? notesCountRegSchool : notesCountSenSchool;

    setNewNote(newNoteDefault);
    getAllRowsById(regularSchoolsEP).then((data) => {
      const myData = data;
      setSchools(myData);
    });
    getAllRowsById(senSchoolsEP).then((data) => {
      const myData = data;
      setSenSchools(myData);
    });

    const delayDebounceFn = setTimeout(() => {
      searchValueLowerCase?.length >= 1 &&
        setCaseNotes(
          caseNotes.filter((caseNote: any) => {
            return caseNote?.fields['First Name']
              .toLocaleLowerCase()
              .includes(searchValueLowerCase);
          })
        );
    }, 500);

    setSchoolFilter(txAccountNumber);
    setShowAddStudent(Number(schoolID));
    setSchoolSelected(schools.filter((i: schoolsFil) => i.id === schoolInView));
    // setSchoolFilter(Number(51));
    navigate('/clients?schoolID=' + txAccountNumber + '&schoolType=RegularSchool');
    return () => clearTimeout(delayDebounceFn);
  }, [searchValueLowerCase]);

  const nextSessionDashbaordQuery = `
  let now := today();
  let sess := (select 'Session Stats')['Session Start' >= now and
      'Central Client Database - ALL STAFF USAGE'.'Current Therapist Assigned'.Id = ${schoolID}];
      "" + format(first(sort(sess)).'Session Start', "x") + "," + first(sort(sess)).'Central Client Database - ALL STAFF USAGE'.'First Name' +" " + first(sort(sess)).'Central Client Database - ALL STAFF USAGE'.'Last Name'+", "+first(sort(sess)).'Central Client Database - ALL STAFF USAGE'.'id'+""
  `;

  const nextSessionStatQuery = `
  let now := today();
  let clients := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.Id = ${schoolID}];
  let arr := [""];
  for j in clients do
    let sess := (select 'Session Stats')['Session Start' >= now and 'Central Client Database - ALL STAFF USAGE'.Id = j.Id];
    arr := array(arr, [format(first(sort(sess)).'Session Start', "x")+"_"+j.Id ])
  end;
  arr`;

  const lastSessionStatQuery = `
  let now := today();
  let clients := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.Id = ${schoolID}];
  let arr := [""];
  for j in clients do
    let sess := (select 'Session Stats')['Session Start'  < now and  'Central Client Database - ALL STAFF USAGE'.Id = j.Id];
    arr := array(arr, [format(first(sort(sess)).'Session Start', "x")+"_"+j.Id ])
  end;
  arr`;
  // const lastsessionQuery =`
  // let now := today();
  // let sess := (select 'Session Stats')['Session Start' < now and 'Central Client Database - ALL STAFF USAGE'.Id = ${clientId}].'Session Start';
  // format(last(sort(sess)), "x")`;
  // arr := array(arr, [ "ss:" + format(first(sort(sess)).'Session Start', "x") + " - " + j.Id ])

  // arr := array(arr, { "nextSession:" + "test", "clientID": j.Id })
  // const test = array(arr, [ {"{ss:" + format(first(sort(sess)).'Session Start', "x") +  ", nextSession: " + j.Id  + "}"])
  function createObjectMap(value: string) {
    const [dateAndTime, clientId] = value.split('_');
    return {
      sessionDateAndTime: dateAndTime || 0,
      clientId: clientId,
    };
  }

  // https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/ey8729p64s59/tables/H/records/1946
  // https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/ey8729p64s59/tables/H/records/1946

  useEffect(() => {
    const allClientsQuery = `let a := (select 'Central Client Database - ALL STAFF USAGE');
    let string := [""];
    for i in a do
      string := array(string, [i.Id + "_" + i.'First Name' + " " + i.'Last Name'])
    end;
    slice(string, 1, length(string) + 1)`;

    // Getting all clients
    getAllRowsByEncodedQuery(clientsQueryEP, allClientsQuery).then((data: any) => {
      const objectsArray = data.map((val: any) => {
        const underscoreIndex = val.indexOf('_');
        const id = underscoreIndex === -1 ? '' : val.substring(0, underscoreIndex);
        const cname = underscoreIndex === -1 ? '' : val.substring(underscoreIndex + 1);

        return {
          value: id === '' ? 0 : id,
          label: cname === '' || cname === ' ' ? 'Name not found' : id + ') ' + cname,
        };
      });

      // console.log(
      //   'Date discharged:',
      //   objectsArray.filter((z: any) => z.fields['Date discharged'])
      // );
      setCaseNotesAllClients(objectsArray);
      setLoadingAllClients(1);
      // Getting Therapist specific clients
      getAllRowsByEncodedQuery(clientsQueryEP, clientsQuery).then((data: any) => {
        const objectsArray = data.map((val: any) => {
          const underscoreIndex = val.indexOf('_');
          const id = underscoreIndex === -1 ? '' : val.substring(0, underscoreIndex);
          const cname = underscoreIndex === -1 ? '' : val.substring(underscoreIndex + 1);

          return {
            value: id === '' ? 0 : id,
            label: cname === '' || cname === ' ' ? 'Name not found' : id + ') ' + cname,
          };
        });

        console.log('clientsQuery', data);
        setCaseNotesMyClients(objectsArray);

        // setCaseNotes(data);
        // getCaseNoteCount(data);

        // get clients next session
        getAllRowsByEncodedQuery(clientsQueryEP, nextSessionDashbaordQuery).then((data: any) => {
          const obj = data.split(',');
          getAllRowsByEncodedQuery(clientsQueryEP, clientsQueryOld).then((data: any) => {
            const allStudents = data.split(', ');
            console.log('allStudents:', allStudents);
            data?.length > 0 &&
              getMultipleApiDataNonAsync(clientsListEP!, bearerToken!, allStudents).then(
                (data: any) => {
                  setLoadingClients(1);
                  console.log('Case note data', data);
                  setCaseNotes(data);
                  // getCaseNoteCount(data);
                }
              );
          });
          setNextSessionData(obj);
          // get clients next sessions
          getAllRowsByEncodedQuery(clientsQueryEP, nextSessionStatQuery).then((data: any) => {
            const arrayOfObjects = data?.map(createObjectMap);
            setLoadingCns(1);
            setClientNextSessions(arrayOfObjects);
            // get clients last sessions
            getAllRowsByEncodedQuery(clientsQueryEP, lastSessionStatQuery).then((data: any) => {
              const arrayOfObjects = data?.map(createObjectMap);
              setLoadingCls(1);
              setClientLastSessions(arrayOfObjects);
              // get case note data
            });
          });
        });
      });
    });
  }, []);

  // useEffect(() => {}, [caseNotesAllClients]);
  // useEffect(() => {
  //   // get clients next session
  //   getAllRowsByEncodedQuery(clientsQueryEP, nextSessionDashbaordQuery).then((data: any) => {
  //     const obj = data.split(',');
  //     setNextSessionData(obj);
  //   });
  //   // get clients next sessions
  //   getAllRowsByEncodedQuery(clientsQueryEP, nextSessionStatQuery).then((data: any) => {
  //     function createObject(value: any) {
  //       const dateAndTime = value.substring(0, value.indexOf('_'));
  //       const clientId = value.split('_').pop();
  //       return {
  //         sessionDateAndTime: dateAndTime === '' ? 0 : dateAndTime,
  //         clientId: clientId,
  //       };
  //     }
  //     const arrayOfObjects = data?.map(createObject);
  //     setLoadingCns(1);
  //     setClientNextSessions(arrayOfObjects);
  //     // console.log(arrayOfObjects);
  //   });
  //   // get clients last sessions
  //   getAllRowsByEncodedQuery(clientsQueryEP, lastSessionStatQuery).then((data: any) => {
  //     function createObject(value: any) {
  //       const dateAndTime = value.substring(0, value.indexOf('_'));
  //       const clientId = value.split('_').pop();
  //       return {
  //         sessionDateAndTime: dateAndTime === '' ? 0 : dateAndTime,
  //         clientId: clientId,
  //       };
  //     }
  //     const arrayOfObjects = data?.map(createObject);
  //     setLoadingCls(1);
  //     setClientLastSessions(arrayOfObjects);
  //     // console.log(arrayOfObjects);
  //   });
  //   // get case note data
  // getAllRowsByEncodedQuery(clientsQueryEP, clientsQueryOld).then((data: any) => {
  //   const allStudents = data.split(',');
  //   data?.length > 0 &&
  //     getMultipleApiData(clientsListEP!, bearerToken!, allStudents).then((data: any) => {
  //       function createObject(val: any) {
  //         // console.log('val', val);
  //         const id = val.id;
  //         const cname = val?.fields['First Name'] + ' ' + val?.fields['Last Name'];
  //         return {
  //           value: id === '' ? 0 : id,
  //           label: cname === ' ' ? 'Name not found' : id + ') ' + cname,
  //         };
  //       }
  //       const arrayOfObjects = data?.map(createObject);
  //       // setCaseNotesMyClients(arrayOfObjects);
  //       // setLoadingClients(1);
  //       setCaseNotes(data);
  //       getCaseNoteCount(data);
  //     });
  // });
  // }, []);
  // }, [userStats, schoolFilter, genders]);

  interface schoolsFil {
    id: number;
  }

  const handleNoteType = (e: any) => {
    setNewNote({ ...newNote, note_type: e.target.value });
  };

  const handleNoteDetails = (e: any) => {
    let inputValue = e.target.value;
    setNewNote({ ...newNote, note_details: inputValue });
  };

  const clientHeadings = [
    'First Name',
    'Last Name',
    'Next session',
    'Last session',
    'Current Therapist Assigned',
    'If reassigned, Previous Therapist?',
    'Status',
    'Client type',
    'Therapy Location',
    'Adult or Child?',
    'Sex',
    'Date of Birth',
    'Age (Enquiry)',
    'Parents First Name',
    'Parents Last Name',
    'School or Employer',
    'Phone',
    'Mobile 1',
    'Mobile 2',
    'Primary Email Address',
    'Email 1',
    'Email 2',
    'Address',
    'Any other information (i.e. partial address)',
    'Additonal party involved: Solicitor, Court, School, Local Authority?',
    'Additional party details',
    'Have you seen a Speech Therapist',
    'Type of Speech Therapist seen?',
    'Do you have a written report? Date?',
    'Enquiry - Concerns',
    'Assessment undertaken?',
    'Assessment Date',
    'Re-assessment - If applicable?',
    'Re-assessment Date',
    'Report provided?',
    'Report paid for?',
    'Diagnosis',
    'Diagnosis (Notes)',
    'Date discharged',
    'Discharge letter sent',
    'Case notes',
    'Manage',
    'TOMS',
  ];
  const clientHeadingsHidden = ['First Name', 'Last Name', 'Case notes', 'Manage', 'TOMS'];

  const optionsGenders: ApexOptions = {
    chart: {
      width: 'auto',
      type: 'pie',
    },
    labels: ['Males', 'Females'],
    legend: {
      position: 'bottom',
    },
    colors: ['#40A83D', '#D6D944'],
    stroke: {
      curve: 'smooth',
      colors: ['transparent'],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
      enabled: true,
      style: {
        fontSize: '18px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
      },
    },
  };
  let classYears = [
    caseNotes
      ? caseNotes?.map((i: any, k: any) => {
          return i?.fields['Therapy Location'] === undefined
            ? 'Location Unknown'
            : i?.fields['Therapy Location'];
        })
      : 0,
  ];
  let classYearsFilterList = classYears[0];
  let uniqueClassYears = classYearsFilterList?.sort().filter((element: any, index: any) => {
    return classYearsFilterList?.indexOf(element) === index;
  });
  // console.log('uniqueClassYears: ', uniqueClassYears);

  function getNextSession(clientId: any) {
    const nextSession = `
let now := today();
let sess := (select 'Session Stats')['Session Start' >= now and 'Central Client Database - ALL STAFF USAGE'.Id = ${clientId}].'Session Start';
format(first(sort(sess)), "x")`;

    const nextSessionVal = getAllRowsByEncodedQuery(clientsQueryEP, nextSession).then(
      (data: any) => {
        // console.log(data);
        return data;
      }
    );

    return JSON.stringify(nextSessionVal);
  }

  function getLastSession(clientId: any) {
    const lastSession = `
    let now := today();
    let sess := (select 'Session Stats')['Session Start' < now and 'Central Client Database - ALL STAFF USAGE'.Id = ${clientId}].'Session Start';
    format(last(sort(sess)), "x")`;

    const lastSessionVal = getAllRowsByEncodedQuery(clientsQueryEP, lastSession).then(
      (data: any) => {
        // console.log(data);
        return data;
      }
    );

    return JSON.stringify(lastSessionVal);
  }

  function displayDataForClass(uniqueClassYearsArray: any) {
    const newArray = uniqueClassYearsArray?.map(
      (z: any, k: any) =>
        caseNotes.filter((i: any) =>
          z === 'Location Unknown'
            ? i?.fields['Therapy Location'] === undefined
            : i?.fields['Therapy Location'] === z
        )?.length
    );
    // console.log('newArrayNumbers: ', newArray);
    return newArray;
  }

  function displayGenderCountForClass(gendersArray: any) {
    const newArray = gendersArray?.map(
      (z: any, k: any) => caseNotes.filter((i: any) => i?.fields['Sex'] === z)?.length
    );
    return newArray;
  }
  const genderTypesArray = ['Male', 'Female'];

  const barChartOptions: any = {
    // Define your chart options here
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      style: {
        color: '#444',
      },
    },
    series: [
      {
        name: 'No of locations',
        data: displayDataForClass(uniqueClassYears),
      },
    ],
    xaxis: {
      categories: uniqueClassYears,
    },
    colors: ['#0C7348'],
  };

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  // const AllClientsSearch = (val: any) => (
  //   <SelectRS
  //     options={options}
  //     onChange={(e) => {
  //       setSchoolFilter(Number(val));
  //       navigate('/therapist-client-session/' + val + '?schoolType=RegularSchool');
  //       // setGenders();
  //     }}
  //   />
  // );
  const countMyClientsCaseNotes =
    caseNotesMyClients.length === 0 ? '... loading' : caseNotesMyClients?.length;

  const countAllClientsCaseNotes =
    caseNotesAllClients.length === 0
      ? '... loading - this may take a while'
      : caseNotesAllClients?.length;
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <Flex alignItems="center" gap="2">
            <Box p={2} mb={5} w={'70%'}>
              <HStack spacing={5}>
                <BackButton navigate={navigate} />
                <Heading as={'h3'} size={'md'}>
                  Clients
                </Heading>
                {/* <Button
                  onClick={() => {
                    console.log("We're loading data");
                    const allClientsQuery = `let a := (select 'Central Client Database - ALL STAFF USAGE');
                  let string := [""];
                  for i in a do
                    string := array(string, [i.Id + "_" + i.'First Name' + " " + i.'Last Name'])
                  end;
                  slice(string, 1, length(string) + 1)`;
                    getAllRowsByEncodedQuery(clientsQueryEP, allClientsQuery).then((data: any) => {
                      console.log('Button fetch', data);
                      // Array of values
                      // const values = ["123_name", "456_othername", "789_ ", "_noname"];

                      // Using map to process the array
                      const objectsArray = data.map((val: any) => {
                        const underscoreIndex = val.indexOf('_');
                        const id = underscoreIndex === -1 ? '' : val.substring(0, underscoreIndex);
                        const cname =
                          underscoreIndex === -1 ? '' : val.substring(underscoreIndex + 1);

                        return {
                          value: id === '' ? 0 : id,
                          label:
                            cname === '' || cname === ' ' ? 'Name not found' : id + ') ' + cname,
                        };
                      });

                      console.log(objectsArray);
                      setCaseNotesAllClients(objectsArray);
                      setLoadingAllClients(1);
                    });
                  }}
                >
                  Load data
                </Button> */}
              </HStack>
            </Box>
            <Spacer />
            {
              <Box mt={-5} bg={'green.100'} p={2.5} borderRadius={10}>
                <HStack gap="2" justifyContent={'right'}>
                  {/* <FormLabel fontWeight={'bold'} mt={2.5}>
                    Select a school
                  </FormLabel> */}
                  <Box width={'350px'}>
                    <SelectRS
                      options={caseNotesAllClients && caseNotesAllClients}
                      onChange={(value: any) => {
                        setAllSchoolFilter(Number(value.value));
                        // console.log(value);
                        navigate(
                          '/therapist-client-session/' + value.value + '?schoolType=RegularSchool'
                        );
                      }}
                      placeholder={'Select from all clients (' + countAllClientsCaseNotes + ')'}
                    />
                  </Box>
                  <Box width={'350px'}>
                    <SelectRS
                      options={caseNotesMyClients && caseNotesMyClients}
                      onChange={(value: any) => {
                        setSchoolFilter(Number(value.value));
                        navigate(
                          '/therapist-client-session/' + value.value + '?schoolType=RegularSchool'
                        );
                      }}
                      placeholder={'Select from my clients (' + countMyClientsCaseNotes + ')'}
                    />
                    {/* <Select
                      value={schoolFilter}
                      options={caseNotesMyClients && caseNotesMyClients}
                      placeholder={'Select from my clients'}
                      width={'auto'}
                      onChange={(value: any) => {
                        setSchoolFilter(Number(value.value));
                        navigate(
                          '/therapist-client-session/' + value.value + '?schoolType=RegularSchool'
                        );
                      }}
                    >
                      {caseNotes &&
                        caseNotes?.sort().map((i: any, k: number) => (
                          <option value={i?.id} key={k}>
                            {i?.fields['First Name'] + ' ' + i?.fields['Last Name']}
                          </option>
                        ))}
                    </Select> */}
                  </Box>
                  {/* <Text>School selected: {schoolFilter}</Text>
                <Text>Selected tab: {tabIndex}</Text> */}
                </HStack>
              </Box>
            }
          </Flex>
          {schoolID === '0' ? (
            ''
          ) : (
            <Box mt={5}>
              <Flex overflowX={'scroll'}>
                <Stat
                  bg={'gray.100'}
                  p={5}
                  borderRadius={5}
                  mr={5}
                  h={'300px'}
                  color={'black'}
                  mb={10}
                >
                  <HStack borderBottom={'1px solid black'}>
                    <Box w={'50%'} h={'130px'} p={'10px'} borderRight={'1px solid black'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        Total clients{' '}
                        <Tooltip label={totalPrivateClientsHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      <StatNumber
                        fontSize={30}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'left'}
                        alignItems={'left'}
                      >
                        {/* {caseNotes?.length} */}
                        {countMyClientsCaseNotes}
                      </StatNumber>
                    </Box>
                    <Box w={'50%'} h={'130px'} p={'10px'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        Next session{' '}
                        <Tooltip label={nextSessionHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      {nextSessionData && (
                        <StatNumber
                          fontSize={12}
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'left'}
                          alignItems={'left'}
                        >
                          <Text>Client Name: {nextSessionData[1]}</Text>

                          <Text>Client ID: {nextSessionData[2]}</Text>
                          <Text>
                            Session:
                            {' ' +
                              moment.unix(nextSessionData[0] / 1000).format('DD/MM/YYYY HH:mm A')}
                          </Text>
                        </StatNumber>
                      )}
                    </Box>
                  </HStack>
                  <HStack>
                    <Box w={'50%'} h={'130px'} p={'10px'} borderRight={'1px solid black'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        Avg. interactions{' '}
                        <Tooltip label={avgInteractionsHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      <StatNumber
                        fontSize={30}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'left'}
                        alignItems={'left'}
                      >
                        {(
                          ((Number(caseNoteCount?.length) >= 1 &&
                            caseNoteCount?.reduce((partialSum: any, a: any) => partialSum + a, 0)) /
                            caseNotes?.length) *
                          100
                        ).toFixed(0)}
                      </StatNumber>
                    </Box>
                    <Box w={'50%'} h={'130px'} p={'10px'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        Total dishcharged{' '}
                        <Tooltip label={showDischargedHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      <StatNumber
                        fontSize={30}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'left'}
                        alignItems={'left'}
                      >
                        {(
                          (Number(studentAges.length) >= 1 &&
                            studentAges?.reduce((partialSum: any, a: any) => partialSum + a, 0)) /
                          caseNotes?.length
                        ).toFixed(0)}
                      </StatNumber>
                    </Box>
                  </HStack>
                </Stat>
                <Stat
                  bg={'gray.100'}
                  p={5}
                  borderRadius={5}
                  mr={5}
                  h={'300px'}
                  color={'black'}
                  mb={10}
                >
                  <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'center'}>
                    Genders{' '}
                    <Tooltip label={gendersHint} style={{ cursor: 'pointer' }}>
                      <Icon mt={0} />
                    </Tooltip>
                  </StatLabel>
                  <StatNumber
                    // fontSize={90}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <ReactApexChart
                      options={optionsGenders}
                      series={displayGenderCountForClass(genderTypesArray)}
                      type="pie"
                      height={240}
                    />
                  </StatNumber>
                </Stat>
                <Stat
                  bg={'gray.100'}
                  p={5}
                  borderRadius={5}
                  mr={5}
                  h={'300px'}
                  color={'black'}
                  mb={10}
                >
                  <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'center'}>
                    Therapy location{' '}
                    <Tooltip label={therapyLocationHint} style={{ cursor: 'pointer' }}>
                      <Icon mt={0} />
                    </Tooltip>
                  </StatLabel>
                  <StatNumber
                    // fontSize={90}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <ReactApexChart
                      options={barChartOptions && barChartOptions}
                      series={barChartOptions?.series}
                      type="bar"
                      height={240}
                    />
                  </StatNumber>
                </Stat>
              </Flex>
            </Box>
          )}
          <Box mt={-5}>
            <Tabs
              variant="soft-rounded"
              colorScheme="green"
              align="start"
              onChange={(index) => {
                setTabIndex(index);
                setSchoolFilter(Number(0));
                setSearchValue('');
                setSearchValueLowerCase('');
                setActiveSearch(0);
                setCaseNotes([]);
                tabIndex === 0
                  ? navigate(`/schools?schoolID=0&schoolType=SENSchool`)
                  : navigate(`/schools?schoolID=0&schoolType=RegularSchool`);
                // console.log(schoolType);
              }}
            >
              <TabList display={'none'}>
                <Tab>Mainstream Schools</Tab>
                <Tab>SEN Schools</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <IsolatedModal modalState={openedModal} />
                  <Box p={2} mb={5}>
                    <HStack spacing={5}>
                      <Box width={'70%'}>
                        <HStack>
                          <InputGroup>
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <InputLeftElement
                                pointerEvents="none"
                                children={<Search2Icon color="gray.600" />}
                              />
                            )}
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <Input
                                pr="4.5rem"
                                placeholder="Search for a client"
                                onChange={(e) => handleSearch(e)}
                                type="text"
                                value={searchValue}
                                size="xl"
                                borderRadius={5}
                              />
                            )}
                            {activeSearch === 1 ? (
                              <InputRightAddon
                                children={<CloseIcon color="gray.600" />}
                                onClick={() => {
                                  clearSearch();
                                }}
                                cursor={'pointer'}
                              />
                            ) : schoolID === '0' ? (
                              ''
                            ) : (
                              <InputRightAddon children="Search" cursor={'pointer'} />
                            )}
                          </InputGroup>
                        </HStack>
                      </Box>
                      <Box width={'30%'}>
                        <Box textAlign={'right'} width={'100%'}>
                          {schoolID === '0' ? (
                            ''
                          ) : (
                            <HStack>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  showAllColumns === 0
                                    ? setShowAllColumns(1)
                                    : setShowAllColumns(0);
                                }}
                                backgroundColor={showAllColumns === 0 ? 'brand.300' : 'brand.400'}
                              >
                                {showAllColumns === 0
                                  ? 'Show all client data'
                                  : 'Hide all client data'}
                              </Button>
                              {/* <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  handleModalOpen(
                                    schoolType === 'RegularSchool' ? 'RegularSchool' : 'SENSchool'
                                  );
                                }}
                              >
                                Add Client
                              </Button> */}
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  showDischarged === 0
                                    ? setShowDischarged(1)
                                    : setShowDischarged(0);
                                }}
                                backgroundColor={showDischarged === 0 ? 'brand.300' : 'brand.400'}
                              >
                                {showDischarged === 0
                                  ? 'Show discharged clients'
                                  : 'Hide discharged clients'}
                              </Button>
                            </HStack>
                          )}
                        </Box>
                      </Box>
                    </HStack>
                  </Box>

                  <TableContainer className="caseloadContainer">
                    {/* <Box>{JSON.stringify(caseNotes)}</Box> */}
                    {localStorage.getItem('accountType') !== undefined && (
                      <Table variant="striped">
                        <Thead>
                          <Tr>
                            {showAllColumns === 0
                              ? showDischarged === 1
                                ? clientHeadingsHidden.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i}
                                      {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))
                                : clientHeadingsHidden.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i}
                                      {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))
                              : showDischarged === 1
                              ? clientHeadings.map((i: any, k: number) => (
                                  <Th
                                    key={k}
                                    style={
                                      k === 0
                                        ? {
                                            position: 'sticky',
                                            left: '0',
                                            width: '150px',
                                            zIndex: 999,
                                            cursor: 'pointer',
                                          }
                                        : k === 1
                                        ? {
                                            position: 'sticky',
                                            left: '140px',
                                            width: '150px',
                                            zIndex: 999,
                                            cursor: 'pointer',
                                          }
                                        : k === 40
                                        ? { position: 'sticky', right: '240px', width: '120px' }
                                        : k === 41
                                        ? { position: 'sticky', right: '120px', width: '120px' }
                                        : k === 42
                                        ? { position: 'sticky', right: '0', width: '120px' }
                                        : {}
                                    }
                                    onClick={() => {
                                      sortDataByParameter(
                                        setCaseNotes,
                                        caseNotes,
                                        schoolFilter,
                                        i,
                                        accountType!,
                                        sorter
                                      );
                                      setActiveSort(i);
                                      setSorter.toggle();
                                    }}
                                  >
                                    {i}
                                    {sorterIcon(sorter, activeSort, i)}
                                  </Th>
                                ))
                              : clientHeadings.map((i: any, k: number) => (
                                  <Th
                                    key={k}
                                    style={
                                      k === 0
                                        ? {
                                            position: 'sticky',
                                            left: '0',
                                            width: '150px',
                                            zIndex: 999,
                                            cursor: 'pointer',
                                          }
                                        : k === 1
                                        ? {
                                            position: 'sticky',
                                            left: '140px',
                                            width: '150px',
                                            zIndex: 999,
                                            cursor: 'pointer',
                                          }
                                        : k === 40
                                        ? { position: 'sticky', right: '240px', width: '120px' }
                                        : k === 41
                                        ? { position: 'sticky', right: '120px', width: '120px' }
                                        : k === 42
                                        ? { position: 'sticky', right: '0', width: '120px' }
                                        : {}
                                    }
                                    onClick={() => {
                                      sortDataByParameter(
                                        setCaseNotes,
                                        caseNotes,
                                        schoolFilter,
                                        i,
                                        accountType!,
                                        sorter
                                      );
                                      setActiveSort(i);
                                      setSorter.toggle();
                                    }}
                                  >
                                    {i}
                                    {sorterIcon(sorter, activeSort, i)}
                                  </Th>
                                ))}
                          </Tr>
                        </Thead>
                        {loadingClients === 0 ? (
                          <Tbody>
                            <Box
                              width={'100%'}
                              alignContent={'center'}
                              display={'flex'}
                              flexDirection={'column'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              pt={5}
                            >
                              <Spinner
                                thickness="8px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="brand.200"
                                size="xl"
                              />
                              loading...
                            </Box>
                          </Tbody>
                        ) : showAllColumns === 0 ? (
                          showDischarged === 1 ? (
                            <Tbody>
                              {caseNotes &&
                                caseNotes
                                  .filter((z: any) => z.fields['Date discharged'])
                                  .map((i: any, k: any) => {
                                    const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                    var a = moment(formattedToday);
                                    var b = moment(born);
                                    const dobTest = a.diff(b, 'years');
                                    return (
                                      <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                                        <Td
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  left: '0',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  left: '0',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          {/* {k} - {i?.id} - */}
                                          {i?.fields['First Name']}{' '}
                                        </Td>
                                        <Td
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  left: '140px',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  left: '140px',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          {i?.fields['Last Name']}
                                        </Td>
                                        <Td>
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              schoolType === 'RegularSchool'
                                                ? navigate(
                                                    '/therapist-client-session/' +
                                                      i.id +
                                                      '?schoolType=RegularSchool'
                                                  )
                                                : navigate(
                                                    '/therapist-client-session/' +
                                                      i.id +
                                                      '?schoolType=SENSchool'
                                                  );
                                            }}
                                          >
                                            View
                                          </Button>
                                        </Td>
                                        <Td>
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              navigate('/edit-client/' + i.id);
                                            }}
                                          >
                                            Manage
                                          </Button>
                                        </Td>
                                        <Td>
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              navigate('/client-toms-rating/' + i.id);
                                            }}
                                          >
                                            TOMS
                                          </Button>
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                            </Tbody>
                          ) : (
                            <Tbody>
                              {caseNotes &&
                                caseNotes
                                  .filter(
                                    (z: any) =>
                                      z.fields['Date discharged'] === undefined ||
                                      z.fields['Date discharged'] === null ||
                                      z.fields['Date discharged'] === ''
                                  )
                                  .map((i: any, k: any) => {
                                    const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                    var a = moment(formattedToday);
                                    var b = moment(born);
                                    const dobTest = a.diff(b, 'years');
                                    return (
                                      <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                                        <Td
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  left: '0',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  left: '0',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          {/* {k} - {i?.id} - */}
                                          {i?.fields['First Name']}{' '}
                                        </Td>
                                        <Td
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  left: '140px',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  left: '140px',
                                                  width: '150px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          {i?.fields['Last Name']}
                                        </Td>
                                        <Td>
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              schoolType === 'RegularSchool'
                                                ? navigate(
                                                    '/therapist-client-session/' +
                                                      i.id +
                                                      '?schoolType=RegularSchool'
                                                  )
                                                : navigate(
                                                    '/therapist-client-session/' +
                                                      i.id +
                                                      '?schoolType=SENSchool'
                                                  );
                                            }}
                                          >
                                            View
                                          </Button>
                                        </Td>
                                        <Td>
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              navigate('/edit-client/' + i.id);
                                            }}
                                          >
                                            Manage
                                          </Button>
                                        </Td>
                                        <Td>
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              navigate('/client-toms-rating/' + i.id);
                                            }}
                                          >
                                            TOMS
                                          </Button>
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                            </Tbody>
                          )
                        ) : showDischarged === 1 ? (
                          <Tbody>
                            {caseNotes &&
                              caseNotes
                                .filter((z: any) => z.fields['Date discharged'])
                                .map((i: any, k: any) => {
                                  const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                  var a = moment(formattedToday);
                                  var b = moment(born);
                                  const dobTest = a.diff(b, 'years');

                                  const nextSess =
                                    clientNextSessions &&
                                    clientNextSessions?.filter(
                                      (z: any) => Number(z.clientId) === Number(i.id)
                                    )[0]?.sessionDateAndTime;

                                  const lastSess =
                                    clientLastSessions &&
                                    clientLastSessions?.filter(
                                      (z: any) => Number(z.clientId) === Number(i.id)
                                    )[0]?.sessionDateAndTime;

                                  return (
                                    <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '0',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '0',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {/* {k} - {i?.id} - */}
                                        {i?.fields['First Name']}{' '}
                                      </Td>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '140px',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '140px',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {i?.fields['Last Name']}
                                      </Td>
                                      <Td>
                                        {loadingCns === 0 ? (
                                          <Progress size="xs" isIndeterminate colorScheme="green" />
                                        ) : nextSess === 0 ? (
                                          'No upcoming session'
                                        ) : (
                                          moment.unix(nextSess / 1000).format('DD/MM/YYYY HH:mm A')
                                        )}
                                      </Td>
                                      <Td>
                                        {loadingCls === 0 ? (
                                          <Progress size="xs" isIndeterminate colorScheme="green" />
                                        ) : lastSess === 0 ? (
                                          'No upcoming session'
                                        ) : (
                                          moment.unix(lastSess / 1000).format('DD/MM/YYYY HH:mm A')
                                        )}
                                      </Td>
                                      {/* <Td>{i?.id && getNextSession(i?.id)}</Td>
                                      <Td>{i?.id && getLastSession(i?.id)}</Td> */}
                                      <Td>{i?.fields['Current Therapist Assigned']}</Td>
                                      <Td>{i?.fields['If reassigned, Previous Therapist?']}</Td>
                                      <Td>{i?.fields['Status']}</Td>
                                      <Td>{i?.fields['Client type']}</Td>
                                      <Td>{i?.fields['Therapy Location']}</Td>
                                      <Td>{i?.fields['Adult or Child?']}</Td>
                                      <Td>{i?.fields['Sex']}</Td>
                                      <Td>
                                        {i?.fields['Date of Birth'] &&
                                          moment(i?.fields['Date of Birth']).format('DD-MM-YYYY')}
                                      </Td>
                                      <Td>{i?.fields['Age (Enquiry)']}</Td>
                                      <Td>{i?.fields['Parents First Name']}</Td>
                                      <Td>{i?.fields['Parents Last Name']}</Td>
                                      <Td>{i?.fields['School or Employer']}</Td>
                                      <Td>{i?.fields['Phone']}</Td>
                                      <Td>{i?.fields['Mobile 1']}</Td>
                                      <Td>{i?.fields['Mobile 2']}</Td>
                                      <Td>{i?.fields['Primary Email Address']}</Td>
                                      <Td>{i?.fields['Email 1']}</Td>
                                      <Td>{i?.fields['Email 2']}</Td>
                                      <Td>{i?.fields['Address']}</Td>
                                      <Td>
                                        {i?.fields['Any other information (i.e. partial address)']}
                                      </Td>
                                      <Td>
                                        {
                                          i?.fields[
                                            'Additonal party involved: Solicitor, Court, School, Local Authority?'
                                          ]
                                        }
                                      </Td>
                                      <Td>{i?.fields['Additional party details']}</Td>
                                      <Td>{i?.fields['Have you seen a Speech Therapist']}</Td>
                                      <Td>{i?.fields['Type of Speech Therapist seen?']}</Td>
                                      <Td>{i?.fields['Do you have a written report? Date?']}</Td>
                                      <Td>{i?.fields['Enquiry - Concerns']}</Td>
                                      <Td>{i?.fields['Assessment undertaken?']}</Td>
                                      <Td>{i?.fields['Assessment Date']}</Td>
                                      <Td>{i?.fields['Re-assessment - If applicable?']}</Td>
                                      <Td>{i?.fields['Re-assessment Date']}</Td>
                                      <Td>{i?.fields['Report provided?']}</Td>
                                      <Td>{i?.fields['Report paid for?']}</Td>
                                      <Td>{i?.fields['Diagnosis']}</Td>
                                      <Td>{i?.fields['Diagnosis (Notes)']}</Td>
                                      <Td>{i?.fields['Date discharged']}</Td>
                                      <Td>{i?.fields['Discharge letter sent']}</Td>

                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                right: '240px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                right: '240px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          View
                                        </Button>
                                      </Td>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                right: '120px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                right: '120px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            navigate('/edit-client/' + i.id);
                                          }}
                                        >
                                          Manage
                                        </Button>
                                      </Td>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                right: '0',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                right: '0',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/toms-rating/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/toms-rating/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          TOMS
                                        </Button>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                          </Tbody>
                        ) : (
                          <Tbody>
                            {caseNotes &&
                              caseNotes
                                .filter(
                                  (z: any) =>
                                    z.fields['Date discharged'] === undefined ||
                                    z.fields['Date discharged'] === null ||
                                    z.fields['Date discharged'] === ''
                                )
                                .map((i: any, k: any) => {
                                  const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                  var a = moment(formattedToday);
                                  var b = moment(born);
                                  const dobTest = a.diff(b, 'years');

                                  const nextSess =
                                    clientNextSessions &&
                                    clientNextSessions?.filter(
                                      (z: any) => Number(z.clientId) === Number(i.id)
                                    )[0]?.sessionDateAndTime;

                                  const lastSess =
                                    clientLastSessions &&
                                    clientLastSessions?.filter(
                                      (z: any) => Number(z.clientId) === Number(i.id)
                                    )[0]?.sessionDateAndTime;

                                  return (
                                    <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '0',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '0',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {/* {k} - {i?.id} - */}
                                        {i?.fields['First Name']}{' '}
                                      </Td>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '140px',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '140px',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {i?.fields['Last Name']}
                                      </Td>
                                      <Td>
                                        {loadingCns === 0 ? (
                                          <Progress size="xs" isIndeterminate colorScheme="green" />
                                        ) : nextSess === 0 ? (
                                          'No upcoming session'
                                        ) : (
                                          moment.unix(nextSess / 1000).format('DD/MM/YYYY HH:mm A')
                                        )}
                                      </Td>
                                      <Td>
                                        {loadingCls === 0 ? (
                                          <Progress size="xs" isIndeterminate colorScheme="green" />
                                        ) : lastSess === 0 ? (
                                          'No upcoming session'
                                        ) : (
                                          moment.unix(lastSess / 1000).format('DD/MM/YYYY HH:mm A')
                                        )}
                                      </Td>
                                      {/* <Td>{i?.id && getNextSession(i?.id)}</Td>
          <Td>{i?.id && getLastSession(i?.id)}</Td> */}
                                      <Td>{i?.fields['Current Therapist Assigned']}</Td>
                                      <Td>{i?.fields['If reassigned, Previous Therapist?']}</Td>
                                      <Td>{i?.fields['Status']}</Td>
                                      <Td>{i?.fields['Client type']}</Td>
                                      <Td>{i?.fields['Therapy Location']}</Td>
                                      <Td>{i?.fields['Adult or Child?']}</Td>
                                      <Td>{i?.fields['Sex']}</Td>
                                      <Td>
                                        {i?.fields['Date of Birth'] &&
                                          moment(i?.fields['Date of Birth']).format('DD-MM-YYYY')}
                                      </Td>
                                      <Td>{i?.fields['Age (Enquiry)']}</Td>
                                      <Td>{i?.fields['Parents First Name']}</Td>
                                      <Td>{i?.fields['Parents Last Name']}</Td>
                                      <Td>{i?.fields['School or Employer']}</Td>
                                      <Td>{i?.fields['Phone']}</Td>
                                      <Td>{i?.fields['Mobile 1']}</Td>
                                      <Td>{i?.fields['Mobile 2']}</Td>
                                      <Td>{i?.fields['Primary Email Address']}</Td>
                                      <Td>{i?.fields['Email 1']}</Td>
                                      <Td>{i?.fields['Email 2']}</Td>
                                      <Td>{i?.fields['Address']}</Td>
                                      <Td>
                                        {i?.fields['Any other information (i.e. partial address)']}
                                      </Td>
                                      <Td>
                                        {
                                          i?.fields[
                                            'Additonal party involved: Solicitor, Court, School, Local Authority?'
                                          ]
                                        }
                                      </Td>
                                      <Td>{i?.fields['Additional party details']}</Td>
                                      <Td>{i?.fields['Have you seen a Speech Therapist']}</Td>
                                      <Td>{i?.fields['Type of Speech Therapist seen?']}</Td>
                                      <Td>{i?.fields['Do you have a written report? Date?']}</Td>
                                      <Td>{i?.fields['Enquiry - Concerns']}</Td>
                                      <Td>{i?.fields['Assessment undertaken?']}</Td>
                                      <Td>{i?.fields['Assessment Date']}</Td>
                                      <Td>{i?.fields['Re-assessment - If applicable?']}</Td>
                                      <Td>{i?.fields['Re-assessment Date']}</Td>
                                      <Td>{i?.fields['Report provided?']}</Td>
                                      <Td>{i?.fields['Report paid for?']}</Td>
                                      <Td>{i?.fields['Diagnosis']}</Td>
                                      <Td>{i?.fields['Diagnosis (Notes)']}</Td>
                                      <Td>{i?.fields['Date discharged']}</Td>
                                      <Td>{i?.fields['Discharge letter sent']}</Td>

                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                right: '240px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                right: '240px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          View
                                        </Button>
                                      </Td>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                right: '120px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                right: '120px',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            navigate('/edit-client/' + i.id);
                                          }}
                                        >
                                          Manage
                                        </Button>
                                      </Td>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                right: '0',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                right: '0',
                                                width: '120px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/toms-rating/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/toms-rating/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          TOMS
                                        </Button>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                          </Tbody>
                        )}
                        {caseNotes === undefined && (
                          <TableCaption>Your sessions history</TableCaption>
                        )}
                      </Table>
                    )}
                  </TableContainer>
                </TabPanel>
                <TabPanel>
                  <Box p={2} mb={5}>
                    <HStack spacing={5}>
                      <Box width={'70%'}>
                        <HStack>
                          <InputGroup>
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <InputLeftElement
                                pointerEvents="none"
                                children={<Search2Icon color="gray.600" />}
                              />
                            )}
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <Input
                                pr="4.5rem"
                                placeholder="Search for a client"
                                onChange={(e) => handleSearch(e)}
                                type="text"
                                value={searchValue}
                                size="xl"
                                borderRadius={5}
                              />
                            )}
                            {activeSearch === 1 ? (
                              <InputRightAddon
                                children={<CloseIcon color="gray.600" />}
                                onClick={() => {
                                  clearSearch();
                                }}
                                cursor={'pointer'}
                              />
                            ) : schoolID === '0' ? (
                              ''
                            ) : (
                              <InputRightAddon children="Search" cursor={'pointer'} />
                            )}
                          </InputGroup>
                        </HStack>
                      </Box>
                      <Box width={'30%'}>
                        <Box textAlign={'right'} width={'100%'}>
                          {schoolID === '0' ? (
                            ''
                          ) : (
                            <HStack>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  showAllColumns === 0
                                    ? setShowAllColumns(1)
                                    : setShowAllColumns(0);
                                }}
                              >
                                {showAllColumns === 0
                                  ? 'Show all client data'
                                  : 'Hide all client data'}
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  handleModalOpen(
                                    schoolType === 'RegularSchool' ? 'RegularSchool' : 'SENSchool'
                                  );
                                }}
                              >
                                Add Student
                              </Button>
                            </HStack>
                          )}
                        </Box>
                      </Box>
                    </HStack>
                  </Box>
                  <TableContainer className="caseloadContainer">
                    {localStorage.getItem('accountType') !== undefined &&
                      localStorage.getItem('accountType') === 'Therapist Staff' && (
                        <Table variant="striped">
                          <Thead>
                            <Tr>
                              {showAllColumns === 0
                                ? clientHeadingsHidden.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i} {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))
                                : clientHeadings.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i} {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))}
                            </Tr>
                          </Thead>
                          {showAllColumns === 0 ? (
                            <Tbody>
                              {caseNotes &&
                                caseNotes.map((i: any, k: any) => {
                                  return (
                                    <Tr key={k} style={{ cursor: 'pointer' }}>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '0',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {i?.fields['First Name']}
                                      </Td>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '150px',
                                                width: '150px',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {i?.fields['Last Name']}
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          View Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/edit-student/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/edit-student/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          Edit Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/toms-rating/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/toms-rating/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          TOMS Rating
                                        </Button>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          ) : (
                            <Tbody>
                              {caseNotes &&
                                caseNotes.map((i: any, k: any) => {
                                  return (
                                    <Tr key={k} style={{ cursor: 'pointer' }}>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {i?.fields['First Name']}
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/therapist-client-session/' +
                                                    i.id +
                                                    '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          View Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/edit-student/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/edit-student/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          Edit Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/toms-rating/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/toms-rating/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          TOMS Rating
                                        </Button>
                                      </Td>
                                      <Td>{i?.fields['DOB']}</Td>
                                      <Td>{i?.fields['Class year']}</Td>
                                      <Td>{i?.fields['Class name']}</Td>
                                      <Td>{i?.fields['Gender']}</Td>
                                      <Td>{i?.fields['Ethnicity']}</Td>
                                      <Td>{i?.fields['Referral Date']}</Td>
                                      <Td>{i?.fields['Language 1']}</Td>
                                      <Td>{i?.fields['Language 2']}</Td>
                                      <Td>{i?.fields['Language 3']}</Td>
                                      <Td>{i?.fields['Other Languages']}</Td>
                                      <Td>{i?.fields['Local Patient Identifier']}</Td>
                                      <Td>{i?.fields['Date of Assessment']}</Td>
                                      <Td>
                                        {i?.fields['Report date'] === undefined
                                          ? 'No Date'
                                          : i?.fields['Report date']}
                                      </Td>
                                      <Td>{i?.fields['Report sent'] === true ? 'Yes' : 'No'}</Td>
                                      <Td>{i?.fields['Report Link']}</Td>
                                      <Td>{i?.fields['Notes']}</Td>
                                      <Td>{i?.fields['Date of review']}</Td>
                                      <Td>
                                        {' '}
                                        {i?.fields['Name of Therapist'] === undefined
                                          ? 'Not allocated'
                                          : i?.fields['Name of Therapist']}
                                      </Td>
                                      <Td>{i?.fields['Primary Medical Diagnosis']}</Td>
                                      <Td>{i?.fields['Additional Medical Diagnosis']}</Td>
                                      <Td>{i?.fields['Other Medical Diagnosis']}</Td>
                                      <Td>
                                        {i?.fields['Primary Communication/ Swallowing Disorder']}
                                      </Td>
                                      <Td>
                                        {i?.fields['Additional Communication/ Swallowing Disorder']}
                                      </Td>
                                      <Td>
                                        {i?.fields['Other Communication/ Swallowing Disorder']}
                                      </Td>
                                      <Td>{i?.fields['EHCP']}</Td>
                                      <Td>{i?.fields['Direct Hours']}</Td>
                                      <Td>{i?.fields['Indirect Hours']}</Td>
                                      <Td>{i?.fields['Hours completed']}</Td>
                                      <Td>{i?.fields['Hours remaining']}</Td>
                                      <Td>{i?.fields['Plan']}</Td>
                                      <Td>{i?.fields['Primary TOMs Scale']}</Td>
                                      <Td>{i?.fields['Secondary TOMs Scale']}</Td>
                                      <Td>
                                        {i?.fields['End of Episode/ Discharge Code/ Description']}
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          )}
                        </Table>
                      )}
                  </TableContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
            {/* {caseNotes === undefined ||
              (caseNotes.length === 0 && <Box textAlign={'center'}>No students added yet!</Box>)} */}
            {schoolFilter === 0 && (
              <Box textAlign={'center'}>
                Select a school from the top right dropdown, in order to view the list of students
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentClients;
