import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Flex,
  Stack,
  Heading,
  Text,
  Link,
  Button,
  Box,
  ButtonGroup,
  Spacer,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  FormHelperText,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import StepOne from '../components/privateClinicalSignUpSteps/StepOne';
import StepTwo from '../components/privateClinicalSignUpSteps/StepTwo';
import StepThree from '../components/privateClinicalSignUpSteps/StepThree';
import StepFour from '../components/privateClinicalSignUpSteps/StepFour';
import StepFive from '../components/privateClinicalSignUpSteps/StepFive';
import CreateAccount from '../components/privateClinicalSignUpSteps/CreateAcccount';

import UL_logo from './../logo/unlocking_language_logo_white.png';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import { privateForm } from '../interfaces/privateForm';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import { useNavigate } from 'react-router-dom';

// import { SmallCloseIcon } from '@chakra-ui/icons';
const steps = [
  { title: 'Details', description: 'Fill in your details', content: 'Content 1' },
  { title: 'File Uploads', description: 'Upload files & reports', content: 'Content 2' },
  {
    title: 'Contract',
    description: 'View & Sign Contract',
    content: 'Content 3',
  },
  { title: 'Direct Debit', description: 'Setup your direct debit', content: 'Content 4' },
  { title: 'Payment details', description: 'Make the first payment', content: 'Content 5' },
];

export default function PrivateClinicalSignUp(): JSX.Element {
  const defaultForm = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    contactNumber: '',
    password: '',
    cpassword: '',
    contract: 0,
  };
  const [form, setForm] = useState<any>(defaultForm);
  const [signContract, setSignContract] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const navigate = useNavigate();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [defaultIndex, setDefaultIndex] = useState<any>([0]);
  const allClients = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const queryParameters = new URLSearchParams(window.location.search);
  const ul_cl_id = queryParameters.get('ul_cl');
  function goBackOneStep(activeStep: number) {
    setActiveStep(activeStep - 1);
  }
  const nextStep = (nextStep: number) => {
    setDefaultIndex([nextStep]);
  };
  function sendDataToStripe() {
    const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
    const customer = stripe.customers
      .create({
        name: customerName,
        email: customerEmail,
      })
      .then((data: any) => {
        const subscription = stripe.subscriptions
          .create({
            customer: data && data?.id,
            items: [
              {
                price: 'price_1PHNSHJwVKnkDHqHr9waj23c',
              },
            ],
          })
          .then((subdata: any) =>
            ninoxTableUpdateRow(
              allClients!,
              Number(ul_cl_id),
              {
                fields: {
                  'Stripe Customer id': data?.id,
                  'Subscription id': subdata?.id,
                },
              },
              process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
            )
          );
      })
      .then(() => {
        navigate('/thank-you-for-completing-the-express-onboarding-process');
      });
  }

  useEffect(() => {
    ninoxTableGetRow(
      allClients!,
      Number(ul_cl_id),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      // setUser(data);
      // setContract(data?.fields['HTML Contract']);
      // setContractStateFromDb(data?.fields['Agreed to T&Cs - website']);
      // console.log('THE CONTRACT: ', data?.fields['HTML Contract']);
      setCustomerName(data?.fields['First Name'] + ' ' + data?.fields['Last Name']);
      setCustomerEmail(data?.fields['Email 1']);
      data?.fields['Agreed to T&Cs - website'] === true ? setSignContract(1) : setSignContract(0);
    });
  }, []);

  const stepSelect = steps[activeStep].title;
  return (
    <Flex minH={'100vh'} justify={'center'} bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        w={'4xl'}
        maxW={'6xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        mt={10}
      >
        <Stack align={'left'} mb={'50px'}>
          <HStack width={'100%'}>
            <Box
              display={'flex'}
              alignItems={'right'}
              textAlign={'right'}
              justifyContent={'left'}
              width={'50%'}
            >
              <Image width="200px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
            <Box width={'50%'}>
              <Heading as={'h4'} fontSize={20} color={'brand.200'} textAlign={'right'}>
                Private Clinical Client - Express Onboarding
              </Heading>
            </Box>
          </HStack>
          <Box w={'full'}>
            <Text mt={'5'}>
              Our service is designed to help you achieve your goals in a seamless and efficient
              manner. <b>Let's get started!</b>
            </Text>
            <Accordion
              allowToggle
              mt={'10'}
              index={defaultIndex}
              borderRadius={10}
              onChange={(defaultIndex) => setDefaultIndex(defaultIndex)}
            >
              <AccordionItem mb={5} borderRadius={10}>
                <AccordionButton
                  _expanded={{ bg: 'brand.300', color: 'white' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                  bg={'brand.200'}
                  color={'white'}
                  borderRadius={10}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                    Personal details
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  bg={'white'}
                  border={'1px'}
                  borderColor={'gray.100'}
                  borderRadius={10}
                >
                  {/* <StepOne /> */}
                  <StepOne form={form} setForm={setForm} nextStep={nextStep} />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem
                mb={5}
                borderRadius={10}
                isDisabled={
                  form.firstName.length <= 0 ||
                  form.lastName.length <= 0 ||
                  form.email.length <= 0 ||
                  form.contactNumber.length <= 0
                    ? true
                    : false
                }
              >
                <AccordionButton
                  _expanded={{ bg: 'brand.300', color: 'white' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                  bg={'brand.200'}
                  color={'white'}
                  borderRadius={10}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                    Create account
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  bg={'white'}
                  border={'1px'}
                  borderColor={'gray.100'}
                  borderRadius={10}
                >
                  <CreateAccount form={form} setForm={setForm} nextStep={nextStep} />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem
                mb={5}
                borderRadius={10}
                isDisabled={
                  form.firstName.length <= 0 ||
                  form.lastName.length <= 0 ||
                  form.email.length <= 0 ||
                  form.contactNumber.length <= 0
                    ? true
                    : false
                }
              >
                <AccordionButton
                  _expanded={{ bg: 'brand.300', color: 'white' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                  bg={'brand.200'}
                  color={'white'}
                  borderRadius={10}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                    Uploads
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  bg={'white'}
                  border={'1px'}
                  borderColor={'gray.100'}
                  borderRadius={10}
                >
                  <StepTwo form={form} setForm={setForm} nextStep={nextStep} />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem
                mb={5}
                borderRadius={10}
                isDisabled={
                  form.firstName.length <= 0 ||
                  form.lastName.length <= 0 ||
                  form.email.length <= 0 ||
                  form.contactNumber.length <= 0
                    ? true
                    : false
                }
              >
                <AccordionButton
                  _expanded={{ bg: 'brand.300', color: 'white' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                  bg={'brand.200'}
                  color={'white'}
                  borderRadius={10}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                    Contract
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  bg={'white'}
                  border={'1px'}
                  borderColor={'gray.100'}
                  borderRadius={10}
                >
                  <StepThree nextStep={nextStep} />
                </AccordionPanel>
              </AccordionItem>
              {/* <AccordionItem mb={5} borderRadius={10}>
                <AccordionButton
                  _expanded={{ bg: 'brand.300', color: 'white' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                  bg={'brand.200'}
                  color={'white'}
                  borderRadius={10}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                    Contract
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  bg={'white'}
                  border={'1px'}
                  borderColor={'gray.100'}
                  borderRadius={10}
                >
                  <StepThree />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={5} borderRadius={10}>
                <AccordionButton
                  _expanded={{ bg: 'brand.300', color: 'white' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                  bg={'brand.200'}
                  color={'white'}
                  borderRadius={10}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                    Direct Debit via Gocardless
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  bg={'white'}
                  border={'1px'}
                  borderColor={'gray.100'}
                  borderRadius={10}
                >
                  <StepFour
                    form={form}
                    setForm={setForm}
                    signContract={signContract}
                    setSignContract={setSignContract}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem mb={5} borderRadius={10}>
                <AccordionButton
                  _expanded={{ bg: 'brand.300', color: 'white' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                  bg={'brand.200'}
                  color={'white'}
                  borderRadius={10}
                >
                  <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                    First payment
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel
                  bg={'white'}
                  border={'1px'}
                  borderColor={'gray.100'}
                  borderRadius={10}
                >
                  <StepFive />
                </AccordionPanel>
              </AccordionItem> */}
            </Accordion>

            <Box width="100%" display="flex" flexDirection="column" alignItems={'end'}>
              {/* <Link
                href={'/thank-you-for-completing-the-express-onboarding-process'}
                textAlign={'center'}
              > */}
              <Button
                textAlign={'center'}
                bg={'brand.300'}
                _hover={{
                  bg: 'brand.200',
                }}
                color={'white'}
                onClick={() => sendDataToStripe()}
              >
                Submit
              </Button>
              {/* </Link> */}
            </Box>
          </Box>
        </Stack>
      </Stack>

      {/* <Stack
        w={'full'}
        maxW={'6xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        mt={10}
      >
        <Stack align={'left'} mb={'50px'}>
          <HStack width={'100%'}>
            <Box width={'50%'}>
              <Heading as={'h3'}>Private Clinical Client Sign up</Heading>
            </Box>
            <Box
              display={'flex'}
              alignItems={'right'}
              textAlign={'right'}
              justifyContent={'right'}
              width={'50%'}
            >
              <Image width="200px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
          </HStack>
        </Stack>
        <Stepper index={activeStep} colorScheme="brand">
          {steps.map(
            (step, index) =>
              activeStep <= 4 && (
                <>
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                </>
              )
          )}
        </Stepper>
        {activeStep === 0 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepOne title={steps[activeStep].title} />
          </Stack>
        )}
        {activeStep === 1 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepTwo title={steps[activeStep].title} activeStep={activeStep} />
          </Stack>
        )}
        {activeStep === 2 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepThree title={steps[activeStep].title} />
          </Stack>
        )}
        {activeStep === 3 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepFour
              title={steps[activeStep].title}
              form={form}
              setForm={setForm}
              signContract={signContract}
              setSignContract={setSignContract}
            ></StepFour>
          </Stack>
        )}
        {activeStep === 4 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepFive index={activeStep} title={steps[activeStep].title}>
              Thank you for completing all steps
            </StepFive>
          </Stack>
        )}
        <Stack spacing={6} direction={['column', 'row']}>
          {activeStep <= 4 && (
            <Button
              bg={'brand.300'}
              color={'white'}
              w={activeStep <= 3 ? 'full' : '50%'}
              _hover={{
                bg: 'brand.200',
              }}
              onClick={() => goBackOneStep(activeStep)}
            >
              Back
            </Button>
          )}
          {activeStep <= 3 && (
            <Button
              bg={'brand.200'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'brand.300',
              }}
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Next
            </Button>
          )}
          {activeStep >= 5 && (
            <>
              <Link href={'/'}>
                <Button
                  bg={'brand.300'}
                  color={'white'}
                  w="full"
                  _hover={{
                    bg: 'brand.200',
                  }}
                >
                  Back to Login select
                </Button>
              </Link>
            </>
          )}
        </Stack>
        <Outlet />
      </Stack> */}
    </Flex>
  );
}
