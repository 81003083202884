import {
  Box,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Text,
  Textarea,
  HStack,
  Stack,
  Flex,
  Link,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  InputRightAddon,
  InputGroup,
  InputLeftElement,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Tooltip,
  Icon,
  useBoolean,
  Spinner,
} from '@chakra-ui/react';
import orderSchools from '../utils/orderSchools';

import React, { ReactNode, useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getMultipleApiData from '../utils/getMultipleApiData';
import getAllRowsById from '../utils/getAllRowsById';
import getAllProfiles from '../utils/getAllProfiles';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { json } from 'stream/consumers';
import { color } from 'framer-motion';
import { BackButton } from './BackButton';
import { addStudent } from '../interfaces/addStudent';
import { AddStudentsForm } from './AddStudentsForm';
import sortDataByParameter from '../utils/sortDataByParameter';
import sorterIcon from '../utils/sorterIcon';
import { Search2Icon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { GiNotebook } from 'react-icons/gi';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import {
  totalStudentsHint,
  ehcpHint,
  avgInteractionsHint,
  avgAgeHint,
  genderHint,
  classYearsHint,
} from '../constants/dashboardHints';
import { studentFields } from '../constants/studentFields';

const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;
interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
// type StateContextType = {
//   userDetails: userSessionDataProps | null;
//   setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
// };
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

interface UserMeta {
  firstName: string;
  lastName: string;
  accessLevel: string;
  accountType: string;
  messages: number[];
  stats: number[];
  invoices: number[];
}
interface Note {
  nid: number;
  session_id: number;
  note_type: string;
  note_owner: number;
  note_details: string;
  posted_at: Date;
  modified_at: Date;
}
const today = new Date();
const formattedToday = moment(new Date()).format('YYYY-MM-DD');
// const now = moment(new Date()); //todays date

const BookingsContentSchools = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserProps) => {
  // const { userDetails, updateUser } = useContext(userDetailsContext);
  // updateUser(signedInUserId);

  const newNoteDefault = {
    nid: 0,
    session_id: 0,
    note_type: 'brief_note',
    note_owner: signedInUserId,
    note_details: '',
    posted_at: today,
    modified_at: today,
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [genders, setGenders] = useState<any>({});
  const [notes, setNotes] = useState<Note[] | []>([]);
  const [newNote, setNewNote] = useState<Note>(newNoteDefault);
  const [patients, setPatients] = useState<any>([]);
  const [caseNotes, setCaseNotes] = useState<any>([]);
  const [sessionNotes, setSessionNotes] = useState<any>([]);
  const [schools, setSchools] = useState<any>([]);
  const [senSchools, setSenSchools] = useState<any>([]);
  const [tomsRating, setTomsRating] = useState<any>([]);
  const [allClassYears, setAllClassYears] = useState<[]>([]);
  const [showAddStudent, setShowAddStudent] = useState<number>(0);
  const [schoolFilter, setSchoolFilter] = useState(0);
  const [size, setSize] = useState('md');
  const [rows, setRows] = useState<any>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueLowerCase, setSearchValueLowerCase] = useState<string>('');
  const [activeSort, setActiveSort] = useState<string>('None');
  const [activeSearch, setActiveSearch] = useState(0);
  const [notesPerSchool, setNotesPerSchool] = useState(0);
  const [sorter, setSorter] = useBoolean();
  const [schoolSelected, setSchoolSelected] = useState<any>('');
  const [caseNoteCount, setCaseNoteCount] = useState<any>([]);
  const [studentAges, setStudentAges] = useState<any>([]);
  const [showAllColumns, setShowAllColumns] = useState<number>(0);
  const [showDischarged, setShowDischarged] = useState<number>(0);
  const [avgInt, setAvgint] = useState<number>(0);
  const [caseNoteCountTracker, setCaseNoteCountTracker] = useState<number>(0);
  const [loadingIcon, setLoadingIcon] = useState<any>(0);

  const handleSearch = (e: any) => {
    let searchInput = e.target.value;
    let lowerSearchInput = searchInput.toLowerCase();
    setSearchValue(searchInput);
    setSearchValueLowerCase(lowerSearchInput);
    console.log(lowerSearchInput);
    setActiveSearch(1);
  };

  function clearSearch() {
    setSearchValue('');
    setSearchValueLowerCase('');
    setActiveSearch(0);
    getAllRowsByEncodedQuery(getAllStudentsEP, getAllStudentsQuery).then((data: any) => {
      const allStudents = data.split(',');
      console.log(allStudents);
      data?.length > 0 &&
        getMultipleApiData(getCurrentSchoolsStudents!, bearerToken!, allStudents).then(
          (data: any) => {
            setCaseNotes(
              data
                .filter(
                  (i: any) =>
                    i.fields['Date of Discharge'] === undefined ||
                    i.fields['Date of Discharge'] === null ||
                    i.fields['Date of Discharge'] === ''
                )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['Student/Patient Name'].localeCompare(
                        a?.fields['Student/Patient Name']
                      )
                    : a?.fields['Student/Patient Name'].localeCompare(
                        b?.fields['Student/Patient Name']
                      )
                )
            );
            getCaseNoteCount(
              data
                .filter(
                  (i: any) =>
                    i.fields['Date of Discharge'] === undefined ||
                    i.fields['Date of Discharge'] === null ||
                    i.fields['Date of Discharge'] === ''
                )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['Student/Patient Name'].localeCompare(
                        a?.fields['Student/Patient Name']
                      )
                    : a?.fields['Student/Patient Name'].localeCompare(
                        b?.fields['Student/Patient Name']
                      )
                )
            );
          }
        );
      // getCaseNoteCount(allStudents);
    });
  }

  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const studentClasses = studentFields.fields.filter((i: any) => i.name === 'Class year')[0]
    .choices;

  const IsolatedModal = ({ modalState }: any) => {
    return (
      <Box as="section">
        <Modal
          onClose={() => {
            setTheModal(0);
            setOpenedModal(false);
          }}
          size={'2xl'}
          isOpen={modalState}
        >
          <ModalOverlay />
          <AddStudentsForm
            schoolFilter={schoolFilter}
            setSchoolFilter={setSchoolFilter}
            setCaseNotes={setCaseNotes}
            regularSchoolsEP={regularSchoolsEP}
            senSchoolsEP={senSchoolsEP}
            therapistCaseNotesRegularSchoolsSEP={therapistCaseNotesRegularSchoolsSEP}
            therapistCaseNotesSENSchoolsEP={therapistCaseNotesSENSchoolsEP}
            studentClasses={studentClasses}
            tabIndex={tabIndex}
            setTheModal={setTheModal}
            setOpenedModal={setOpenedModal}
          />
        </Modal>
      </Box>
    );
  };

  function handleModalOpen(school: string) {
    // console.log('We added a student to ' + school);
    setOpenedModal(true);
    console.log(openedModal);
  }
  function handleModalClose(rowId: any, rowSession: any) {
    // console.log('We tried to close ' + rowId + ' and the data was ' + JSON.stringify(rowSession));
    setOpenedModal(false);
    setTheModal(rowId);
    // console.log(openedModal);
  }
  // const [overlay, setOverlay] = useState<ReactNode>(<IsolatedModal session={0} />);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allProfilesFullUrl = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const userStats = userMeta && userMeta?.stats;
  const therapistsTable = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolID = queryParameters.get('schoolID');
  const schoolType = queryParameters.get('schoolType');
  const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  const accountType = schoolType === 'RegularSchool' ? 'Mainstream' : 'SEN Schools';
  const schoolInView = Number(schoolID);
  const therapistSessionNotesRegularSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
  const therapistSessionNotesSENSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;

  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;
  // ${Number(schoolID)}
  const baseMsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['Mainstream School'.Id = school_id];
concat(students)`;

  const baseSsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['SEN schools'.Id = school_id];
concat(students)`;

  const getAllStudentsEP = schoolType === 'RegularSchool' ? msQueryEP : ssQueryEP;
  const getAllStudentsQuery = schoolType === 'RegularSchool' ? baseMsQuery : baseSsQuery;
  const getCurrentSchoolsStudents =
    schoolType === 'RegularSchool' ? caseNotesMainstream : caseNotesSEN;

  // const getAllStudents = tabIndex === 0 ? caseNotesMainstream : caseNotesSEN;

  const NotesEP =
    schoolType == 'RegularSchool'
      ? therapistSessionNotesRegularSchoolsEP
      : therapistSessionNotesSENSchoolsEP;

  function getTheCounts(url: string) {
    const headers = {
      Authorization: process.env.REACT_APP_NINOX_API_BEARER_TOKEN!,
      'Content-Type': 'application/json',
    };

    return fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log('Error:', error);
      });
    //         fetch('https://example.com?' + new URLSearchParams({
    //     foo: 'value',
    //     bar: 2,
    // }))
  }

  const avgInteractionsQuery = `let school_id := ${schoolID};
let today_date := today();
let today_year := year(today_date);
let today_month := month(today_date);
let school_years_array := if today_month >= 9 then
		[today_year, number(today_year + 1)]
	else
		[number(today_year - 1), today_year]
	end;
let session_notes := (select 'Sessions + Notes')[Caseload.'Mainstream School'.Id = school_id and
		(Date >= date(number(item(school_years_array, 0)), 9, 1) and
			Date <= date(number(item(school_years_array, 1)), 8, 1))];
count(session_notes);
let students := (select Students)['Mainstream School'.Id = school_id and 'Date of Discharge' = null];
count(students);
format(count(session_notes) / count(students), "0.00")`;

  function getCaseNoteCount(students: any) {
    // console.log('students: ', students);
    getAllRowsById(NotesEP).then((data) => {
      const myData = data;
      // setSessionNotes(myData);
      setCaseNoteCount([]);
      students.map((i: any, k: any) => {
        // for each student get array of casenotes
        setStudentAges(
          students.map((t: any, k: any) => {
            const born = moment(t?.fields['DOB']).format('YYYY-MM-DD');
            var a = moment(formattedToday);
            var b = moment(born);
            const dobTest = a.diff(b, 'years');

            return dobTest;
          })
        );
        myData.filter((z: any) => Number(z.fields['Caseload']) === i.id)?.length > 0 &&
          setCaseNoteCount((caseNoteCount: any) => [
            ...caseNoteCount,
            myData.filter((z: any) => Number(z.fields['Caseload']) === i.id)?.length,
          ]);
        // setCaseNoteCountTracker(1);
        // console.log(myData.filter((z: any) => Number(z.fields['Caseload']) === i.id));
      });
    });
  }
  const initialized = useRef(false);
  var ranonce = false;

  useEffect(() => {
    setSignedInUserType('clinical');

    const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
    const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;

    const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
    const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
    const EP = process.env.REACT_APP_USER_DATA;

    // query = let a := 51; count((select 'Sessions + Notes')[Caseload.'Mainstream School'.Id = a and Caseload.'Date of Discharge' = null])
    let params = new URLSearchParams();
    let table = 'Sessions + Notes';
    params.set('query', table);

    //     myURL.searchParams.set('type',myType);
    // myURL.searchParams.set('name',myID);

    const notesCountRegSchool = `${process.env.REACT_APP_MS_QUERY_EP}?` + params;

    const notesCountSenSchool = `${process.env.REACT_APP_SS_QUERY_EP}?query=let%20a%20%3A%3D%20${schoolID}%3B%20count((select%20'Sessions%20%2B%20Notes')%5BCaseload.'SEN%20Schools'.Id%20%3D%20a%5D)`;
    const NotesCountEP = schoolType == 'RegularSchool' ? notesCountRegSchool : notesCountSenSchool;

    // setForm({ ...form, mainstreamSchool: String(schoolFilter) });
    setNewNote(newNoteDefault);

    // getAllRowsById(NotesEP).then((data) => {
    //   const myData = data;
    //   // console.log('ALL NOTES : ', JSON.stringify(myData.length));
    // });

    getAllRowsById(regularSchoolsEP).then((data) => {
      const myData = data;
      setSchools(
        myData.filter(
          (i: any) =>
            i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            i?.fields['Additional Therapists']?.includes(localStorage.getItem('accountEmail'))
        )
      );
    });
    getAllRowsById(senSchoolsEP).then((data) => {
      const myData = data;
      setSenSchools(
        myData.filter(
          (i: any) =>
            i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            i?.fields['Additional Therapists']?.includes(localStorage.getItem('accountEmail'))
        )
      );
    });
    // getAllRowsById(process.env.REACT_APP_UL_NOTES_TABLE).then((data) => setNotes(data));

    // getTheCounts(NotesCountEP).then((data) => {
    //   const myData = data;
    //   console.log('Session notes count : ', JSON.stringify(myData));
    //   console.log(NotesCountEP);
    //   setNotesPerSchool(myData);
    // });

    const delayDebounceFn = setTimeout(() => {
      searchValueLowerCase?.length >= 1 &&
        setCaseNotes(
          caseNotes.filter((caseNote: any) => {
            return caseNote?.fields['Student/Patient Name']
              .toLocaleLowerCase()
              .includes(searchValueLowerCase);
            // ||
            // caseNote?.fields['DOB'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Class year'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Class name'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Gender'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Ethnicity'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Referral Date']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Language 1'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Language 2'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Language 3'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Other Languages']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Local Patient Identifier']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Date of Assessment']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Report'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Report sent'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Report Link'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Notes'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Date of review']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Name of Therapist']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Primary Medical Diagnosis']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Additional Medical Diagnosis']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Other Medical Diagnosis']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Primary Communication/ Swallowing Disorder']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Additional Communication/ Swallowing Disorder']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Other Communication/ Swallowing Disorder']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['EHCP'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Direct Hours'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Indirect Hours']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Hours completed']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Hours remaining']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Plan'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Primary TOMs Scale']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Secondary TOMs Scale']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['End of Episode/ Discharge Code/ Description']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Date of Discharge']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase)
          })
        );
    }, 500);
    setSchoolFilter(Number(schoolID));
    setShowAddStudent(Number(schoolID));
    setSchoolSelected(schools.filter((i: schoolsFil) => i.id === schoolInView));
    return () => clearTimeout(delayDebounceFn);
  }, [searchValueLowerCase]);

  useEffect(() => {
    //   // if (!initialized.current) {
    //   //   initialized.current = true;
    //   getAllRowsById(getAllStudents).then((data) => {
    //     const allStudents = data;

    //     getCaseNoteCount(allStudents);
    //   });
    //   //   console.log('i fire once');
    //   // }

    getAllRowsByEncodedQuery(getAllStudentsEP, getAllStudentsQuery).then((data: any) => {
      const allStudents = data.split(',');
      // console.log(allStudents);
      // console.log(getAllStudentsEP);
      // console.log(getAllStudentsQuery);
      data?.length > 0 &&
        getMultipleApiData(getCurrentSchoolsStudents!, bearerToken!, allStudents).then(
          (data: any) => {
            setLoadingIcon(1);
            setCaseNotes(
              data
                // .filter(
                //   (i: any) =>
                //     i.fields['Date of Discharge'] === undefined ||
                //     i.fields['Date of Discharge'] === null ||
                //     i.fields['Date of Discharge'] === ''
                // )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['Student/Patient Name'].localeCompare(
                        a?.fields['Student/Patient Name']
                      )
                    : a?.fields['Student/Patient Name'].localeCompare(
                        b?.fields['Student/Patient Name']
                      )
                )
            );
            getCaseNoteCount(
              data
                // .filter(
                //   (i: any) =>
                //     i.fields['Date of Discharge'] === undefined ||
                //     i.fields['Date of Discharge'] === null ||
                //     i.fields['Date of Discharge'] === ''
                // )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['Student/Patient Name'].localeCompare(
                        a?.fields['Student/Patient Name']
                      )
                    : a?.fields['Student/Patient Name'].localeCompare(
                        b?.fields['Student/Patient Name']
                      )
                )
            );
          }
        );
      // getCaseNoteCount(allStudents);
    });
    getAllRowsByEncodedQuery(getAllStudentsEP, avgInteractionsQuery).then((data: any) => {
      console.log('avg int: ', data);
      setAvgint(data);
    });
  }, [userStats, schoolFilter, genders]);

  interface schoolsFil {
    id: number;
  }
  // useEffect(() => {
  // }, []);

  interface BookingFields {
    'Block Sessions?': string;
    'Brief Notes': string;
    'Event Created by': string;
    'Session End': string;
    'Session Invoiced?': boolean;
    'Session Start': string;
    'Session Type': string;
  }

  interface Bookings {
    createdAt: string;
    createdBy: string;
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    fields: BookingFields;
  }

  const handleNoteType = (e: any) => {
    setNewNote({ ...newNote, note_type: e.target.value });
  };

  const handleNoteDetails = (e: any) => {
    let inputValue = e.target.value;
    setNewNote({ ...newNote, note_details: inputValue });
  };

  let currentUrlParams = new URLSearchParams(window.location.search);
  const headingsHidden = ['Student/Patient Name', 'Case notes', 'Manage', 'TOMS'];
  const headings = [
    'Student/Patient Name',

    'DOB',
    'Age',
    'Class year',
    'Class name',
    'Gender',
    'Ethnicity',
    'Referral Date',
    'Language 1',
    'Language 2',
    'Language 3',
    'Other Languages',
    'Local Patient Identifier',
    'Date of Assessment',
    'Report Date',
    'Report sent',
    'Report Link',
    'Notes',
    'Date of review',
    'Name of Therapist',
    'Primary Medical Diagnosis',
    'Additional Medical Diagnosis',
    'Other Medical Diagnosis',
    'Primary Communication/ Swallowing Disorder',
    'Additional Communication/ Swallowing Disorder',
    'Other Communication/ Swallowing Disorder',
    'EHCP',
    'Direct Hours',
    'Indirect Hours',
    'Hours completed',
    'Hours remaining',
    'Plan',
    'Primary TOMs Scale',
    'Secondary TOMs Scale',
    'Date of Discharge',
    'Case notes',
    'Manage',
    'TOMS',
  ];
  const optionsGenders: ApexOptions = {
    chart: {
      width: 'auto',
      type: 'pie',
    },
    labels: ['Males', 'Females'],
    legend: {
      position: 'bottom',
    },
    colors: ['#40A83D', '#D6D944'],
    stroke: {
      curve: 'smooth',
      colors: ['transparent'],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
      enabled: true,
      style: {
        fontSize: '18px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
      },
    },
  };
  let classYears = [
    caseNotes
      ? caseNotes?.map((i: any, k: any) => {
          return i?.fields['Class year'];
        })
      : 0,
  ];
  let classYearsFilterList = classYears[0];
  let uniqueClassYears = classYearsFilterList?.sort().filter((element: any, index: any) => {
    return classYearsFilterList?.indexOf(element) === index;
  });

  function displayDataForClass(uniqueClassYearsArray: any) {
    const newArray = uniqueClassYearsArray?.map(
      (z: any, k: any) => caseNotes.filter((i: any) => i?.fields['Class year'] === z)?.length
    );
    return newArray;
  }

  function displayGenderCountForClass(gendersArray: any) {
    const newArray = gendersArray?.map(
      (z: any, k: any) => caseNotes.filter((i: any) => i?.fields['Gender'] === z)?.length
    );
    return newArray;
  }
  const genderTypesArray = ['Male', 'Female'];

  // const barChartData: ApexChart = {
  //   data: [21, 22, 10, 28, 16, 21, 13, 30],
  // };
  const barChartOptions: any = {
    // Define your chart options here
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      style: {
        color: '#444',
      },
    },
    series: [
      {
        name: 'No of students',
        data: displayDataForClass(uniqueClassYears),
      },
    ],
    xaxis: {
      categories: uniqueClassYears,
    },
    colors: ['#0C7348'],
  };

  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <Flex alignItems="center" gap="2">
            <Box p={2} mb={5} w={'70%'}>
              <HStack spacing={5}>
                <BackButton navigate={navigate} />
                <Heading as={'h3'} size={'md'}>
                  {schoolFilter === 0
                    ? schoolType === 'RegularSchool'
                      ? 'Mainstream Schools'
                      : 'SEN Schools'
                    : schoolType === 'RegularSchool'
                    ? schools.filter((i: any) => i.id === Number(schoolID))[0]?.fields[
                        'School Name'
                      ]
                    : senSchools.filter((i: any) => i.id === Number(schoolID))[0]?.fields[
                        'School Name'
                      ]}
                  {/* ={schools.filter((i: any) => i.id === Number(schoolID))[0]?.fields['School Name']}{' '}
                  = {schoolFilter} = {schoolID} */}
                </Heading>
              </HStack>
            </Box>
            <Spacer />
            <HStack mt={-5} mr={5}>
              {/* <Text>{schoolFilter}</Text> */}
              <Button
                onClick={() => {
                  setTabIndex(0);
                  setSchoolFilter(Number(0));
                  setSearchValue('');
                  setSearchValueLowerCase('');
                  setActiveSearch(0);
                  setCaseNotes([]);
                  navigate('?schoolID=0&schoolType=RegularSchool');
                }}
                variant={schoolType === 'RegularSchool' ? 'defaultButton' : 'default'}
              >
                Mainstream Schools
              </Button>
              <Button
                onClick={() => {
                  setTabIndex(1);
                  setSchoolFilter(Number(0));
                  setSearchValue('');
                  setSearchValueLowerCase('');
                  setActiveSearch(0);
                  setCaseNotes([]);
                  navigate('?schoolID=0&schoolType=SENSchool');
                }}
                variant={schoolType !== 'RegularSchool' ? 'defaultButton' : 'default'}
              >
                SEN Schools
              </Button>
            </HStack>
            {schoolType === 'RegularSchool' ? (
              <Box mt={-5} bg={'green.100'} p={2.5} borderRadius={10}>
                <HStack gap="2" justifyContent={'right'}>
                  {/* <FormLabel fontWeight={'bold'} mt={2.5}>
                    Select a school
                  </FormLabel> */}
                  <Select
                    value={schoolFilter}
                    placeholder={'Select a Mainstream School'}
                    width={'auto'}
                    onChange={(e) => {
                      setSchoolFilter(Number(e.target.value));
                      navigate('/schools?schoolID=' + e.target.value + '&schoolType=RegularSchool');
                      // setGenders();
                    }}
                    bg={'white'}
                  >
                    {schools &&
                      schools?.sort(orderSchools).map((i: any, k: number) => (
                        <option value={i?.id} key={k}>
                          {i?.fields['School Name']}
                        </option>
                      ))}
                  </Select>
                  {/* <Text>School selected: {schoolFilter}</Text>
                <Text>Selected tab: {tabIndex}</Text> */}
                </HStack>
              </Box>
            ) : (
              <Box mt={-5} bg={'green.100'} p={2.5} borderRadius={10}>
                <HStack gap="2" justifyContent={'right'}>
                  {/* <FormLabel fontWeight={'bold'} mt={2.5}>
                    Select a SENCO school
                  </FormLabel> */}
                  <Select
                    value={schoolFilter}
                    placeholder="Select SEN School"
                    width={'auto'}
                    onChange={(e) => {
                      setSchoolFilter(Number(e.target.value));
                      navigate('/schools?schoolID=' + e.target.value + '&schoolType=SENSchool');
                      // setGenders();
                    }}
                    bg={'white'}
                  >
                    {senSchools &&
                      senSchools?.sort(orderSchools).map((i: any, k: number) => (
                        <option value={i?.id} key={k}>
                          {i?.fields['School Name']}
                        </option>
                      ))}
                  </Select>
                  {/* <Text>School selected: {schoolFilter}</Text>
                <Text>Selected tab: {tabIndex}</Text> */}
                </HStack>
              </Box>
            )}
          </Flex>
          {schoolID === '0' ? (
            ''
          ) : (
            <Box mt={5}>
              <Flex overflowX={'scroll'}>
                <Stat
                  bg={'gray.100'}
                  p={5}
                  borderRadius={5}
                  mr={5}
                  h={'300px'}
                  color={'black'}
                  mb={10}
                >
                  <HStack borderBottom={'1px solid black'}>
                    <Box w={'50%'} h={'130px'} p={'10px'} borderRight={'1px solid black'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        Total students{' '}
                        <Tooltip label={totalStudentsHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      <StatNumber
                        fontSize={30}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'left'}
                        alignItems={'left'}
                      >
                        {caseNotes?.length}
                      </StatNumber>
                    </Box>
                    <Box w={'50%'} h={'130px'} p={'10px'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        EHCP{' '}
                        <Tooltip label={ehcpHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      <StatNumber
                        fontSize={30}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'left'}
                        alignItems={'left'}
                      >
                        {caseNotes?.filter((i: any) => i?.fields['EHCP'] === true)?.length}
                      </StatNumber>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box w={'50%'} h={'130px'} p={'10px'} borderRight={'1px solid black'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        Avg. interactions{' '}
                        <Tooltip label={avgInteractionsHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      <StatNumber
                        fontSize={30}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'left'}
                        alignItems={'left'}
                      >
                        {/* {(
                          ((Number(caseNoteCount.length) >= 1 &&
                            caseNoteCount?.reduce((partialSum: any, a: any) => partialSum + a, 0)) /
                            caseNotes.length) *
                          100
                        ).toFixed(0)} */}
                        {avgInt}
                      </StatNumber>
                    </Box>
                    <Box w={'50%'} h={'130px'} p={'10px'}>
                      <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'left'}>
                        Avg. age{' '}
                        <Tooltip label={avgAgeHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={0} />
                        </Tooltip>
                      </StatLabel>
                      <StatNumber
                        fontSize={30}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'left'}
                        alignItems={'left'}
                      >
                        {(
                          (Number(studentAges?.length) >= 1 &&
                            studentAges?.reduce((partialSum: any, a: any) => partialSum + a, 0)) /
                          caseNotes?.length
                        ).toFixed(0)}
                      </StatNumber>
                    </Box>
                  </HStack>
                </Stat>
                <Stat
                  bg={'gray.100'}
                  p={5}
                  borderRadius={5}
                  mr={5}
                  h={'300px'}
                  color={'black'}
                  mb={10}
                >
                  <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'center'}>
                    Genders{' '}
                    <Tooltip label={genderHint} style={{ cursor: 'pointer' }}>
                      <Icon mt={0} />
                    </Tooltip>
                  </StatLabel>
                  <StatNumber
                    // fontSize={90}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <ReactApexChart
                      options={optionsGenders}
                      series={displayGenderCountForClass(genderTypesArray)}
                      type="pie"
                      height={240}
                    />
                  </StatNumber>
                </Stat>
                <Stat
                  bg={'gray.100'}
                  p={5}
                  borderRadius={5}
                  mr={5}
                  h={'300px'}
                  color={'black'}
                  mb={10}
                >
                  <StatLabel fontSize={'16px'} height={'24px'} mb={5} textAlign={'center'}>
                    Class years{' '}
                    <Tooltip label={classYearsHint} style={{ cursor: 'pointer' }}>
                      <Icon mt={0} />
                    </Tooltip>
                  </StatLabel>
                  <StatNumber
                    // fontSize={90}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <ReactApexChart
                      options={barChartOptions && barChartOptions}
                      series={barChartOptions?.series}
                      type="bar"
                      height={240}
                    />
                  </StatNumber>
                </Stat>
              </Flex>
            </Box>
          )}
          <Box mt={-5}>
            <Tabs
              variant="soft-rounded"
              colorScheme="green"
              align="start"
              onChange={(index) => {
                setTabIndex(index);
                setSchoolFilter(Number(0));
                setSearchValue('');
                setSearchValueLowerCase('');
                setActiveSearch(0);
                setCaseNotes([]);
                tabIndex === 0
                  ? navigate(`/schools?schoolID=0&schoolType=SENSchool`)
                  : navigate(`/schools?schoolID=0&schoolType=RegularSchool`);
                // console.log(schoolType);
              }}
            >
              <TabList display={'none'}>
                <Tab>Mainstream Schools</Tab>
                <Tab>SEN Schools</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <IsolatedModal modalState={openedModal} />
                  <Box p={2} mb={5}>
                    <HStack spacing={5}>
                      <Box width={'40%'}>
                        <HStack>
                          <InputGroup>
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <InputLeftElement
                                pointerEvents="none"
                                children={<Search2Icon color="gray.600" />}
                              />
                            )}
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <Input
                                pr="4.5rem"
                                placeholder="Search for a student"
                                onChange={(e) => handleSearch(e)}
                                type="text"
                                value={searchValue}
                                size="xl"
                                borderRadius={5}
                              />
                            )}
                            {activeSearch === 1 ? (
                              <InputRightAddon
                                children={<CloseIcon color="gray.600" />}
                                onClick={() => {
                                  clearSearch();
                                }}
                                cursor={'pointer'}
                              />
                            ) : schoolID === '0' ? (
                              ''
                            ) : (
                              <InputRightAddon children="Search" cursor={'pointer'} />
                            )}
                          </InputGroup>
                        </HStack>
                      </Box>
                      <Box width={'60%'}>
                        <Box textAlign={'right'} width={'100%'}>
                          {schoolID === '0' ? (
                            ''
                          ) : (
                            <HStack>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  handleModalOpen(
                                    schoolType === 'RegularSchool' ? 'RegularSchool' : 'SENSchool'
                                  );
                                }}
                              >
                                Add Student
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  window.open(
                                    'https://sites.google.com/unlockinglanguage.co.uk/school-information/home',
                                    '_blank'
                                  );
                                }}
                              >
                                School information
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  window.open(
                                    'https://unlockinglanguage.retool.com/embedded/public/4ee07fbe-6f51-4bbe-87c8-eba50f99a80d',
                                    '_blank'
                                  );
                                }}
                              >
                                Group notes
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  showAllColumns === 0
                                    ? setShowAllColumns(1)
                                    : setShowAllColumns(0);
                                }}
                                backgroundColor={showAllColumns === 0 ? 'brand.300' : 'brand.400'}
                              >
                                {showAllColumns === 0
                                  ? 'Show all student data'
                                  : 'Hide all student data'}
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  showDischarged === 0
                                    ? setShowDischarged(1)
                                    : setShowDischarged(0);
                                }}
                                backgroundColor={showDischarged === 0 ? 'brand.300' : 'brand.400'}
                              >
                                {showDischarged === 0
                                  ? 'Show discharged students'
                                  : 'Hide discharged students'}
                              </Button>
                            </HStack>
                          )}
                        </Box>
                      </Box>
                    </HStack>
                  </Box>

                  <TableContainer className="caseloadContainer">
                    {localStorage.getItem('accountType') !== undefined &&
                      localStorage.getItem('accountType') === 'Therapist Staff' && (
                        <Table variant="striped">
                          <Thead>
                            <Tr>
                              {showAllColumns === 0
                                ? headingsHidden.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i} {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))
                                : headings.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : k === 35
                                          ? { position: 'sticky', right: '240px', width: '120px' }
                                          : k === 36
                                          ? { position: 'sticky', right: '120px', width: '120px' }
                                          : k === 37
                                          ? { position: 'sticky', right: '0', width: '120px' }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i} {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))}
                            </Tr>
                          </Thead>
                          {showAllColumns === 0 ? (
                            loadingIcon === 0 && schoolID !== '0' ? (
                              <Box
                                width={'100%'}
                                alignContent={'center'}
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                pt={5}
                              >
                                <Spinner
                                  thickness="8px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="brand.200"
                                  size="xl"
                                />
                                loading...
                              </Box>
                            ) : (
                              <Tbody>
                                {caseNotes && showDischarged === 1
                                  ? caseNotes
                                      .filter((z: any) => z.fields['Date of Discharge'])
                                      .map((i: any, k: any) => {
                                        const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                        var a = moment(formattedToday);
                                        var b = moment(born);
                                        const dobTest = a.diff(b, 'years');
                                        return (
                                          <Tr
                                            key={k}
                                            // style={
                                            //   i.fields['Date of Discharge'] === undefined ||
                                            //   i.fields['Date of Discharge'] === null ||
                                            //   i.fields['Date of Discharge'] === ''
                                            //     ? { backgroundColor: 'gray.300' }
                                            //     : { backgroundColor: 'pink' }
                                            // }
                                          >
                                            <Td
                                              // style={
                                              //   k % 2 == 0
                                              //     ? i.fields['Date of Discharge'] === undefined ||
                                              //       i.fields['Date of Discharge'] === null ||
                                              //       i.fields['Date of Discharge'] === ''
                                              //       ? {
                                              //           position: 'sticky',
                                              //           left: '0',
                                              //           width: 'auto',
                                              //           zIndex: 9,
                                              //           fontWeight: 'bold',
                                              //           background: 'gray.100',
                                              //         }
                                              //       : {
                                              //           position: 'sticky',
                                              //           left: '0',
                                              //           width: 'auto',
                                              //           zIndex: 9,
                                              //           fontWeight: 'bold',
                                              //           background: 'pink',
                                              //         }
                                              //     : i.fields['Date of Discharge'] === undefined ||
                                              //       i.fields['Date of Discharge'] === null ||
                                              //       i.fields['Date of Discharge'] === ''
                                              //     ? {
                                              //         position: 'sticky',
                                              //         left: '0',
                                              //         width: 'auto',
                                              //         zIndex: 9,
                                              //         fontWeight: 'bold',
                                              //         background: 'white',
                                              //       }
                                              //     : {
                                              //         position: 'sticky',
                                              //         left: '0',
                                              //         width: 'auto',
                                              //         zIndex: 9,
                                              //         fontWeight: 'bold',
                                              //         background: 'pink',
                                              //       }
                                              // }

                                              style={
                                                k % 2 == 0
                                                  ? {
                                                      position: 'sticky',
                                                      left: '0',
                                                      width: '240px',
                                                      zIndex: 9,
                                                      fontWeight: 'bold',
                                                      background: 'gray.100',
                                                    }
                                                  : {
                                                      position: 'sticky',
                                                      left: '0',
                                                      width: '240px',
                                                      zIndex: 9,
                                                      fontWeight: 'bold',
                                                      background: 'white',
                                                    }
                                              }
                                            >
                                              {i?.fields['Student/Patient Name']}
                                            </Td>
                                            <Td>
                                              <Button
                                                bg={'brand.300'}
                                                color={'white'}
                                                _hover={{
                                                  background: 'brand.200',
                                                }}
                                                onClick={() => {
                                                  schoolType === 'RegularSchool'
                                                    ? navigate(
                                                        '/therapist-session/' +
                                                          i.id +
                                                          '?schoolType=RegularSchool'
                                                      )
                                                    : navigate(
                                                        '/therapist-session/' +
                                                          i.id +
                                                          '?schoolType=SENSchool'
                                                      );
                                                }}
                                              >
                                                View
                                              </Button>
                                            </Td>
                                            <Td>
                                              <Button
                                                bg={'brand.300'}
                                                color={'white'}
                                                _hover={{
                                                  background: 'brand.200',
                                                }}
                                                onClick={() => {
                                                  // tabIndex === 0
                                                  schoolType === 'RegularSchool'
                                                    ? navigate(
                                                        '/edit-student/' +
                                                          i.id +
                                                          '?schoolType=RegularSchool'
                                                      )
                                                    : navigate(
                                                        '/edit-student/' +
                                                          i.id +
                                                          '?schoolType=SENSchool'
                                                      );
                                                }}
                                              >
                                                Manage
                                              </Button>
                                            </Td>
                                            <Td>
                                              <Button
                                                bg={'brand.300'}
                                                color={'white'}
                                                _hover={{
                                                  background: 'brand.200',
                                                }}
                                                onClick={() => {
                                                  // tabIndex === 0
                                                  schoolType === 'RegularSchool'
                                                    ? navigate(
                                                        '/toms-rating/' +
                                                          i.id +
                                                          '?schoolType=RegularSchool'
                                                      )
                                                    : navigate(
                                                        '/toms-rating/' +
                                                          i.id +
                                                          '?schoolType=SENSchool'
                                                      );
                                                }}
                                              >
                                                TOMS
                                              </Button>
                                            </Td>
                                          </Tr>
                                        );
                                      })
                                  : caseNotes
                                      .filter(
                                        (z: any) =>
                                          z.fields['Date of Discharge'] === undefined ||
                                          z.fields['Date of Discharge'] === null ||
                                          z.fields['Date of Discharge'] === ''
                                      )
                                      .map((i: any, k: any) => {
                                        const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                        var a = moment(formattedToday);
                                        var b = moment(born);
                                        const dobTest = a.diff(b, 'years');
                                        return (
                                          <Tr
                                            key={k}
                                            // style={
                                            //   i.fields['Date of Discharge'] === undefined ||
                                            //   i.fields['Date of Discharge'] === null ||
                                            //   i.fields['Date of Discharge'] === ''
                                            //     ? { backgroundColor: 'gray.300' }
                                            //     : { backgroundColor: 'pink' }
                                            // }
                                          >
                                            <Td
                                              // style={
                                              //   k % 2 == 0
                                              //     ? i.fields['Date of Discharge'] === undefined ||
                                              //       i.fields['Date of Discharge'] === null ||
                                              //       i.fields['Date of Discharge'] === ''
                                              //       ? {
                                              //           position: 'sticky',
                                              //           left: '0',
                                              //           width: 'auto',
                                              //           zIndex: 9,
                                              //           fontWeight: 'bold',
                                              //           background: 'gray.100',
                                              //         }
                                              //       : {
                                              //           position: 'sticky',
                                              //           left: '0',
                                              //           width: 'auto',
                                              //           zIndex: 9,
                                              //           fontWeight: 'bold',
                                              //           background: 'pink',
                                              //         }
                                              //     : i.fields['Date of Discharge'] === undefined ||
                                              //       i.fields['Date of Discharge'] === null ||
                                              //       i.fields['Date of Discharge'] === ''
                                              //     ? {
                                              //         position: 'sticky',
                                              //         left: '0',
                                              //         width: 'auto',
                                              //         zIndex: 9,
                                              //         fontWeight: 'bold',
                                              //         background: 'white',
                                              //       }
                                              //     : {
                                              //         position: 'sticky',
                                              //         left: '0',
                                              //         width: 'auto',
                                              //         zIndex: 9,
                                              //         fontWeight: 'bold',
                                              //         background: 'pink',
                                              //       }
                                              // }
                                              style={
                                                k % 2 == 0
                                                  ? {
                                                      position: 'sticky',
                                                      left: '0',
                                                      width: '240px',
                                                      zIndex: 9,
                                                      fontWeight: 'bold',
                                                      background: 'gray.100',
                                                    }
                                                  : {
                                                      position: 'sticky',
                                                      left: '0',
                                                      width: '240px',
                                                      zIndex: 9,
                                                      fontWeight: 'bold',
                                                      background: 'white',
                                                    }
                                              }
                                            >
                                              {i?.fields['Student/Patient Name']}
                                            </Td>
                                            <Td>
                                              <Button
                                                bg={'brand.300'}
                                                color={'white'}
                                                _hover={{
                                                  background: 'brand.200',
                                                }}
                                                onClick={() => {
                                                  schoolType === 'RegularSchool'
                                                    ? navigate(
                                                        '/therapist-session/' +
                                                          i.id +
                                                          '?schoolType=RegularSchool'
                                                      )
                                                    : navigate(
                                                        '/therapist-session/' +
                                                          i.id +
                                                          '?schoolType=SENSchool'
                                                      );
                                                }}
                                              >
                                                View
                                              </Button>
                                            </Td>
                                            <Td>
                                              <Button
                                                bg={'brand.300'}
                                                color={'white'}
                                                _hover={{
                                                  background: 'brand.200',
                                                }}
                                                onClick={() => {
                                                  // tabIndex === 0
                                                  schoolType === 'RegularSchool'
                                                    ? navigate(
                                                        '/edit-student/' +
                                                          i.id +
                                                          '?schoolType=RegularSchool'
                                                      )
                                                    : navigate(
                                                        '/edit-student/' +
                                                          i.id +
                                                          '?schoolType=SENSchool'
                                                      );
                                                }}
                                              >
                                                Manage
                                              </Button>
                                            </Td>
                                            <Td>
                                              <Button
                                                bg={'brand.300'}
                                                color={'white'}
                                                _hover={{
                                                  background: 'brand.200',
                                                }}
                                                onClick={() => {
                                                  // tabIndex === 0
                                                  schoolType === 'RegularSchool'
                                                    ? navigate(
                                                        '/toms-rating/' +
                                                          i.id +
                                                          '?schoolType=RegularSchool'
                                                      )
                                                    : navigate(
                                                        '/toms-rating/' +
                                                          i.id +
                                                          '?schoolType=SENSchool'
                                                      );
                                                }}
                                              >
                                                TOMS
                                              </Button>
                                            </Td>
                                          </Tr>
                                        );
                                      })}
                              </Tbody>
                            )
                          ) : (
                            <Tbody>
                              {caseNotes && showDischarged === 1
                                ? caseNotes
                                    .filter((z: any) => z.fields['Date of Discharge'])
                                    .map((i: any, k: any) => {
                                      const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                      var a = moment(formattedToday);
                                      var b = moment(born);
                                      const dobTest = a.diff(b, 'years');
                                      return (
                                        <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                                          <Td
                                            // style={
                                            //   k % 2 == 0
                                            //     ? i.fields['Date of Discharge'] === undefined ||
                                            //       i.fields['Date of Discharge'] === null ||
                                            //       i.fields['Date of Discharge'] === ''
                                            //       ? {
                                            //           position: 'sticky',
                                            //           left: '0',
                                            //           width: 'auto',
                                            //           zIndex: 9,
                                            //           fontWeight: 'bold',
                                            //           background: 'gray.100',
                                            //         }
                                            //       : {
                                            //           position: 'sticky',
                                            //           left: '0',
                                            //           width: 'auto',
                                            //           zIndex: 9,
                                            //           fontWeight: 'bold',
                                            //           background: 'pink',
                                            //         }
                                            //     : i.fields['Date of Discharge'] === undefined ||
                                            //       i.fields['Date of Discharge'] === null ||
                                            //       i.fields['Date of Discharge'] === ''
                                            //     ? {
                                            //         position: 'sticky',
                                            //         left: '0',
                                            //         width: 'auto',
                                            //         zIndex: 9,
                                            //         fontWeight: 'bold',
                                            //         background: 'white',
                                            //       }
                                            //     : {
                                            //         position: 'sticky',
                                            //         left: '0',
                                            //         width: 'auto',
                                            //         zIndex: 9,
                                            //         fontWeight: 'bold',
                                            //         background: 'pink',
                                            //       }
                                            // }
                                            style={
                                              k % 2 == 0
                                                ? {
                                                    position: 'sticky',
                                                    left: '0',
                                                    width: '240px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'gray.100',
                                                  }
                                                : {
                                                    position: 'sticky',
                                                    left: '0',
                                                    width: '240px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'white',
                                                  }
                                            }
                                          >
                                            {i?.fields['Student/Patient Name']}
                                          </Td>
                                          <Td>
                                            {i?.fields['DOB'] &&
                                              moment(i?.fields['DOB']).format('DD-MM-YYYY')}
                                          </Td>
                                          <Td>{dobTest}</Td>
                                          <Td>{i?.fields['Class year']}</Td>
                                          <Td>{i?.fields['Class name']}</Td>
                                          <Td>{i?.fields['Gender']}</Td>
                                          <Td>{i?.fields['Ethnicity']}</Td>
                                          <Td>{i?.fields['Referral Date']}</Td>
                                          <Td>{i?.fields['Language 1']}</Td>
                                          <Td>{i?.fields['Language 2']}</Td>
                                          <Td>{i?.fields['Language 3']}</Td>
                                          <Td>{i?.fields['Other Languages']}</Td>
                                          <Td>{i?.fields['Local Patient Identifier']}</Td>
                                          <Td>{i?.fields['Date of Assessment']}</Td>
                                          <Td>
                                            {i?.fields['Report date'] === undefined
                                              ? 'No Date'
                                              : i?.fields['Report date']}
                                          </Td>
                                          <Td>
                                            {i?.fields['Report sent'] === true ? 'Yes' : 'No'}
                                          </Td>
                                          <Td>{i?.fields['Report Link']}</Td>
                                          <Td>{i?.fields['Notes']}</Td>
                                          <Td>{i?.fields['Date of review']}</Td>
                                          <Td>
                                            {' '}
                                            {i?.fields['Name of Therapist'] === undefined
                                              ? 'Not allocated'
                                              : i?.fields['Name of Therapist']}
                                          </Td>
                                          <Td>{i?.fields['Primary Medical Diagnosis']}</Td>
                                          <Td>{i?.fields['Additional Medical Diagnosis']}</Td>
                                          <Td>{i?.fields['Other Medical Diagnosis']}</Td>
                                          <Td>
                                            {
                                              i?.fields[
                                                'Primary Communication/ Swallowing Disorder'
                                              ]
                                            }
                                          </Td>
                                          <Td>
                                            {
                                              i?.fields[
                                                'Additional Communication/ Swallowing Disorder'
                                              ]
                                            }
                                          </Td>
                                          <Td>
                                            {i?.fields['Other Communication/ Swallowing Disorder']}
                                          </Td>
                                          <Td>{i?.fields['EHCP'] ? 'True' : 'False'}</Td>
                                          <Td>{i?.fields['Direct Hours']}</Td>
                                          <Td>{i?.fields['Indirect Hours']}</Td>
                                          <Td>{i?.fields['Hours completed']}</Td>
                                          <Td>{i?.fields['Hours remaining']}</Td>
                                          <Td>{i?.fields['Plan']}</Td>
                                          <Td>{i?.fields['Primary TOMs Scale']}</Td>
                                          <Td>{i?.fields['Secondary TOMs Scale']}</Td>
                                          <Td>
                                            <Text
                                              fontWeight={'bold'}
                                              color={'red'}
                                              textTransform={'uppercase'}
                                            >
                                              {i?.fields['Date of Discharge']}
                                            </Text>
                                          </Td>
                                          <Td
                                            style={
                                              k % 2 == 0
                                                ? {
                                                    position: 'sticky',
                                                    right: '240px',
                                                    width: '120px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'gray.100',
                                                  }
                                                : {
                                                    position: 'sticky',
                                                    right: '240px',
                                                    width: '120px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'white',
                                                  }
                                            }
                                          >
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                schoolType === 'RegularSchool'
                                                  ? navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              View
                                            </Button>
                                          </Td>
                                          <Td
                                            style={
                                              k % 2 == 0
                                                ? {
                                                    position: 'sticky',
                                                    right: '120px',
                                                    width: '120px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'gray.100',
                                                  }
                                                : {
                                                    position: 'sticky',
                                                    right: '120px',
                                                    width: '120px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'white',
                                                  }
                                            }
                                          >
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                // tabIndex === 0
                                                schoolType === 'RegularSchool'
                                                  ? navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              Manage
                                            </Button>
                                          </Td>
                                          <Td
                                            style={
                                              k % 2 == 0
                                                ? {
                                                    position: 'sticky',
                                                    right: '0',
                                                    width: '120px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'gray.100',
                                                  }
                                                : {
                                                    position: 'sticky',
                                                    right: '0',
                                                    width: '120px',
                                                    zIndex: 9,
                                                    fontWeight: 'bold',
                                                    background: 'white',
                                                  }
                                            }
                                          >
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                // tabIndex === 0
                                                schoolType === 'RegularSchool'
                                                  ? navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              TOMS
                                            </Button>
                                          </Td>
                                        </Tr>
                                      );
                                    })
                                : caseNotes.map((i: any, k: any) => {
                                    const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                                    var a = moment(formattedToday);
                                    var b = moment(born);
                                    const dobTest = a.diff(b, 'years');
                                    return (
                                      <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                                        <Td
                                          // style={
                                          //   k % 2 == 0
                                          //     ? i.fields['Date of Discharge'] === undefined ||
                                          //       i.fields['Date of Discharge'] === null ||
                                          //       i.fields['Date of Discharge'] === ''
                                          //       ? {
                                          //           position: 'sticky',
                                          //           left: '0',
                                          //           width: 'auto',
                                          //           zIndex: 9,
                                          //           fontWeight: 'bold',
                                          //           background: 'gray.100',
                                          //         }
                                          //       : {
                                          //           position: 'sticky',
                                          //           left: '0',
                                          //           width: 'auto',
                                          //           zIndex: 9,
                                          //           fontWeight: 'bold',
                                          //           background: 'pink',
                                          //         }
                                          //     : i.fields['Date of Discharge'] === undefined ||
                                          //       i.fields['Date of Discharge'] === null ||
                                          //       i.fields['Date of Discharge'] === ''
                                          //     ? {
                                          //         position: 'sticky',
                                          //         left: '0',
                                          //         width: 'auto',
                                          //         zIndex: 9,
                                          //         fontWeight: 'bold',
                                          //         background: 'white',
                                          //       }
                                          //     : {
                                          //         position: 'sticky',
                                          //         left: '0',
                                          //         width: 'auto',
                                          //         zIndex: 9,
                                          //         fontWeight: 'bold',
                                          //         background: 'pink',
                                          //       }
                                          // }
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  left: '0',
                                                  width: '240px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  left: '0',
                                                  width: '240px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          {i?.fields['Student/Patient Name']}
                                        </Td>
                                        <Td>
                                          {i?.fields['DOB'] &&
                                            moment(i?.fields['DOB']).format('DD-MM-YYYY')}
                                        </Td>
                                        <Td>{dobTest}</Td>
                                        <Td>{i?.fields['Class year']}</Td>
                                        <Td>{i?.fields['Class name']}</Td>
                                        <Td>{i?.fields['Gender']}</Td>
                                        <Td>{i?.fields['Ethnicity']}</Td>
                                        <Td>{i?.fields['Referral Date']}</Td>
                                        <Td>{i?.fields['Language 1']}</Td>
                                        <Td>{i?.fields['Language 2']}</Td>
                                        <Td>{i?.fields['Language 3']}</Td>
                                        <Td>{i?.fields['Other Languages']}</Td>
                                        <Td>{i?.fields['Local Patient Identifier']}</Td>
                                        <Td>{i?.fields['Date of Assessment']}</Td>
                                        <Td>
                                          {i?.fields['Report date'] === undefined
                                            ? 'No Date'
                                            : i?.fields['Report date']}
                                        </Td>
                                        <Td>{i?.fields['Report sent'] === true ? 'Yes' : 'No'}</Td>
                                        <Td>{i?.fields['Report Link']}</Td>
                                        <Td>{i?.fields['Notes']}</Td>
                                        <Td>{i?.fields['Date of review']}</Td>
                                        <Td>
                                          {' '}
                                          {i?.fields['Name of Therapist'] === undefined
                                            ? 'Not allocated'
                                            : i?.fields['Name of Therapist']}
                                        </Td>
                                        <Td>{i?.fields['Primary Medical Diagnosis']}</Td>
                                        <Td>{i?.fields['Additional Medical Diagnosis']}</Td>
                                        <Td>{i?.fields['Other Medical Diagnosis']}</Td>
                                        <Td>
                                          {i?.fields['Primary Communication/ Swallowing Disorder']}
                                        </Td>
                                        <Td>
                                          {
                                            i?.fields[
                                              'Additional Communication/ Swallowing Disorder'
                                            ]
                                          }
                                        </Td>
                                        <Td>
                                          {i?.fields['Other Communication/ Swallowing Disorder']}
                                        </Td>
                                        <Td>{i?.fields['EHCP'] ? 'True' : 'False'}</Td>
                                        <Td>{i?.fields['Direct Hours']}</Td>
                                        <Td>{i?.fields['Indirect Hours']}</Td>
                                        <Td>{i?.fields['Hours completed']}</Td>
                                        <Td>{i?.fields['Hours remaining']}</Td>
                                        <Td>{i?.fields['Plan']}</Td>
                                        <Td>{i?.fields['Primary TOMs Scale']}</Td>
                                        <Td>{i?.fields['Secondary TOMs Scale']}</Td>
                                        <Td>
                                          <Text
                                            fontWeight={'bold'}
                                            color={'red'}
                                            textTransform={'uppercase'}
                                          >
                                            {i?.fields['Date of Discharge']}
                                          </Text>
                                        </Td>
                                        <Td
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  right: '240px',
                                                  width: '120px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  right: '240px',
                                                  width: '120px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              schoolType === 'RegularSchool'
                                                ? navigate(
                                                    '/therapist-session/' +
                                                      i.id +
                                                      '?schoolType=RegularSchool'
                                                  )
                                                : navigate(
                                                    '/therapist-session/' +
                                                      i.id +
                                                      '?schoolType=SENSchool'
                                                  );
                                            }}
                                          >
                                            View
                                          </Button>
                                        </Td>
                                        <Td
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  right: '120px',
                                                  width: '120px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  right: '120px',
                                                  width: '120px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              // tabIndex === 0
                                              schoolType === 'RegularSchool'
                                                ? navigate(
                                                    '/edit-student/' +
                                                      i.id +
                                                      '?schoolType=RegularSchool'
                                                  )
                                                : navigate(
                                                    '/edit-student/' +
                                                      i.id +
                                                      '?schoolType=SENSchool'
                                                  );
                                            }}
                                          >
                                            Manage
                                          </Button>
                                        </Td>
                                        <Td
                                          style={
                                            k % 2 == 0
                                              ? {
                                                  position: 'sticky',
                                                  right: '0',
                                                  width: '120px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'gray.100',
                                                }
                                              : {
                                                  position: 'sticky',
                                                  right: '0',
                                                  width: '120px',
                                                  zIndex: 9,
                                                  fontWeight: 'bold',
                                                  background: 'white',
                                                }
                                          }
                                        >
                                          <Button
                                            bg={'brand.300'}
                                            color={'white'}
                                            _hover={{
                                              background: 'brand.200',
                                            }}
                                            onClick={() => {
                                              // tabIndex === 0
                                              schoolType === 'RegularSchool'
                                                ? navigate(
                                                    '/toms-rating/' +
                                                      i.id +
                                                      '?schoolType=RegularSchool'
                                                  )
                                                : navigate(
                                                    '/toms-rating/' + i.id + '?schoolType=SENSchool'
                                                  );
                                            }}
                                          >
                                            TOMS
                                          </Button>
                                        </Td>
                                      </Tr>
                                    );
                                  })}
                            </Tbody>
                          )}

                          {caseNotes === undefined && (
                            <TableCaption>Your sessions history</TableCaption>
                          )}
                        </Table>
                      )}
                  </TableContainer>
                </TabPanel>
                <TabPanel>
                  <Box p={2} mb={5}>
                    <HStack spacing={5}>
                      <Box width={'60%'}>
                        <HStack>
                          <InputGroup>
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <InputLeftElement
                                pointerEvents="none"
                                children={<Search2Icon color="gray.600" />}
                              />
                            )}
                            {schoolID === '0' ? (
                              ''
                            ) : (
                              <Input
                                pr="4.5rem"
                                placeholder="Search for a student"
                                onChange={(e) => handleSearch(e)}
                                type="text"
                                value={searchValue}
                                size="xl"
                                borderRadius={5}
                              />
                            )}
                            {activeSearch === 1 ? (
                              <InputRightAddon
                                children={<CloseIcon color="gray.600" />}
                                onClick={() => {
                                  clearSearch();
                                }}
                                cursor={'pointer'}
                              />
                            ) : schoolID === '0' ? (
                              ''
                            ) : (
                              <InputRightAddon children="Search" cursor={'pointer'} />
                            )}
                          </InputGroup>
                        </HStack>
                      </Box>
                      <Box width={'40%'}>
                        <Box textAlign={'right'} width={'100%'}>
                          {schoolID === '0' ? (
                            ''
                          ) : (
                            <HStack>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  showAllColumns === 0
                                    ? setShowAllColumns(1)
                                    : setShowAllColumns(0);
                                }}
                              >
                                {showAllColumns === 0
                                  ? 'Show all student data'
                                  : 'Hide all student data'}
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  handleModalOpen(
                                    schoolType === 'RegularSchool' ? 'RegularSchool' : 'SENSchool'
                                  );
                                }}
                              >
                                Add Student
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  window.open(
                                    'https://sites.google.com/unlockinglanguage.co.uk/school-information/home',
                                    '_blank'
                                  );
                                }}
                              >
                                School information
                              </Button>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  showDischarged === 0
                                    ? setShowDischarged(1)
                                    : setShowDischarged(0);
                                }}
                              >
                                {showDischarged === 0
                                  ? 'Show discharged students'
                                  : 'Hide discharged students'}
                              </Button>
                            </HStack>
                          )}
                        </Box>
                      </Box>
                    </HStack>
                  </Box>
                  <TableContainer className="caseloadContainer">
                    {localStorage.getItem('accountType') !== undefined &&
                      localStorage.getItem('accountType') === 'Therapist Staff' && (
                        <Table variant="striped">
                          <Thead>
                            <Tr>
                              {showAllColumns === 0
                                ? headingsHidden.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i} {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))
                                : headings.map((i: any, k: number) => (
                                    <Th
                                      key={k}
                                      style={
                                        k === 0
                                          ? {
                                              position: 'sticky',
                                              left: '0',
                                              width: 'auto',
                                              zIndex: 999,
                                              cursor: 'pointer',
                                            }
                                          : {}
                                      }
                                      onClick={() => {
                                        sortDataByParameter(
                                          setCaseNotes,
                                          caseNotes,
                                          schoolFilter,
                                          i,
                                          accountType!,
                                          sorter
                                        );
                                        setActiveSort(i);
                                        setSorter.toggle();
                                      }}
                                    >
                                      {i} {sorterIcon(sorter, activeSort, i)}
                                    </Th>
                                  ))}
                            </Tr>
                          </Thead>
                          {showAllColumns === 0 ? (
                            <Tbody>
                              {caseNotes &&
                                caseNotes.map((i: any, k: any) => {
                                  return (
                                    <Tr key={k} style={{ cursor: 'pointer' }}>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {i?.fields['Student/Patient Name']}
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/therapist-session/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/therapist-session/' +
                                                    i.id +
                                                    '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          View Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/edit-student/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/edit-student/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          Edit Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/toms-rating/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/toms-rating/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          TOMS Rating
                                        </Button>
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          ) : (
                            <Tbody>
                              {caseNotes &&
                                caseNotes.map((i: any, k: any) => {
                                  return (
                                    <Tr key={k} style={{ cursor: 'pointer' }}>
                                      <Td
                                        style={
                                          k % 2 == 0
                                            ? {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'gray.100',
                                              }
                                            : {
                                                position: 'sticky',
                                                left: '0',
                                                width: 'auto',
                                                zIndex: 9,
                                                fontWeight: 'bold',
                                                background: 'white',
                                              }
                                        }
                                      >
                                        {i?.fields['Student/Patient Name']}
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/therapist-session/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/therapist-session/' +
                                                    i.id +
                                                    '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          View Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/edit-student/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/edit-student/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          Edit Student
                                        </Button>
                                      </Td>
                                      <Td>
                                        <Button
                                          bg={'brand.300'}
                                          color={'white'}
                                          _hover={{
                                            background: 'brand.200',
                                          }}
                                          onClick={() => {
                                            // tabIndex === 0
                                            schoolType === 'RegularSchool'
                                              ? navigate(
                                                  '/toms-rating/' +
                                                    i.id +
                                                    '?schoolType=RegularSchool'
                                                )
                                              : navigate(
                                                  '/toms-rating/' + i.id + '?schoolType=SENSchool'
                                                );
                                          }}
                                        >
                                          TOMS Rating
                                        </Button>
                                      </Td>
                                      <Td>{i?.fields['DOB']}</Td>
                                      <Td>{i?.fields['Class year']}</Td>
                                      <Td>{i?.fields['Class name']}</Td>
                                      <Td>{i?.fields['Gender']}</Td>
                                      <Td>{i?.fields['Ethnicity']}</Td>
                                      <Td>{i?.fields['Referral Date']}</Td>
                                      <Td>{i?.fields['Language 1']}</Td>
                                      <Td>{i?.fields['Language 2']}</Td>
                                      <Td>{i?.fields['Language 3']}</Td>
                                      <Td>{i?.fields['Other Languages']}</Td>
                                      <Td>{i?.fields['Local Patient Identifier']}</Td>
                                      <Td>{i?.fields['Date of Assessment']}</Td>
                                      <Td>
                                        {i?.fields['Report date'] === undefined
                                          ? 'No Date'
                                          : i?.fields['Report date']}
                                      </Td>
                                      <Td>{i?.fields['Report sent'] === true ? 'Yes' : 'No'}</Td>
                                      <Td>{i?.fields['Report Link']}</Td>
                                      <Td>{i?.fields['Notes']}</Td>
                                      <Td>{i?.fields['Date of review']}</Td>
                                      <Td>
                                        {' '}
                                        {i?.fields['Name of Therapist'] === undefined
                                          ? 'Not allocated'
                                          : i?.fields['Name of Therapist']}
                                      </Td>
                                      <Td>{i?.fields['Primary Medical Diagnosis']}</Td>
                                      <Td>{i?.fields['Additional Medical Diagnosis']}</Td>
                                      <Td>{i?.fields['Other Medical Diagnosis']}</Td>
                                      <Td>
                                        {i?.fields['Primary Communication/ Swallowing Disorder']}
                                      </Td>
                                      <Td>
                                        {i?.fields['Additional Communication/ Swallowing Disorder']}
                                      </Td>
                                      <Td>
                                        {i?.fields['Other Communication/ Swallowing Disorder']}
                                      </Td>
                                      <Td>{i?.fields['EHCP']}</Td>
                                      <Td>{i?.fields['Direct Hours']}</Td>
                                      <Td>{i?.fields['Indirect Hours']}</Td>
                                      <Td>{i?.fields['Hours completed']}</Td>
                                      <Td>{i?.fields['Hours remaining']}</Td>
                                      <Td>{i?.fields['Plan']}</Td>
                                      <Td>{i?.fields['Primary TOMs Scale']}</Td>
                                      <Td>{i?.fields['Secondary TOMs Scale']}</Td>
                                      <Td>
                                        {i?.fields['End of Episode/ Discharge Code/ Description']}
                                      </Td>
                                    </Tr>
                                  );
                                })}
                            </Tbody>
                          )}
                        </Table>
                      )}
                  </TableContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
            {/* {caseNotes === undefined ||
              (caseNotes.length === 0 && <Box textAlign={'center'}>No students added yet!</Box>)} */}
            {schoolFilter === 0 && (
              <Box textAlign={'center'}>
                Select a school from the top right dropdown, in order to view the list of students
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentSchools;
