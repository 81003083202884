import React, { ReactNode, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
import { loadStripe } from '@stripe/stripe-js';
import CheckoutFormInvoices from './CheckoutFormInvoices';
import { useLocation } from 'react-router-dom';

import {
  Box,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Button,
  Link,
  Textarea,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  HStack,
  useToast,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getMultipleApiData from '../utils/getMultipleApiData';
import getInvoiceById from '../utils/getInvoiceById';
import generateXeroAccessToken from '../utils/generateXeroAccessToken';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { UserMeta } from '../interfaces/UserMeta';
import { Base64 } from 'js-base64';
import { json } from 'stream/consumers';
import { link } from 'fs';
import { BackButton } from './BackButton';
import { useNavigate } from 'react-router-dom';

import postDataToEndPoint from './../utils/postDataToEndPoint';
import ninoxTableUpdateRow from './../utils/ninoxTableUpdateRow';
const pkstripe = process.env.REACT_APP_UL_STRIPE_PK;
const stripePromise = loadStripe(String(pkstripe!));
const stripePaymentEP =
  'https://client-portal-serverless-functions.vercel.app/api/stripePaymentIntent';

const PayInvoice = (
  { signedInUserId, signedInUserEmail, userMeta }: SignedInUserProps,
  props: any
) => {
  // console.log('Props', props);
  const queryParameters = new URLSearchParams(window.location.search);
  const invoiceNumber = queryParameters.get('invoice_number');
  const invoiceId = queryParameters.get('id');

  const [rows, setRows] = useState<any>([]);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulInvoicesTable = process.env.REACT_APP_UL_INVOICES_TABLE;
  const userStats = userMeta && userMeta?.invoices;
  const invoicesStrings: any = localStorage?.getItem('invoices');
  const accountNumber: any = localStorage?.getItem('accountNumber');

  const invoicesList = invoicesStrings?.split(',').map(Number);
  const [invoiceEnquiry, setInvoiceEnquiry] = useState('');
  const [invoiceData, setInvoiceData] = useState<any>();

  const navigate = useNavigate();
  const toast = useToast();

  const [cs, setCs] = useState('');
  const invoice = useLocation();

  const invoiceEnquiryMakeEP = process.env.REACT_APP_REQUEST_INVOICE_ENQUIRY_VIA_MAKE_EP;
  const requestBodyInvoiceEnquiry = {
    data: {
      type: ['invoiceEnquiry'],
      invoice_id: [Number(invoiceId)],
      invoice_number: [invoiceNumber],
      client_id: [Number(accountNumber)],
      invoiceEnquiry: [invoiceEnquiry],
      category: [localStorage.getItem('accountType')],
    },
  };
  const sendInvoiceEnquiry = (e: any) => {
    try {
      postDataToEndPoint(invoiceEnquiryMakeEP, requestBodyInvoiceEnquiry);
      toast({
        title: 'Enquiry sent!',
        description:
          'We have received your invoice equiry. Thank you. A member of our team will get back to you soon',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      setInvoiceEnquiry('');
    } catch {
      toast({
        title: 'Invoice enquiry error',
        description:
          'We did not receive your invoice enquiry. There was a system error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    // console.log('from ', requestBodyInvoiceEnquiry);
    setInvoiceData(invoice);
    // // Create PaymentIntent as soon as the page loads
    // fetch('https://client-portal-serverless-functions.vercel.app/api/stripePaymentIntent', {
    //   method: 'POST',
    //   // headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ amount: '3700' }),
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log(data);
    //     setClientSecret(data.clientSecret);
    //   });

    const requestBody = {
      amount: invoice?.state?.invoiceValue * 100,
      description: invoiceNumber,
      customerID: Number(localStorage?.getItem('accountNumber')),
      receipt_email: localStorage?.getItem('accountEmail'),
      name: localStorage.getItem('firstName') + ' ' + localStorage?.getItem('lastName'),
      email: localStorage.getItem('accountEmail'),
      phone: localStorage.getItem('mobileNumber'),
      metadata: {
        customer_first_name: localStorage.getItem('firstName'),
        customer_last_name: localStorage.getItem('lastName'),
        customer_phone_number: localStorage.getItem('phoneNumber'),
        customer_mobile_number: localStorage.getItem('mobileNumber'),
        customer_account_number: Number(localStorage?.getItem('accountNumber')),
        payment_type: 'client_invoice_payment',
      },
      currency: 'gbp',
    };

    console.log(requestBody);
    postDataToEndPoint(stripePaymentEP, requestBody).then((stripeData) => {
      // console.log(stripeData.clientSecret);
      setCs(stripeData.clientSecret);
    });
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: cs,
  };
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box w="100%" height={'auto'} mt={10} p={6} bg="white">
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              <Tab bg={'gray.100'} mr={2}>
                Pay invoice
              </Tab>
              <Tab bg={'gray.100'} mr={2}>
                Invoice Enquiry
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box py={6} bg="white">
                  <HStack spacing={5} mb={10}>
                    <BackButton navigate={navigate} />
                    <Heading>Pay invoice </Heading>
                  </HStack>
                  {cs && (
                    <Box display={'flex'} flexDirection={'row'}>
                      <Box width={'60%'}>
                        {options && (
                          <Elements stripe={stripePromise} options={options}>
                            <CheckoutFormInvoices
                              invoiceID={invoiceNumber && invoiceNumber}
                              // invoiceStatus={invoiceStatus}
                              // setIvoiceStatus={setInvoiceStatus}
                              // invoicePaymentError={invoicePaymentError}
                              // setInvoicePaymentError={setInvoicePaymentError}
                            />
                          </Elements>
                        )}
                      </Box>
                      <Box
                        mt={-1}
                        mb={10}
                        ml={20}
                        width={'30%'}
                        p={5}
                        bg={'gray.100'}
                        borderRadius={10}
                      >
                        <Stat p="5">
                          <StatLabel>Invoice number:</StatLabel>
                          <StatNumber fontSize={30}>{invoiceNumber}</StatNumber>
                        </Stat>
                        <Stat p="5">
                          <StatLabel>Total amount payable</StatLabel>
                          <StatNumber fontSize={30}>
                            £{invoiceData?.state?.invoiceValue.toFixed(2)}
                            {/* {JSON.stringify(userDetails?.fields['Xero Invoice Line Item Amount'])} */}
                          </StatNumber>
                          {/* <StatHelpText>
                            You will be paying £{invoiceData?.state?.invoiceValue.toFixed(2)} today.
                          </StatHelpText> */}
                        </Stat>
                      </Box>
                    </Box>
                  )}
                </Box>
              </TabPanel>
              <TabPanel>
                <Box py={6} bg="white">
                  <HStack spacing={5} mb={10}>
                    <BackButton navigate={navigate} />
                    <Heading>Invoice Enquiry</Heading>
                  </HStack>
                  <Box width="50%">
                    <FormControl width={'100%'}>
                      <FormLabel htmlFor="invoice-equiry" fontWeight={'bold'}>
                        <Text> Invoice Enquiry for {invoiceNumber}</Text>
                      </FormLabel>
                    </FormControl>
                    <Textarea
                      isRequired
                      placeholder="Enter your invoice enquiry"
                      size="3xl"
                      p={'5'}
                      borderRadius={10}
                      background={'white'}
                      my={5}
                      onChange={(e) => setInvoiceEnquiry(e.target.value)}
                      value={invoiceEnquiry}
                    />
                    <Button
                      variant={'defaultButton'}
                      onClick={(e) => sendInvoiceEnquiry(e)}
                      isDisabled={invoiceEnquiry.length === 0 ? true : false}
                    >
                      Send invoice enquiry
                    </Button>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default PayInvoice;
