import { Link, Button, Box, Heading, HStack, VStack } from '@chakra-ui/react';
import { FiPlayCircle } from 'react-icons/fi';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import getAllRowsById from '../utils/getAllRowsById';
import Youtube, { YouTubeProps } from 'react-youtube';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';

interface DashboardVideoListProps {
  accountType?: string;
  accessLevel?: string;
  videosList?: any;
}
interface VideoProps {
  videoUrl: string;
  videoTitle: string;
  videoType: string;
  videoId: string;
}
const opts = {
  height: '80px',
  width: '140px',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};
const testId = 'DHPyTHYcFlY';
const onPlayerReady: YouTubeProps['onReady'] = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};
export const VideoCard = ({ videoUrl, videoTitle, videoType, videoId }: VideoProps) =>
  videoType === 'YouTube' ? (
    <Link
      // target="_blank"
      href={'/video/?res_id=' + videoId + '&fileExt=youtube'}
      // href={videoUrl}
      cursor={'pointer'}
      textDecoration={'none'}
      _hover={{
        color: 'brand.200',
        textDecoration: 'none',
      }}
    >
      <Box
        height={'360px'}
        width={'360px'}
        bg={'gray.100'}
        p={5}
        boxShadow="base"
        rounded="md"
        // mr={5}
        textDecoration={'none'}
        _hover={{
          color: 'brand.200',
          textDecoration: 'none',
        }}
      >
        {/* <Youtube videoId={videoId} opts={opts} onReady={onPlayerReady} /> */}
        <Box
          height={'200px'}
          width={'320px'}
          bgImage={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
          bgSize={'cover'}
          bgPosition={'center'}
          position={'relative'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box>
            <FiPlayCircle color="white" size={70} />
          </Box>
        </Box>

        <Heading size={'md'} pt={5} pb={5} textDecoration={'none'}>
          {videoTitle}
        </Heading>
      </Box>
    </Link>
  ) : (
    <Link target="_blank" href={'/video/?res_id=' + videoId + '&fileExt=mp4'} cursor={'pointer'}>
      <Box
        height={'360px'}
        width={'360px'}
        bg={'gray.100'}
        p={5}
        boxShadow="base"
        rounded="md"
        mr={5}
      >
        {/* <Youtube videoId={videoId} opts={opts} onReady={onPlayerReady} /> */}
        <Box
          height={'200px'}
          width={'320px'}
          bgImage={`https://drive.google.com/thumbnail?id=${videoId}`}
          bgSize={'cover'}
          bgPosition={'center'}
          position={'relative'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box>
            <FiPlayCircle color="white" size={70} />
          </Box>
        </Box>
        <Heading size={'md'} pt={5} pb={5}>
          {videoTitle}
        </Heading>
      </Box>
    </Link>
  );

export const DashboardVideoList = ({ videosList }: DashboardVideoListProps) => {
  // const [videosList, setVideosList] = useState<any>([]);
  const videosEP = process.env.REACT_APP_VIDEOS_LIST;

  // useEffect(() => {}, []);

  // const url = 'https://youtu.be/qX7Y8w6O4iQ';
  // const params = url.split('?')[1].split('&');
  // const id = params.find((param) => param.startsWith('si='))!.split('=')[1];
  // console.log(id);

  return (
    <Box bg={'white'} width={'100%'}>
      <VStack>
        {/* // .filter(
          //   (i: any) => i?.fields['subscription_level'] === localStorage.getItem('accessLevel')
          // ) */}
        {videosList?.length === 0 ? (
          <Box>No videos available at present.</Box>
        ) : (
          videosList?.map((i: any, k: any) => (
            <VideoCard
              key={k}
              videoTitle={i?.fields['video_title']}
              videoUrl={i?.fields['video_url']}
              videoType={i?.fields['video_source']}
              videoId={i?.fields['video_id']}
            />
          ))
        )}
      </VStack>
    </Box>
  );
};
