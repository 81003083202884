import React, { useCallback, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { Page, Text as PDFText, View, Document, PDFDownloadLink } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import { Lorem10Paragraphs } from '../../utils/Lorem10Paragraphs';
import ninoxTableGetRow from './../../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from './../../utils/ninoxTableUpdateRow';
import postDataToEndPoint from './../../utils/postDataToEndPoint';
const StepThree = ({ nextStep }: any) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const ul_cl_id = queryParameters.get('ul_cl');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior, setScrollBehavior] = React.useState('inside');
  const [signContract, setSignContract] = useState(0);
  const [contractStateFromDb, setContractStateFromDb]: any = useState(false);
  const btnRef = React.useRef(null);
  const [contract, setContract]: any = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerIdStripe, setCustomerIdStripe] = useState('');
  const [customerSubIdStripe, setCustomerSubIdStripe] = useState('');
  const [message, setMessage] = useState(''); // State to store the message
  const [emailedContract, setEmailedContract] = useState(0);

  const allClients = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const emailContractToClientViaMakeEP = process.env.REACT_APP_EMAIL_CONTRACT_TO_CLIENT_VIA_MAKE;
  const toast = useToast();
  const requestBody = {
    data: {
      id: [Number(ul_cl_id)],
    },
  };
  // const handleClick = () => {
  //   // Clear any existing message
  //   // setMessage('');
  //   // Set a timeout to display the message after 3 seconds
  //   // setTimeout(() => {
  //   // }, 3000);
  // };
  function sendDataToMake() {
    // handleClick();
    setEmailedContract(1);
    postDataToEndPoint(emailContractToClientViaMakeEP, requestBody).then((data: any) => {
      console.log(data.data);
      setMessage('A copy of the contract has been sent to your email address');
      // setEmailedContract(0);
    });
  }

  // function downloadContractPDF() {}
  function clientSignContract() {
    const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
    const customer = stripe.customers
      .create({
        name: customerName,
        email: customerEmail,
      })
      .then((data: any) => {
        const subscription = stripe.subscriptions
          .create({
            customer: data && data?.id,
            items: [
              {
                price: 'price_1PHNSHJwVKnkDHqHr9waj23c',
              },
            ],
          })
          .then((subdata: any) =>
            ninoxTableUpdateRow(
              allClients!,
              Number(ul_cl_id),
              {
                fields: {
                  'Stripe Customer id': data?.id,
                  'Subscription id': subdata?.id,
                },
              },
              process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
            )
          );
      });

    ninoxTableUpdateRow(
      allClients!,
      Number(ul_cl_id),
      {
        fields: {
          'Agreed to T&Cs - website': true,
        },
      },
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then(() => {
      toast({
        title: 'Contract signed',
        description: 'The client data has been successfully updated.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    });
    onClose();
    setSignContract(1);
  }

  useEffect(() => {
    ninoxTableGetRow(
      allClients!,
      Number(ul_cl_id),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      // setUser(data);
      setContract(data?.fields['HTML Contract']);
      setContractStateFromDb(data?.fields['Agreed to T&Cs - website']);
      // console.log('THE CONTRACT: ', data?.fields['HTML Contract']);
      setCustomerName(data?.fields['First Name'] + ' ' + data?.fields['Last Name']);
      setCustomerEmail(data?.fields['Email 1']);
      data?.fields['Agreed to T&Cs - website'] === true ? setSignContract(1) : setSignContract(0);
    });
  }, []);

  const stylesheet = {
    // clear margins for all <p> tags
    p: {
      margin: 0,
    },
    table: {
      width: '800px!important',
    },
    tbody: {
      width: '800px!important',
    },
    tr: {
      width: '800px!important',
    },
    td: {
      width: '800px!important',
    },
    // add pink background color to elements with class="special"
    // ['.special']: {
    //   backgroundColor: 'pink',
    // },
  };

  // const PDFContractDocument = () => (
  //   <Document>
  //     <Page size="A4" orientation="landscape">
  //       <Html stylesheet={stylesheet}>{contract}</Html>
  //     </Page>
  //   </Document>
  // );

  return (
    <Box w="100%" height={'auto'} p={4}>
      {/* <Heading as="h3" size="lg" mb={'10'}>
        {title}
      </Heading> */}
      <Text mb={'5'}>
        Proceed to review and sign the contract, finalising the terms of engagement for our
        services.
      </Text>
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        <Box>
          <Text as="h5" size="lg" fontWeight={'bold'}>
            Your account has been successfully created
          </Text>

          <>
            <Button
              mt={3}
              ref={btnRef}
              onClick={onOpen}
              // isDisabled={signContract === 1 && true}
              mb={10}
              fontWeight={'medium'}
            >
              View contract
            </Button>

            <Modal
              onClose={onClose}
              finalFocusRef={btnRef}
              isOpen={isOpen}
              scrollBehavior={'inside'}
              size={'full'}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Unlocking Language Contract</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <>
                    {/* <Lorem10Paragraphs /> */}
                    {/* {parse(String(contract))} */}
                    <Box
                      width={'100%'}
                      className={'clinicalClientContract'}
                      dangerouslySetInnerHTML={{ __html: contract }}
                    />
                  </>
                </ModalBody>
                <ModalFooter>
                  <HStack>
                    {signContract === 1 ? (
                      <Button
                        bg={'brand.300'}
                        _hover={{
                          bg: 'brand.200',
                        }}
                        color={'white'}
                        isDisabled={true}
                        // onClick={() => clientSignContract()}
                      >
                        You have already signed your contract
                      </Button>
                    ) : (
                      <Button
                        bg={'brand.300'}
                        _hover={{
                          bg: 'brand.200',
                        }}
                        color={'white'}
                        onClick={() => clientSignContract()}
                      >
                        Sign Contract as {customerName}
                      </Button>
                    )}

                    <Button onClick={onClose}>Close</Button>
                  </HStack>
                </ModalFooter>
              </ModalContent>
            </Modal>
            {signContract === 1 ? (
              <>
                <Text mb={5}>
                  Your signed contract has been received, you can now proceed to the next step{' '}
                </Text>
                <HStack>
                  <Button
                    bg={'brand.300'}
                    _hover={{
                      bg: 'brand.200',
                    }}
                    color={'white'}
                    onClick={() => sendDataToMake()}
                    isDisabled={emailedContract === 1 ? true : false}
                  >
                    Email me a copy of the contract
                  </Button>
                  {message === '' ? (
                    emailedContract === 1 ? (
                      <Spinner />
                    ) : (
                      ''
                    )
                  ) : (
                    <Box fontWeight={'bold'} bg={'green.200'} p={2} borderRadius={5}>
                      {message}
                    </Box>
                  )}

                  {/* <Button
                    bg={'brand.300'}
                    _hover={{
                      bg: 'brand.200',
                    }}
                    color={'white'}
                    onClick={() => downloadContractPDF()}
                  >
                    Download contract PDF
                  </Button> */}
                  {/* <PDFDownloadLink
                    document={<PDFContractDocument />}
                    fileName="unlocking_language_contract.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : ' Download contract PDF'
                    }
                  </PDFDownloadLink> */}
                </HStack>
              </>
            ) : (
              <Text>
                To finalise your account, please view and sign the contract &amp; agree to terms and
                conditions
              </Text>
            )}

            <Box
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-end'}
              mt={5}
            >
              <Button
                variant={'defaultButton'}
                mt={5}
                onClick={() => nextStep(4)}
                isDisabled={signContract === 1 ? false : true}
                _hover={{ backgroundColor: 'brand.200' }}
              >
                Next
              </Button>
            </Box>
          </>
        </Box>
      </Grid>
    </Box>
  );
};
export default StepThree;
