import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  SimpleGrid,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
  Avatar,
  HStack,
  Image,
  StackDivider,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Link,
  Spinner,
} from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';

import React, { ReactNode, useContext, useEffect, useState, useReducer } from 'react';
export default function BookingsContentUpcomingDates({
  txSchoolUpcomingDatesMs,
  txSchoolUpcomingDatesSs,
  combinedUpcomingDates,
  setCombinedUpcomingDates,
  msSchools,
  ssSchools,
}: any) {
  const msSchoolDates = txSchoolUpcomingDatesMs.map((v: any) => ({ ...v, schoolType: 'Ms' }));
  const ssSchoolDates = txSchoolUpcomingDatesSs.map((v: any) => ({ ...v, schoolType: 'Ss' }));
  const combined = [...msSchoolDates, ...ssSchoolDates];

  return (
    <Box overflow={'hidden'}>
      <Box bg={'white'}>
        <Stack
          divider={<StackDivider />}
          spacing="2"
          height={'140px'}
          bg={'white'}
          overflowY={'scroll'}
        >
          {Number(combined.length) > 0 ? (
            combined
              .sort((a: any, b: any) => a?.fields['Start'].localeCompare(b?.fields['Start']))
              .map((i: any, k: any) => {
                let bookingDate = moment(new Date(i?.fields['Start'])).format('DD/MM/YYYY HH:mm');
                let schoolName =
                  i.schoolType === 'Ms'
                    ? msSchools.filter((z: any) => z?.id === i?.fields['Mainstream School'])[0]
                    : ssSchools.filter((z: any) => z?.id === i?.fields['Mainstream School'])[0];
                return (
                  <Box key={k}>
                    <Heading size="xs" textTransform="uppercase">
                      {i?.fields['Brief Notes']}
                      {schoolName?.fields['School Name']}
                    </Heading>
                    <Text pt="2" fontSize="sm" pb={4}>
                      {bookingDate}
                    </Text>
                  </Box>
                );
              })
          ) : (
            <Box>No upcoming bookings</Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
