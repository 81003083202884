import { PhoneIcon, UnlockIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  Heading,
  Link,
  ListItem,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  UnorderedList,
  useBoolean,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { BsBookmarkFill } from 'react-icons/bs';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import PopularResources from './PopularResources';
import { FaBell } from 'react-icons/fa';
import { FaRegBell } from 'react-icons/fa6';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import { useNavigate } from 'react-router-dom';
import { MyContext } from '..';
import { LanguageContext } from '../App';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import { SignedInUserPropsTherapist } from '../interfaces/SignedInUserPropsTherapist';
import getAllRowsById from '../utils/getAllRowsById';
import getUserNinoxDataByEmail from '../utils/getUserNinoxDataByEmail';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import { signOut } from '../utils/signOut';
import { DashboardVideoListInTab } from './DashboardVideoListInTab';
import MessagesContentUsersDashboard from './../components/MessagesContentUsersDashboard';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const adsEP = process.env.REACT_APP_ADS_LIST;
const myFavouritesEP = process.env.REACT_APP_MY_FAVOURITES_EP;

const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const studentsFromSchool = caseNotesMainstream;
const saveResourceEP = process.env.REACT_APP_UL_SAVED_RESOURCES;
const txDatesEP = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS_DATES;
const msSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const ssSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
const EP = process.env.REACT_APP_USER_DATA;
const DashboardSubscriberContent = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserPropsTherapist) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dbLoad, setDbLoad] = useState(0);
  const [accessLevel, setAccessLevel] = useState('none');
  const [adsList, setAdsList] = useState<any>([]);
  const [myFavouritesList, setMyFavouritesList] = useState<any>([]);
  const [allSavedResources, setAllSavedResources] = useState<any>([]);
  const [myPop, setMyPop] = useState<any>(0);
  const [videosList, setVideosList] = useState<any>([]);

  const userAccount = useContext(MyContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolType = queryParameters.get('schoolType');
  const [sorter, setSorter] = useBoolean();
  const navigate = useNavigate();
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const [stripeCpLogin, setStripeCpLogin] = useState<any>('');
  const today = new Date();
  const currentDateTime = moment(today).format('DD/MM/YYYY HH:mm');
  const allAnalyticsEP = process.env.REACT_APP_ALL_ANALYTICS_EP;
  const initialized = useRef(false);

  function loginSubscriberAndSetUser(firebaseUser: any, data: any) {
    setSignedInUserEmail(firebaseUser?.email!);
    if (userAccount.userAccountType === 'subscriber') {
      if (firebaseUser?.email !== undefined) {
        const endPointSubscribers = `${process.env.REACT_APP_SUBSCRIBER_TABLE}?filters={"A":"${firebaseUser?.email}"}`;
        getUserNinoxDataByEmail(endPointSubscribers, bearerToken).then((data: any) => {
          setSignedInUserId(data?._id);
          localStorage.setItem('accountNumber', data?._id);
          ninoxTableGetRow(
            process.env.REACT_APP_SUBSCRIBER_TABLE!,
            data?._id,
            process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
          ).then((data: any) => {
            setUserMeta({
              firstName: data?.fields['First name'],
              lastName: data?.fields['Last name'],
              accessLevel: data?.fields['Access level'],
              accountType: userAccount.userAccountType === 'subscriber' && 'Subscriber',
              signedInUserEmail: firebaseUser?.email,
            });

            localStorage.setItem('firstName', data?.fields['First name']);
            localStorage.setItem('lastName', data?.fields['Last name']);
            localStorage.setItem('contactNumber', data?.fields['Contact number']);
            localStorage.setItem('accountEmail', String(firebaseUser?.email));
            localStorage.setItem('accessLevel', data?.fields['Access level']);
            localStorage.setItem('accountType', data?.fields['Account type']);
            localStorage.setItem('contractSignedStatus', data?.fields['Contract signed status']);
            localStorage.setItem('active', data?.fields['Active']);
            localStorage.setItem('subscriptionStatus', data?.fields['Subscription status']);
            localStorage.setItem('subscriptionId', data?.fields['Subscription id']);
            localStorage.setItem('subscriptionPrice', data?.fields['Subscription price']);
            localStorage.setItem('stripeCustomerId', data?.fields['Stripe Customer id']);
            localStorage.setItem('messagesStart', data?.fields['Messages start']);
            localStorage.setItem('data', JSON.stringify(data));
            setMyPop(1);
            const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
            const session = stripe.billingPortal.sessions
              .create({
                customer: localStorage.getItem('stripeCustomerId'),
                configuration: 'bpc_1PJa3KJwVKnkDHqHOdrFQOgH',
              })
              .then((data: any) => {
                setStripeCpLogin(data.url);
                localStorage.setItem('stripeCpLogin', data.url);
              });

            getAllRowsById(myFavouritesEP).then((data: any) => {
              // const ads = data;
              const myFavourites = data;
              setMyFavouritesList(
                myFavourites.filter(
                  (i: any) =>
                    i.fields['Access Level'] == localStorage.getItem('accessLevel') &&
                    i.fields['Ad Location'] == localStorage.getItem('accountType')
                )
              );
            });

            const queryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
            const adsQuery = `let item := (select Ads)[text(subscription_level) = "${String(
              localStorage.getItem('accessLevel')
            )}" and ad_location = "${String(localStorage.getItem('accountType'))}"];
            let array := [""];
            for i in item do
              let id := number(i.Id);
              let ad_title := i.ad_title;
              let ad_image_url := i.ad_image_url;
              let target_url := i.target_url;
              let ad_location := i.ad_location;
              let subscription_level := i.subscription_level;
              array := array(array, ["{'id': " + id + ",'fields':{'ad_title':'" + ad_title + "','ad_image_url':'" +
                  ad_image_url +
                  "','target_url':'" +
                  target_url +
                  "','ad_location':'" +
                  ad_location +
                  "','subscription_level':'" +
                  subscription_level +
                  "'}}"])
            end;
             join(slice(array, 1, length(array)), ",")`;
            getAllRowsByEncodedQuery(queryEP, adsQuery).then((data: any) => {
              const validJsonString = `[${data.replace(/'/g, '"')}]`;
              const currentUsersAds = JSON.parse(validJsonString);
              setAdsList(currentUsersAds);
              console.log(currentUsersAds);
            });

            const videosQuery = `let item := (select Videos)[text(subscription_level) = "${String(
              localStorage.getItem('accessLevel')
            )}" and text('Ad Location') = "${String(localStorage.getItem('accountType'))}"];
          let array := [""];
          for i in item do
            let id := number(i.Id);
            let video_title := i.video_title;
            let video_url := i.video_url;
            let subscription_level := i.subscription_level;
            let status := i.status;
            let video_source := i.video_source;
            let video_id := i.video_id;
            let fileExt := i.fileExt;
            let videoV1ewCount := i.videoViewCount;
            let Ad_Location := i.'Ad Location';
            let subscription_level := i.subscription_level;
            array := array(array, ["{'id': " + id + ",'fields':{'video_title':'" + video_title + "','video_url':'" +
                video_url +
                "','subscription_level':'" +
                subscription_level +
                "','video_source':'" +
                video_source +
                "','video_id':'" +
                video_id +
                "','Ad_Location':'" +
                Ad_Location +
                "'}}"])
          end;
          join(slice(array, 1, length(array)), ",")
          
          `;
            getAllRowsByEncodedQuery(queryEP, videosQuery).then((data: any) => {
              const validJsonString = `[${data.replace(/'/g, '"')}]`;
              const currentUsersAds = JSON.parse(validJsonString);
              setVideosList(currentUsersAds);
            });
          });
        });
      }
    }
  }
  function checkOutAndLogout() {
    signOut();
    navigate('/login-error');
  }
  // `https://drive.google.com/uc?export=view&id=${adsList[3]?.fields?.ad_image_url}`;

  const AdCarousel = () => {
    const tooglesGroupId = 'Toggles';
    const valuesGroupId = 'Values';
    const mainGroupId = 'Main';

    const getConfigurableProps = () => ({
      showArrows: false,
      showStatus: false,
      showIndicators: true,
      infiniteLoop: true,
      showThumbs: false,
      useKeyboardArrows: true,
      autoPlay: true,
      stopOnHover: true,
      swipeable: true,
      dynamicHeight: false,
      emulateTouch: true,
      autoFocus: false,
      // thumbWidth: 100,
      selectedItem: 1,
      interval: 3000,
      transitionTime: 500,
      swipeScrollTolerance: 5,
      ariaLabel: 'Unlocking Language Advert',
    });

    return (
      <Carousel {...getConfigurableProps()}>
        {adsList.length <= 0
          ? 'No ads to show'
          : adsList.map((i: any, k: any) => (
              <Link target="_blank" href={i.fields.target_url}>
                <Box key={k} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Box
                    backgroundImage={`https://drive.google.com/thumbnail?id=${i.fields?.ad_image_url}&sz=w400`}
                    backgroundSize={'contain'}
                    backgroundPosition={'center'}
                    backgroundRepeat={'no-repeat'}
                    width={'400px'}
                    height={'600px'}
                  />
                </Box>
              </Link>
            ))}
      </Carousel>
    );
  };

  // const AdCarouselFavourites = () => {
  //   const slickSettings = {
  //     dots: true,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 4,
  //     slidesToScroll: 1,
  //   };

  //   return (
  //     <Box pb={10}>
  //       <Slider {...slickSettings}>
  //         {myFavouritesList.length <= 0
  //           ? 'Favourites coming soon'
  //           : myFavouritesList.map((i: any, k: any) => (
  //               <Link target="_blank" href={i.fields['Target URL']}>
  //                 <Box
  //                   key={k}
  //                   display={'flex'}
  //                   flexDirection={'column'}
  //                   alignItems={'center'}
  //                   bg={'gray.100'}
  //                   mr={5}
  //                 >
  //                   <Text py={'2'} fontWeight={'bold'}>
  //                     {i.fields['Name']}
  //                   </Text>
  //                   <Box
  //                     backgroundImage={`https://drive.google.com/thumbnail?id=${i.fields['Picture']}&sz=w200`}
  //                     backgroundSize={'contain'}
  //                     backgroundPosition={'center'}
  //                     backgroundRepeat={'no-repeat'}
  //                     width={'200px'}
  //                     height={'150px'}
  //                   />
  //                   <Text py={'2'} fontStyle={'italic'}>
  //                     {i.fields['Caption']}
  //                   </Text>
  //                 </Box>
  //               </Link>
  //             ))}
  //       </Slider>
  //     </Box>
  //   );
  // };

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      const endPointClientsCentral = `${process.env.REACT_APP_SUBSCRIBER_TABLE}?filters={"A":"${firebaseUser?.email}"}`;

      getUserNinoxDataByEmail(endPointClientsCentral, bearerToken)
        .then((data: any) =>
          data === undefined ? checkOutAndLogout() : loginSubscriberAndSetUser(firebaseUser, data)
        )
        .catch((error) => console.log(error));

      getAllRowsById(saveResourceEP).then((data: any) => {
        setAllSavedResources(
          data?.filter((i: any) => i?.fields['userEmail'] === localStorage.getItem('accountEmail'))
        );
      });
    });
    return unsubscribe;
  }, []);

  return (
    <Box height={'100vh'} overflowY={'scroll'}>
      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        p="10"
        maxW="750xl"
        columns={{ base: 2, md: 2 }}
        mb={'-10'}
        mt={'-5'}
      >
        <Heading textAlign={'left'} as="h4" size="xl">
          Unlocking Language
        </Heading>
        <HStack justifyContent={'right'}>
          <Box textAlign={'left'} ml={3} mr={5}>
            Welcome back
            <Heading textAlign={'right'} as="h4" size="md" mt={'0'}>
              {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}{' '}
            </Heading>
          </Box>
          <HStack>
            {localStorage.getItem('notificationsCount') === undefined ||
            Number(localStorage.getItem('notificationsCount')) === 0 ? (
              <Link href={'messages'}>
                <HStack>
                  <FaRegBell size={30} />
                  <Text fontSize={20}>(0)</Text>
                </HStack>
              </Link>
            ) : (
              <Link href={'messages'}>
                <HStack>
                  <FaBell size={30} />
                  <Text fontSize={20}>({localStorage.getItem('notificationsCount')})</Text>
                </HStack>
              </Link>
            )}
          </HStack>
        </HStack>
      </SimpleGrid>
      <Flex ml={{ base: 0 }} mt={-10}>
        <SimpleGrid
          ml={{ base: 0, md: 20 }}
          p="10"
          // maxW="50xl"
          columns={[1, 2, 4, 4]}
          spacing={{ base: 5, lg: 8 }}
          width={'100%'}
        >
          <Box
            background={'white'}
            borderRadius={10}
            padding={5}
            alignContent={'center'}
            textAlign={'center'}
            height={310}
          >
            <Text fontWeight={'bold'} mb={5}>
              Access level
            </Text>

            <CircularProgress
              value={100}
              size="140px"
              // color="pink.700"
              color="brand.100"
            >
              <CircularProgressLabel fontSize={26} fontWeight={'bold'}>
                {localStorage.getItem('accessLevel')}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
          <Link
            href={'/resources/14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5'}
            _hover={{ textDecoration: 'none' }}
          >
            <Box
              background={'white'}
              borderRadius={10}
              padding={5}
              alignContent={'center'}
              textAlign={'center'}
              height={310}
            >
              <Text fontWeight={'bold'} mb={5} textAlign={'center'} mt={-5}>
                Resources available
              </Text>
              <CircularProgress
                value={100}
                size="140px"
                color="brand.200"
                // color="pink.500"
              >
                <CircularProgressLabel fontSize={50} fontWeight={'bold'}>
                  {1}
                </CircularProgressLabel>
              </CircularProgress>
            </Box>{' '}
          </Link>
          <Box
            background={'white'}
            borderRadius={10}
            padding={5}
            textAlign={'left'}
            display={'flex'}
            flexDirection={'column'}
            position={'relative'}
            height={310}
          >
            <Text fontWeight={'bold'} mb={5} textAlign={'left'}>
              <UnlockIcon mr={5} /> Upgrade subscription
            </Text>
            <Box>
              <Text mb={5} textAlign={'left'}>
                Upgrade your subscription in order to access more resources.
              </Text>
              <UnorderedList mb={5} textAlign={'left'}>
                <ListItem>
                  <Text fontWeight={'bold'}>Free: £0.00 per month</Text>
                </ListItem>
                <ListItem>
                  <Text fontWeight={'bold'}>Bronze: £2.99 per month</Text>
                </ListItem>
                <ListItem>
                  <Text fontWeight={'bold'}>Silver: £3.99 per month</Text>
                </ListItem>
                <ListItem>
                  <Text fontWeight={'bold'}>Gold: £4.99 per month</Text>
                </ListItem>
              </UnorderedList>
            </Box>
            <Link href={stripeCpLogin} target="_blank" position={'absolute'} bottom={5}>
              <Button variant={'defaultButton'}> Manage Subscription</Button>
            </Link>
          </Box>
          <Box
            background={'white'}
            borderRadius={10}
            padding={5}
            alignContent={'center'}
            alignItems={'left'}
            textAlign={'left'}
            display={'flex'}
            flexDirection={'column'}
            position={'relative'}
            height={310}
          >
            <Text
              fontWeight={'bold'}
              mb={5}
              textAlign={'left'}
              display={'flex'}
              flexDirection={'row'}
            >
              <PhoneIcon />
              <Text ml={5} mt={-1}>
                Book a discovery call
              </Text>
            </Text>
            <Text mb={5} textAlign={'left'}>
              Arrange a discovery call to understand more about our services and how we can help
              you.
            </Text>
            <Box pt={100}>
              <Link href={'book-discovery-call'} position={'absolute'} bottom={5}>
                <Button variant={'defaultButton'}> Book Discovery Call</Button>
              </Link>
            </Box>

            {/* <Button
              variant={'defaultButton'}
              onClick={() => {
                const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);

                stripe.billingPortal.configurations.create({
                  business_profile: {
                    headline: 'Unlocking Language',
                    privacy_policy_url: 'https://example.com/privacy',
                    terms_of_service_url: 'https://example.com/tod',
                  },

                  features: {
                    customer_update: {
                      allowed_updates: ['email', 'name', 'phone'],
                      enabled: true,
                    },
                    invoice_history: {
                      enabled: true,
                    },
                    payment_method_update: {
                      enabled: true,
                    },
                    subscription_cancel: {
                      cancellation_reason: {
                        enabled: true,
                        options: [
                          'too_expensive',
                          'missing_features',
                          'switched_service',
                          'unused',
                          'other',
                        ],
                      },
                      enabled: false,
                      mode: 'immediately',
                      proration_behavior: 'none',
                    },
                    subscription_pause: {
                      enabled: false,
                    },
                    subscription_update: {
                      default_allowed_updates: ['price'],
                      enabled: true,
                      products: [
                        {
                          prices: ['price_1PJEjCJwVKnkDHqHOcUi7udi'],
                          product: 'prod_Q9XoKmKnsHKbZz',
                        },
                        {
                          prices: ['price_1NfNIxJwVKnkDHqHp2jmMFXG'],
                          product: 'prod_OSHstJKur8lSVx',
                        },
                        {
                          prices: ['price_1PIpiNJwVKnkDHqHLDbF1J9Y'],
                          product: 'prod_Q7chYgNdLgknwE',
                        },
                        {
                          prices: ['price_1PIpiSJwVKnkDHqHRhKqQLhj'],
                          product: 'prod_Q7ch5E6MuIYN4O',
                        },
                      ],
                      proration_behavior: 'always_invoice',
                    },
                  },
                });

                // Bronze
                // price_1PHNSHJwVKnkDHqHr9waj23c
                // prod_Q7chlJWu5dj1gG

                // Silver
                // price_1PHNSJJwVKnkDHqH1zl9Ngrs
                // prod_Q7chihYUq3Iwe1

                // Gold
                // price_1PHNSKJwVKnkDHqHQj9bBS1z
                // prod_Q7chqj85Urgjg1
              }}
            >
              {' '}
              Create configuration
            </Button> */}
            {/* <Button
              variant={'defaultButton'}
              onClick={() => {
                const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
                const configurations = stripe.billingPortal.configurations.list({
                  limit: 10,
                });
              }}
            >
              {' '}
              List customer portals configured
            </Button> */}
            {/* <Button
              variant={'defaultButton'}
              onClick={() => {
                const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
                const session = stripe.billingPortal.sessions.create({
                  customer: 'cus_Q7H7h6oPE8QobQ',
                  configuration: 'bpc_1PJa3KJwVKnkDHqHOdrFQOgH',
                });
              }}
            >
              {' '}
              Create Customer Portal Link
            </Button> */}
            {/* <Link href={stripeCpLogin}>
              <Button variant={'defaultButton'} mt={5}>
                My Subscription
              </Button>
            </Link> */}
          </Box>
        </SimpleGrid>
        {/* <Box width={'25%'} justifyContent={'right'} pr={10}>
          <Box
            width={'100%'}
            height={'220px'}
            bg={'white'}
            borderRadius={10}
            mt={10}
            pt={5}
            pl={5}
            pr={5}
            pb={5}
            minH={'300px'}
            overflow={'hidden'}
            textAlign={'left'}
          >
            <Text
              fontWeight={'bold'}
              mb={5}
              textAlign={'left'}
              display={'flex'}
              flexDirection={'row'}
            >
              <BsBookmarkFill fill="black" />
              <Text ml={5} mt={-1}>
                Resources
              </Text>
            </Text>
            <Box
              width={'100%'}
              height={'280px'}
              bg={'white'}
              overflow={'scroll'}
              textAlign={'left'}
              pb={20}
            >
              <Box>
                <Text mb={5} textAlign={'left'}>
                  Quick links to popular resources
                </Text>
                <UnorderedList mb={5} textAlign={'left'}>
                  <ListItem>Resource 1</ListItem>
                  <ListItem>Resource 2</ListItem>
                  <ListItem>Resource 3</ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Flex>

      <PopularResources myPop={myPop} setMyPop={setMyPop} />

      <Flex ml={{ base: 0, md: 20 }} mt={-10} p="10">
        <Box
          bg={'white'}
          borderRadius={10}
          p={5}
          width={'33.3%'}
          height={'700'}
          ml={0}
          overflowY={'hidden'}
        >
          <Box
            bg={'white'}
            minH={'300px'}
            height={'700'}
            pb={10}
            pr={5}
            pl={5}
            borderRadius={10}
            mt={0}
          >
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab fontSize={18}>Latest videos</Tab>
                <Tab fontSize={18}>Saved resources ({allSavedResources.length})</Tab>
              </TabList>
              <TabPanels pt={5}>
                <TabPanel>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                    height={540}
                    overflowY={'hidden'}
                  >
                    {/* <Heading as={'h3'} size={'md'} mb={10} mt={0}>
                      Latest videos
                    </Heading> */}
                    <Box width={'100%'} overflowY={'scroll'} height={'580px'}>
                      <DashboardVideoListInTab
                        accountType={localStorage.getItem('accountType')!}
                        accessLevel={localStorage.getItem('accessLevel')!}
                        videosList={videosList}
                      />
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'640px'}>
                    <Heading as={'h3'} size={'md'} mb={10} mt={-5}>
                      Saved resources
                    </Heading>
                    <Box bg={'white'} pl={0} height={'440px'} width={'100%'}>
                      <Stack
                        divider={<StackDivider />}
                        spacing="4"
                        height={'420px'}
                        overflowY={'scroll'}
                        overflowX={'hidden'}
                        wrap={'wrap'}
                      >
                        {allSavedResources.map((i: any, k: any) => (
                          <Link
                            href={`/resource/?res_id=${i?.fields['resourceId']}&fileExt=${i?.fields['fileExt']}`}
                            key={k}
                            textDecoration={'none'}
                            cursor={'pointer'}
                          >
                            <Box cursor={'pointer'}>
                              <Heading size="xs" textTransform="uppercase">
                                <HStack>
                                  <BsBookmarkFill fill="black" />
                                  {/* <Text width={'100%'}>{i?.fields['fileTitle']?.slice(0, 20)}&nbsp;...</Text> */}
                                  <Text width={'100%'}>{i?.fields['fileTitle']}&nbsp;...</Text>
                                </HStack>
                              </Heading>
                              <Text pt="2" fontSize="sm" pl={6}>
                                Date saved: {moment(new Date(i.createdAt)).format('DD/MM/YYYY')}
                              </Text>
                            </Box>
                          </Link>
                        ))}
                        {/* {JSON.stringify(allSavedResources)} */}
                        {allSavedResources?.length === 0 && <Text>No saved resources</Text>}
                      </Stack>
                    </Box>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>

        <Box
          // pl={5}
          width={'33.3%'}
          // bg="gray.100"
          // mt={-20}
          // display={'flex'}
          // flexDirection={'column'}
          // alignItems={'center'}
          // justifyContent={'center'}
          // // height={'600px'}
          bg="white"
          borderRadius={10}
          p={5}
          ml={5}
        >
          <Heading as={'h3'} size={'md'} mb={10}>
            More information
          </Heading>
          <AdCarousel />
        </Box>

        <Box width={'33.3%'} bg="white" borderRadius={10} p={5} ml={5}>
          <Heading as={'h3'} size={'md'} mb={10} mt={0}>
            Messages
          </Heading>
          <Box bg={'white'}>
            <Stack
              divider={<StackDivider />}
              spacing="4"
              height={'590px'}
              overflowY={'scroll'}
              width={'100%'}
            >
              <MessagesContentUsersDashboard
                signedInUserId={signedInUserId}
                signedInUserEmail={signedInUserEmail}
                userMeta={userMeta}
                navWidth={navWidth}
                setNavWidth={setNavWidth}
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            </Stack>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardSubscriberContent;
