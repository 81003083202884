import {
  Box,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Link,
  Text,
  Button,
} from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import { FiServer } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import React, { useEffect, useState, ReactNode, useContext } from 'react';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { NONAME } from 'dns';
import { SignedInUserProps } from './../interfaces/SignedInUserProps';
import { UserMeta } from './../interfaces/UserMeta';
import { SignedInUserPropsTherapist } from '../interfaces/SignedInUserPropsTherapist';
import { MyContext } from '..';
import { auth } from './../../src/auth/fireBaseSetup';
import getUserNinoxDataByEmail from './../../src/utils/getUserNinoxDataByEmail';
import getAllRowsById from '../utils/getAllRowsById';
import { useNavigate } from 'react-router-dom';
import { signOut } from './../utils/signOut';
import AdminContent from './AdminContent';

const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const ulDb = process.env.REACT_APP_UL_DB;
interface StatsCardProps {
  title: string;
  stat?: string | number | undefined;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: userSessionDataProps | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

// const optionsGenders: ApexOptions = {
interface ApexChartOptions {
  chart: {
    type: string;
    offsetY: number;
    sparkline: {
      enabled: boolean;
    };
  };
  plotOptions: {
    radialBar: {
      startAngle: number;
      endAngle: number;
      track: {
        background: string;
        strokeWidth: string;
        margin: number;
        dropShadow: {
          enabled: boolean;
          top: number;
          left: number;
          color: string;
          opacity: number;
          blur: number;
        };
      };
      dataLabels: {
        name: {
          show: boolean;
        };
        value: {
          offsetY: number;
          fontSize: string;
        };
      };
    };
  };
  grid: {
    padding: {
      top: number;
    };
  };
  fill: {
    type: string;
    gradient: {
      shade: string;
      shadeIntensity: number;
      inverseColors: boolean;
      opacityFrom: number;
      opacityTo: number;
      stops: number[];
    };
  };
  labels: string[];
}

function StatsCard(props: StatsCardProps) {
  const { title, stat, icon, color } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}
      bg={color}
      w="100%"
      p={4}
      color="brand.25"
      borderRadius="md"
      _hover={{
        textDecoration: 'none',
      }}
    >
      <Flex justifyContent={'space-between'}>
        <Box w="100%" p={4} color="brand.25" borderRadius="md" pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} fontSize={'2xl'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'4xl'} fontWeight={'extrabold'} color="white" as="b">
            {stat}
          </StatNumber>
        </Box>
        <Box my={'auto'} color={'white'} alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

const DashboardAdminContent = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserPropsTherapist) => {
  // const [userMeta, setUserMeta] = useState<UserMeta | null>();
  const allProfilesTable = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const navigate = useNavigate();
  const userAccount = useContext(MyContext);
  const adminUsersEP = process.env.REACT_APP_UL_ADMIN_USERS;
  const [approveOrReject, setApproveOrReject] = useState(0);

  function checkOutAndLogout() {
    signOut();
    navigate('/login-error');
  }
  function loginAdminAndSetUser(firebaseUser: any, data: any) {
    setSignedInUserEmail(firebaseUser?.email!);
    if (userAccount.userAccountType === 'admin') {
      if (firebaseUser?.email !== undefined) {
        const adminUser = data.filter((i: any) => i.fields['Email'] === firebaseUser?.email)[0];
        localStorage.setItem('firstName', adminUser?.fields['First Name']);
        localStorage.setItem('lastName', adminUser?.fields['Last Name']);
        localStorage.setItem('accountNumber', adminUser?.id);
        localStorage.setItem('accessLevel', 'Super Admin');
        localStorage.setItem('accountType', 'Admin');
        localStorage.setItem('accountEmail', String(firebaseUser?.email));

        localStorage.setItem(
          'txFullName',
          adminUser?.fields['First Name'] + ' ' + adminUser?.fields['Last Name']
        );
        localStorage.setItem('txEmail', String(firebaseUser?.email));
        localStorage.setItem('txJobTitle', 'SuperAdmin');
      }
    }
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      getAllRowsById(adminUsersEP).then((data) => {
        data && data?.some((i: any) => i.fields['Email'] === firebaseUser?.email)
          ? loginAdminAndSetUser(firebaseUser, data)
          : checkOutAndLogout();
      });
    });
    return unsubscribe;
  }, []);

  return (
    <>
      {/* <Box p="6">
        <Heading textAlign={'right'} as="h4" size="md">
          ADMIN
        </Heading>
        <Heading textAlign={'right'} as="h4" size="md">
          Welcome back
       
        </Heading>
      </Box> */}
      {/* ,
          {' ' + localStorage.getItem('firstName')} {localStorage.getItem('lastName')} */}
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        // spacing={{ base: 5, lg: 8 }}
        mb={'-10'}
        pt={'0'}
      >
        <Box>
          <AdminContent
            signedInUserId={signedInUserId}
            signedInUserEmail={signedInUserEmail}
            userMeta={userMeta}
            navWidth={navWidth}
            setNavWidth={setNavWidth}
            signedInUserType={signedInUserType}
            setSignedInUserType={setSignedInUserType}
            approveOrReject={approveOrReject}
            setApproveOrReject={setApproveOrReject}
          />
        </Box>
        {/* 
        <Link
          _hover={{
            textDecoration: 'none',
          }}
          href={'https://unlockinglanguage.co.uk/'}
          target={'_blank'}
        >
          <Box bg={'#fff'} h={'auto'} borderRadius={'6px'} p={'10'}>
            <Heading>Admin panel</Heading>
            <Text pt={'5'}>🎉 Hooray, we just released a new version. Check out what's new.</Text>
          </Box>
        </Link> */}
      </SimpleGrid>
      {/* <SimpleGrid
        ml={{ base: 0, md: 20 }}
        p="10"
        maxW="50xl"
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 5, lg: 8 }}
      >
        <StatsCard
          title={'Account type'}
          stat={String(localStorage.getItem('accountType'))}
          icon={''}
          color={'brand.400'}
        />
        <StatsCard
          title={'Account details'}
          stat={String(localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'))}
          icon={''}
          color={'brand.300'}
        />
        <StatsCard
          title={'Account type'}
          stat={String(localStorage.getItem('accountType'))}
          icon={''}
          color={'brand.200'}
        />

        {/* <StatsCard title={'Upcoming session'} stat={'01/09/2023'} icon={''} color={'brand.400'} /> */}
      {/*
        <Link
          href={'/'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Buy now pay later'}
            stat={'Get started'}
            icon={''}
            color={'brand.300'}
          />
        </Link>

        <Link
          href={'/invoices'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Outstanding Invoices(2)'}
            stat={'Pay now'}
            icon={''}
            color={'brand.200'}
          />
        </Link>
        <Link
          href={'/sessions'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Sessions'}
            stat={userMeta?.stats?.length}
            icon={''}
            color={'brand.400'}
          />
        </Link>
        <Link
          href={'/messages'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Messages'}
            stat={userMeta?.messages?.length}
            icon={''}
            color={'brand.300'}
          />
        </Link>

        <Link
          href={'/invoices'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Invoices'}
            stat={userMeta?.invoices?.length}
            icon={''}
            color={'brand.200'}
          />
        </Link> 
      </SimpleGrid> */}
    </>
  );
};

export default DashboardAdminContent;
