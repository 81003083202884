import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getAllRowsById from '../utils/getAllRowsById';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import orderItems from '../utils/orderItems';
import { BackButton } from './BackButton';
import { Note } from './../interfaces/Note';
import { BiAlarm } from 'react-icons/bi';
import moment from 'moment';
import postDataToEndPoint from '../utils/postDataToEndPoint';
import momentBusiness from 'moment-business-days';
import ninoxTableUpdateRow from './../utils/ninoxTableUpdateRow';
import ninoxTableGetRow from './../utils/ninoxTableGetRow';
import RatingStars from './RatingStars';

const today = new Date();

const BookingsContentSinglePrivateClient = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  const { userDetails, updateUser } = useContext(userDetailsContext);
  const [user, setUser]: any = useState();
  const [tabIndex, setTabIndex] = useState(0);
  updateUser(signedInUserId);
  const sessionUpdateReasons = ['Reschedule', 'Cancel'];

  const queryParameters = new URLSearchParams(window.location.search);
  const sessionStart = queryParameters.get('session_start');
  const theSession = moment(sessionStart).format('YYYY-MM-DD HH:mm');
  const activeTab = queryParameters.get('activeTab');
  interface reasonMessageTypes {
    session_id: number | undefined;
    date_of_message: any;
    reason: string;
    reason_message: string;
  }
  const updateSessionDefault = {
    session_id: sessionId,
    date_of_message: today,
    reason: sessionUpdateReasons[0],
    reason_message: '',
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [notes, setNotes] = useState<Note[] | []>([]);
  const [reasonMessage, setReasonMessage] = useState<reasonMessageTypes>(updateSessionDefault);
  const { onOpen, onToggle } = useDisclosure();
  const [edit, setEdit] = useState(1);
  const [rows, setRows] = useState<any>([]);
  const [ratingComment, setRatingComment] = useState<string>('');
  const [ratings, setRatings] = useState<any>([]);
  const [ratingScore, setRatingScore] = useState<number>(0);

  const navigate = useNavigate();
  const userAccountNumber = localStorage.getItem('accountNumber');
  const clientsEP = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const clientsSessionsEP = process.env.REACT_APP_USER_DATA;

  const clientToUpdateDefault = {
    rating: '4',
    comment: 'This comment',
  };
  const [form, setForm] = useState<any>(clientToUpdateDefault);

  const clientToUpdate = {
    'Session rating': ratingScore,
    'Session comments': ratingComment,
  };
  const addRating = (e: any) => {
    try {
      ninoxTableUpdateRow(
        clientsSessionsEP!,
        Number(sessionId),
        { fields: clientToUpdate },
        String(bearerToken)
      )
        .then(() => {
          toast({
            title: 'Rating added.',
            description: 'The rating has been successfully added.',
            status: 'success',
            duration: 6000,
            isClosable: true,
          });
        })
        .then(() => {
          navigate('/private-client-sessions');
        });
    } catch {
      toast({
        title: 'Update error',
        description:
          'The client was note updated. There was an error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const defaultForm = {
    firstName: '',
    lastName: '',
    classYear: 'none',
    localPatientIdentifier: '',
  };
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const userStats = userMeta && userMeta?.stats;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const toast = useToast();
  console.log('sessionId:', sessionId);
  const userNotes = notes?.filter((i: any) => i?.fields?.session_id == sessionId);

  function getUserData() {
    ninoxTableGetRow(
      clientsSessionsEP!,
      Number(sessionId),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      console.log(data);
      setUser(data);
      setRatingScore(data?.fields['Session rating']);
      // setForm({
      //   ...form,
      //   sessionRating: data?.fields['Session rating'],
      //   comment: data?.fields['Session comments'],
      // });
    });
  }

  useEffect(() => {
    getUserData();
    // setReasonMessage(updateSessionDefault);
    // getAllRowsById(process.env.REACT_APP_UL_NOTES_TABLE).then((data) => {
    //   console.log('Notes: ', data);
    //   setNotes(data);
    // });
  }, []);

  // session_id: sessionId,
  // date_of_message: today,
  // reason: sessionUpdateReasons[0],
  // reason_note: '',
  const handleReasonType = (e: any) => {
    setReasonMessage({ ...reasonMessage, reason: e.target.value });
  };
  const handleReasonMessage = (e: any) => {
    setReasonMessage({ ...reasonMessage, reason_message: e.target.value });
  };

  const requestRescheduleOrCancelViaMakeEP =
    process.env.REACT_APP_CANCEL_OR_RESCHEDULE_SESSION_VIA_MAKE_EP;
  const requestBody = {
    data: {
      type: [reasonMessage.reason],
      message: [reasonMessage.reason_message],
      session_id: [sessionId],
    },
  };

  const sendRequest = (e: any) => {
    try {
      toast({
        title: reasonMessage.reason + ' request sent to Unlocking language',
        description: 'The team is dealing with your request.',
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
      postDataToEndPoint(requestRescheduleOrCancelViaMakeEP, requestBody);
      setReasonMessage(updateSessionDefault);
    } catch {
      toast({
        title: 'Message not sent',
        description: 'Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  let checkBefore =
    moment(sessionStart).isBefore(moment(today).format('YYYY-MM-DD HH:mm')) === true ? '-' : '';
  let businessDaysDiff = momentBusiness(
    moment(sessionStart).format('YYYY-MM-DD HH:mm')
  ).businessDiff(momentBusiness(moment(today).format('YYYY-MM-DD HH:mm')));
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <HStack spacing={5} mb={10}>
            <BackButton navigate={navigate} />
            <Heading>Update session</Heading>
          </HStack>
          <Tabs
            variant="soft-rounded"
            colorScheme="green"
            index={activeTab === 'rate' ? 0 : activeTab === 'manage' ? 1 : tabIndex}
          >
            <TabList>
              {activeTab === 'rate' && (
                <Tab
                  onClick={() => {
                    navigate(
                      '/private-client-session/' +
                        sessionId +
                        '?session_start=' +
                        sessionStart +
                        '&activeTab=rate'
                    );
                  }}
                >
                  Rate session
                </Tab>
              )}
              {activeTab === 'manage' && (
                <Tab
                  onClick={() => {
                    navigate(
                      '/private-client-session/' +
                        sessionId +
                        '?session_start=' +
                        sessionStart +
                        '&activeTab=manage'
                    );
                  }}
                >
                  Manage session
                </Tab>
              )}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box w={'100%'} mt={5}>
                  <Heading size={'md'} fontWeight={'bold'}>
                    Rate your session
                  </Heading>

                  {edit === 0 ? (
                    <Box bg={'gray.100'} p={10} borderRadius={10} mt={10} display={'none'}>
                      <RatingStars
                        size={48}
                        icon="StarIcon"
                        scale={5}
                        fillColor="gold"
                        strokeColor="grey"
                        ratingScore={ratingScore}
                        setRatingScore={setRatingScore}
                        isDisabled={true}
                      />
                      <Box
                        width="100%"
                        textAlign={'center'}
                        bg={'gray.100'}
                        p={10}
                        borderRadius={10}
                      >
                        {ratingComment === '' ? 'No rating comment provided' : ratingComment}
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <RatingStars
                        size={48}
                        icon="StarIcon"
                        scale={5}
                        fillColor="gold"
                        strokeColor="grey"
                        ratingScore={ratingScore}
                        setRatingScore={setRatingScore}
                      />
                      <Textarea
                        // value={value}
                        // onChange={handleInputChange}
                        isRequired
                        placeholder="Enter your note details here"
                        size="3xl"
                        p={'5'}
                        borderRadius={10}
                        background={'white'}
                        mt={5}
                        onChange={(e) => setRatingComment(e.target.value)}
                        value={ratingComment}
                      />
                    </Box>
                  )}

                  <Box mt={2} textAlign={'right'} mb={5}>
                    {edit === 0 ? (
                      <Button
                        variant={'defaultButton'}
                        onClick={(e) => {
                          setEdit(1);
                        }}
                      >
                        Add Rating
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant={'defaultButton'}
                          onClick={(e) => {
                            setEdit(0);
                          }}
                          mr={2}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant={'defaultButton'}
                          onClick={(e) => {
                            addRating(e);
                            setEdit(1);
                          }}
                        >
                          Add Rating
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                {Number(checkBefore + businessDaysDiff) > 2 ? (
                  <Box w={'50%'} p={'5'}>
                    <FormControl mb={'5'}>
                      <FormLabel>Session ID</FormLabel>
                      <Input value={sessionId} isDisabled />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Session Start</FormLabel>
                      <Input value={theSession} isDisabled />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Reschedule or Cancel: </FormLabel>
                      <Select
                        isRequired
                        onChange={(e) => handleReasonType(e)}
                        value={reasonMessage.reason}
                      >
                        <option value="Reschedule">Reschedule</option>
                        <option value="Cancel">Cancel</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Please provide a reason for this request: </FormLabel>
                      <Textarea
                        isRequired
                        placeholder="Enter your note details here"
                        size="3xl"
                        p={'5'}
                        onChange={(e) => handleReasonMessage(e)}
                        value={reasonMessage.reason_message}
                      />
                    </FormControl>
                    <FormControl>
                      <Button
                        onClick={(e) => sendRequest(e)}
                        bg={'brand.300'}
                        _hover={{
                          bg: 'brand.200',
                        }}
                        color={'white'}
                        isDisabled={reasonMessage.reason_message.length === 0 ? true : false}
                        mt={'5'}
                      >
                        Send {reasonMessage.reason} request
                      </Button>
                    </FormControl>
                  </Box>
                ) : (
                  'Unable to change session.'
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentSinglePrivateClient;
