import {
  Box,
  Button,
  Heading,
  IconButton,
  Image,
  Link,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useToast,
  SimpleGrid,
  HStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { BiCopy, BiCheck, BiCross, BiSearch } from 'react-icons/bi';
import { BsFillEnvelopeAtFill } from 'react-icons/bs';
import { useFetcher, useNavigate } from 'react-router-dom';
import getAllProfiles from '../utils/getAllProfiles';
import getAllRowsById from '../utils/getAllRowsById';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';
import postBulkNotesData from '../utils/postBulkNotesData';
import postDataToEndPointProfileChanges from '../utils/postDataToEndPointProfileChanges';
import MessagesContent from './../components/MessagesContent';
import { SignedInUserProps } from './../interfaces/SignedInUserProps';
import ninoxTableAddRow from './../utils/ninoxTableAddRow';
import ninoxTableGetRow from './../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import { UserMeta } from './../interfaces/UserMeta';
import SelectRS from 'react-select';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CountUp from 'react-countup';
import UL_logo from './../logo/unlocking_language_logo_transparent.png';

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import EtlProcessCaseLoads from './EtlProcessCaseLoads';
import EtlProcessCaseNotes from './EtlProcessCaseNotes';
import EtlProcessReportTabData from './EtlProcessReportTabData';
const allTherapistsEP = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS;

async function getTherapistName(txid: number) {
  const myData = ninoxTableGetRow(
    process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
    txid,
    process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
  ).then((data: any) => {
    // return data[0]?.fields['First Name'];
    return data;
    // console.log(data[0]?.fields['First Name']);
  });

  return myData;
}

async function getAdminName(txid: number) {
  const myData = ninoxTableGetRow(
    process.env.REACT_APP_UL_ADMIN_USERS!,
    txid,
    process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
  ).then((data: any) => {
    // return data[0]?.fields['First Name'];
    return data;
    // console.log(data[0]?.fields['First Name']);
  });

  return myData;
}
const clientsListEP = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
export interface SignedInUserPropsAdmin {
  signedInUserId: number;
  setSignedInUserId?: Function;
  signedInUserEmail: string;
  setSignedInUserEmail?: Function;
  userMeta: UserMeta;
  match?: any;
  navWidth: number;
  setNavWidth: Function;
  sessionId?: number;
  signedInUserType: string;
  setSignedInUserType: Function;
  approveOrReject: number;
  setApproveOrReject: Function;
}
const AdminContent = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
  approveOrReject,
  setApproveOrReject,
}: SignedInUserPropsAdmin) => {
  const toast = useToast();

  const approveAccount = (e: any, accountId: string) => {
    const clientToUpdate = {
      'Account status': true,
    };
    try {
      ninoxTableUpdateRow(
        clientsListEP!,
        Number(accountId),
        { fields: clientToUpdate },
        String(bearerToken)
      ).then(() => {
        getAllProfiles(clientsListEP + '?perPage=5000', bearerToken!).then((values) => {
          // console.log(values);
          // setRows(values);
        });
        toast({
          title: 'Client approved.',
          description: 'The client data has been successfully updated.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Update error',
        description:
          'The client was note updated. There was an error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const navigate = useNavigate();
  const [rows, setRows] = useState<any>([]);

  const [profileApprovals, setProfileApprovals] = useState<any>([]);

  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulClientsTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  // const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const [copied, setCopied] = useState<string>('');
  const [copiedExp, setCopiedExp] = useState<string>('');

  const [activeTab, setActiveTab] = useState<string>('');
  const [msSchools, setMsSchools] = useState<any>('');
  const [ssSchools, setSsSchools] = useState<any>('');
  const [therapists, setTherapists] = useState<any>('');
  const [subscriber, setSubscriber] = useState<any>('');
  // const [sessions, setSessions] = useState<any>('');
  const [notesToImport, setNotesToImport] = useState<any>();
  const [schoolFilter, setSchoolFilter] = useState(0);
  const [schoolType, setSchoolType] = useState<string>('none');
  const [sheetId, setSheetId] = useState<string>('');
  const [sheetTabName, setSheetTabName] = useState<string>('');
  const [dataWeGotFromSheet, setDataWeGotFromSheet] = useState<any>('none');
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(0);
  const [txLoading, setTxLoading] = useState(0);

  const [studentId, setStudentId] = useState<number>();
  function updateUserType(userType: string) {}
  const therapistSessionNotesRegularSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
  const therapistSessionNotesSENSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;

  const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
  const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
  const ulTherapistsTable = process.env.REACT_APP_UL_ALL_THERAPIST_PROFILES;
  const allTherapists = process.env.REACT_APP_ALL_THERAPISTS_EP;
  const ulSubscriberTable = process.env.REACT_APP_UL_NINOX_TABLES_SUBSCRIPTION_CLIENTS;
  const allSubscribers = process.env.REACT_APP_SUBSCRIBER_TABLE;
  const caseloadsEP = process.env.REACT_APP_ETL_CASELOADS_SERVERLESS_FUNCTION_EP;
  const allAnalyticsEP = process.env.REACT_APP_ALL_ANALYTICS_EP;
  const initialized = useRef(false);
  const accountType = localStorage.getItem('accountType');
  const enterTxSession = async (sessionType: string) => {
    const sessionToAdd = {
      email: String(localStorage.getItem('accountEmail')),
      accountNumber: String(localStorage.getItem('accountNumber')),
      Time: currentDateTime,
      Type: sessionType,
    };
    if (!initialized.current) {
      initialized.current = true;
      try {
        ninoxTableAddRow(allAnalyticsEP, { fields: sessionToAdd }, bearerToken).then(
          (data: any) => {
            return data;
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const approveProfileMakeEP = process.env.REACT_APP_APPROVE_PROFILE_UPDATES_MAKE_EP;
  const rejectProfileMakeEP = process.env.REACT_APP_REJECT_PROFILE_UPDATES_MAKE_EP;
  function sendDataToMake(dataToSend: any, ep: any) {
    postDataToEndPointProfileChanges(ep, dataToSend).then((data: any) => {
      // Get profile approvals
      const queryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
      const profileApprovalsEndpoint = process.env.REACT_APP_CLIENT_PROFILE_APPROVAL_CENTER_EP;
      const profileApprovalsQuery = `concat((select 'Request changes')['Approved?' != true and 'Rejected?' != true].number(Id))`;

      getAllRowsByEncodedQuery(queryEP, profileApprovalsQuery).then((data: any) => {
        const validJsonString = `[${data.replace(/'/g, '"')}]`;
        const approvalsData = JSON.parse(validJsonString);
        console.log(approvalsData);

        approvalsData?.length >= 0 &&
          getMultipleApiData(profileApprovalsEndpoint!, bearerToken!, approvalsData).then(
            (data: any) => {
              setProfileApprovals(data);
            }
          );
      });
    });
  }
  const [optionsClinicalValue, setOptionsClinicalValue] = useState<number>(1700);
  const [seriesClinicalValue, setSeriesClinicalValue] = useState<number>(0);
  const [seriesClinical, setSeriesClinical] = useState<number[]>([0]);
  const [optionsClinical, setOptionsClinical] = useState<ApexOptions>({});

  const [optionsProfileUpdatesValue, setOptionsProfileUpdatesValue] = useState<number>(0);
  const [seriesProfileUpdatesValue, setSeriesProfileUpdatesValue] = useState<number>(0);
  const [seriesProfileUpdates, setSeriesProfileUpdates] = useState<number[]>([0]);
  const [optionsProfileUpdates, setOptionsProfileUpdates] = useState<ApexOptions>({});

  const [optionsMsSchoolsValue, setOptionsMsSchoolsValue] = useState<number>(0);
  const [seriesMsSchoolsValue, setSeriesMsSchoolsValue] = useState<number>(0);
  const [seriesMsSchools, setSeriesMsSchools] = useState<number[]>([0]);
  const [optionsMsSchools, setOptionsMsSchools] = useState<ApexOptions>({});
  const [clientCount, setClientCount] = useState<number>(0);
  const [clientLoading, setClientLoading] = useState<number>(0);

  useEffect(() => {
    const EP = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
    const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;

    // schools &&
    //   getAllProfiles(EP, bearerToken!).then((values) => {
    //     console.log(values);
    //     setRows(values);
    //   });

    // getAllProfiles(String(therapistsTable), bearerToken!).then((values) => {
    //   const thePatients = values.filter((i: any) => i.id === signedInUserId);
    //   const patientsList = thePatients[0]?.fields['Central Client Database - ALL STAFF USAGE'];
    //   console.log(
    //     'thePatients: ',
    //     thePatients[0]?.fields['Central Client Database - ALL STAFF USAGE']
    //   );
    // getMultipleApiData(EP, bearerToken!, patientsList).then((values) => {
    //   console.log('patientsList: ', values);
    //   setPatients(values);
    // });

    getAllRowsById(regularSchoolsEP).then((data) => {
      const myData = data;
      setMsSchools(myData);
      setSeriesMsSchoolsValue(myData.length);
      setOptionsMsSchoolsValue(myData.length);
      setSeriesMsSchools([myData.length]);
    });
    getAllRowsById(senSchoolsEP).then((data) => {
      const myData = data;
      setSsSchools(myData);
    });
    getAllRowsById(allTherapists).then((data) => {
      const myData = data;
      setTherapists(myData);
    });
    getAllRowsById(allSubscribers).then((data) => {
      const myData = data;
      setSubscriber(myData);
    });

    // rows &&
    //   getAllProfiles(EP + '?perPage=5000', bearerToken!).then((values) => {
    //     // console.log(values);
    //     setRows(values);
    //   });
    // Clients query EP:

    //     const allClientsCountsQuery = `let a := count(select 'Central Client Database - ALL STAFF USAGE');
    // let b := count(concat((select 'Request changes')['Approved?' != true and 'Rejected?' != true].number(Id)));
    // let c := count(select 'Subscription clients');
    // [a, b, c]`;
    const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

    const allClientsQuery = `let a := (select 'Central Client Database - ALL STAFF USAGE');
    let string := [""];
    for i in a do
      string := array(string, [i.Id + "_" + i.'First Name' + " " + i.'Last Name'])
    end;
    slice(string, 1, length(string) + 1)`;

    getAllRowsByEncodedQuery(clientsQueryEP, allClientsQuery).then((data: any) => {
      console.log('Counts:', data.length);
      setClientCount(data.length);
    });

    // Get profile approvals
    const queryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
    const profileApprovalsEndpoint = process.env.REACT_APP_CLIENT_PROFILE_APPROVAL_CENTER_EP;
    const profileApprovalsQuery = `concat((select 'Request changes')['Approved?' != true and 'Rejected?' != true].number(Id))`;

    // Getting all profile approvals
    getAllRowsByEncodedQuery(queryEP, profileApprovalsQuery).then((data: any) => {
      const validJsonString = `[${data.replace(/'/g, '"')}]`;
      const approvalsData = JSON.parse(validJsonString);
      console.log(approvalsData);

      data?.length > 0 &&
        getMultipleApiData(profileApprovalsEndpoint!, bearerToken!, approvalsData).then(
          (data: any) => {
            setProfileApprovals(data);
            setSeriesProfileUpdatesValue(data?.length);
            setOptionsProfileUpdatesValue(data?.length);
            setSeriesProfileUpdates([data?.length]);
          }
        );
    });

    // enterTxSession(accountType!);
    // getAllRowsById(allAnalyticsEP).then(async (data: any) => {
    //   // data.shift();
    //   console.log('Shifted data:', data);
    //   const results = await Promise.all(
    //     data?.map(async (i: any) => {
    //       return {
    //         sessionId: i?.id === null ? 1 : i?.id,
    //         accountNumber: i?.fields['accountNumber'],
    //         timeStamp: moment(i.createdAt).format('DD/MM/YYYY HH:mm'),
    //         type: i.fields['Type'],
    //       };
    //     })
    //   );
    //   data && setTxLoading(1);
    //   data && setSessions(results);
    // });

    // const results = await Promise.all(
    //   array.map(async (item) => {
    //     return await asyncFunction(item);
    //   })
    // );

    // getAllRowsById(test).then((data: any) => {
    //   const myData = data;
    //   console.log(myData);
    //   // setSessions(myData);
    // });
    // getAllRowsById(regularSchoolsEP).then((data) => {
    //   const myData = data;
    //   // console.log('Regular Schools: ', JSON.stringify(myData), 'color:blue;');
    //   setSchools(myData);
    // });
    // getAllRowsById(senSchoolsEP).then((data) => {
    //   const myData = data;
    //   // console.log('SEN Schools: ', JSON.stringify(myData), 'color:red;');
    //   setSenSchools(myData);
    // });
    // });
  }, []);
  // useEffect(() => {
  //   // Get profile approvals
  //   const queryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
  //   const profileApprovalsEndpoint = process.env.REACT_APP_CLIENT_PROFILE_APPROVAL_CENTER_EP;
  //   const profileApprovalsQuery = `concat((select 'Request changes')['Approved?' != true and 'Rejected?' != true].number(Id))`;

  //   // Getting all profile approvals
  //   getAllRowsByEncodedQuery(queryEP, profileApprovalsQuery).then((data: any) => {
  //     const validJsonString = `[${data.replace(/'/g, '"')}]`;
  //     const approvalsData = JSON.parse(validJsonString);
  //     console.log(approvalsData);

  //     data?.length > 0 &&
  //       getMultipleApiData(profileApprovalsEndpoint!, bearerToken!, approvalsData).then(
  //         (data: any) => {
  //           setProfileApprovals(data);
  //         }
  //       );
  //   });
  // }, [approveOrReject]);

  // useEffect(() => {}, []);

  let caseLoadData = dataWeGotFromSheet?.values && dataWeGotFromSheet?.values.slice(2);

  let sheetToImport = {
    schoolType: schoolType,
    schoolFilter: schoolFilter,
    sheetId: sheetId,
    sheetTabName: sheetTabName,
  };

  const notesTableEP = process.env.REACT_APP_UL_NOTES_TABLE;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;

  function saveRowsToNinox(arrayOfNotes: any, studentId: any, schoolType: string) {
    postBulkNotesData(
      schoolType === 'mainstream'
        ? String(therapistSessionNotesRegularSchoolsEP)
        : String(therapistSessionNotesSENSchoolsEP),
      String(bearerToken),
      arrayOfNotes,
      studentId
    ).then((data: any) => setLoading(3));
  }
  interface InvoiceFields {
    'Invoice Date': string;
    'Invoice Due Date': string;
    'Invoice Status': string;
    'Invoice Posted to Xero?': boolean;
    'Invoice No': string;
    Reference: string;
  }

  interface Invoices {
    createdAt: string;
    createdBy: string;
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    fields: InvoiceFields;
  }
  // console.log(rows);
  // let numberOfCaseloadNotes;
  //   numberOfCaseloadNotes = dataWeGotFromSheet && dataWeGotFromSheet?.values;
  //   let slicedNumberOfCaseloads = numberOfCaseloadNotes.slice(2);
  const today = new Date();
  const currentDateTime = moment(today).format('DD/MM/YYYY HH:mm');
  useEffect(() => {
    setOptionsClinical({
      series: [seriesClinicalValue],
      chart: {
        height: 350,
        type: 'radialBar',
      },
      labels: ['Clinical Clients'],
      colors: ['#D6D944'],
      stroke: {
        curve: 'smooth',
        colors: ['transparent'],
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%',
          },
          // fill: {
          //   type: 'gradient',
          //   gradient: {
          //     shade: 'dark',
          //     type: 'vertical',
          //     gradientToColors: ['#87D4F9'],
          //     // stops: [0, 360],
          //   },
          // },
          startAngle: 0,
          endAngle: 360,
          dataLabels: {
            show: true,

            name: {
              fontSize: '20',
              color: 'black',
              offsetY: 70,
              // textAlign: 'center',
              // display: 'inline-block',
              // wordBreak: 'break-word',
            },
            value: {
              offsetY: 0,
              fontSize: '90px',
              fontWeight: 'bold',
              color: undefined,
              formatter: function () {
                return `${seriesClinicalValue}`;
              },
            },
          },
        },
      },
    });
  }, [rows]);

  useEffect(() => {
    setOptionsProfileUpdates({
      series: [seriesProfileUpdatesValue],
      chart: {
        height: 350,
        type: 'radialBar',
      },
      labels: ['Profile Updates'],
      colors: ['#40A83D'],
      stroke: {
        curve: 'smooth',
        colors: ['transparent'],
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%',
          },
          // fill: {
          //   type: 'gradient',
          //   gradient: {
          //     shade: 'dark',
          //     type: 'vertical',
          //     gradientToColors: ['#87D4F9'],
          //     // stops: [0, 360],
          //   },
          // },
          startAngle: 0,
          endAngle: 360,
          dataLabels: {
            show: true,

            name: {
              fontSize: '20',
              color: 'black',
              offsetY: 70,
              // textAlign: 'center',
              // display: 'inline-block',
              // wordBreak: 'break-word',
            },
            value: {
              offsetY: 0,
              fontSize: '90px',
              fontWeight: 'bold',
              color: undefined,
              formatter: function () {
                return `${seriesProfileUpdatesValue}`;
              },
            },
          },
        },
      },
    });
  }, [profileApprovals]);

  useEffect(() => {
    setOptionsMsSchools({
      series: [seriesMsSchoolsValue],
      chart: {
        height: 350,
        type: 'radialBar',
      },
      labels: ['MsSchools Clients'],
      colors: ['#0C7348'],
      stroke: {
        curve: 'smooth',
        colors: ['transparent'],
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '70%',
          },
          startAngle: 0,
          endAngle: 360,
          dataLabels: {
            show: true,

            name: {
              fontSize: '20',
              color: 'black',
              offsetY: 70,
              // textAlign: 'center',
              // display: 'inline-block',
              // wordBreak: 'break-word',
            },
            value: {
              offsetY: 0,
              fontSize: '90px',
              fontWeight: 'bold',
              color: undefined,
              formatter: function () {
                return `${seriesMsSchoolsValue}`;
              },
            },
          },
        },
      },
    });
  }, [msSchools]);

  const CountUpTool = ({ color, amount, title }: any) => {
    return (
      <Box
        fontSize={60}
        fontWeight={'bold'}
        bg={'white'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
        border={`20px solid ${color}`}
        borderRadius={300}
        width={300}
        height={300}
      >
        <CountUp start={0} end={amount} duration={2} />
        <Text textAlign={'center'} fontSize={20} textTransform={'uppercase'}>
          {title}
        </Text>
      </Box>
    );
  };

  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} borderRadius={20} bg={'white'}>
          {localStorage.getItem('accountType') === 'Admin' && (
            <>
              <HStack width={'100%'}>
                <Box width="75%" display={'flex'} flexDirection={'row'}>
                  <Heading pb={6} fontWeight={'300'} mr={10}>
                    Admin Dashboard
                  </Heading>
                  <Link
                    href={
                      'https://console.firebase.google.com/project/client-portal-69658/authentication/users'
                    }
                    target="_blank"
                  >
                    <Button
                      variant={'defaultButton'}
                      bg={'brand.300'}
                      _hover={{ background: 'brand.200' }}
                      mr={5}
                    >
                      Firebase users
                    </Button>
                  </Link>
                  <Link href={'https://vercel.com/unlockinglanguages-projects'} target="_blank">
                    <Button
                      variant={'defaultButton'}
                      bg={'brand.300'}
                      _hover={{ background: 'brand.200' }}
                      mr={5}
                    >
                      Vercel hosting
                    </Button>
                  </Link>
                  <Link href={'https://github.com/UnlockingLanguage'} target="_blank">
                    <Button
                      variant={'defaultButton'}
                      bg={'brand.300'}
                      _hover={{ background: 'brand.200' }}
                      mr={5}
                    >
                      Github repo
                    </Button>
                  </Link>
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'righ'}
                  flexDirection={'column'}
                  width={'25%'}
                  borderRadius={10}
                >
                  <Image
                    width="260px"
                    objectFit="cover"
                    src={UL_logo}
                    alt="Unlocking Language"
                    mt={-10}
                  />
                </Box>
              </HStack>
              <TableContainer>
                <Table variant="simple">
                  <Tabs variant="soft-rounded" colorScheme="green">
                    <TabList ml={5}>
                      <Tab onClick={() => setActiveTab('Clinical')}>Dashboard</Tab>
                      <Tab onClick={() => setActiveTab('Subscriber')}>
                        Profile updates: ({profileApprovals.length})
                      </Tab>
                      <Tab
                        onClick={() => {
                          const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

                          const allClientsQuery = `let a := (select 'Central Client Database - ALL STAFF USAGE');
                          let string := [""];
                          for i in a do
                            string := array(string, [i.Id + "_" + i.'First Name' + " " + i.'Last Name'])
                          end;
                          slice(string, 1, length(string) + 1)`;
                          // Getting all clients
                          getAllRowsByEncodedQuery(clientsQueryEP, allClientsQuery).then(
                            (data: any) => {
                              const objectsArray = data.map((val: any) => {
                                const underscoreIndex = val.indexOf('_');
                                const id =
                                  underscoreIndex === -1 ? '' : val.substring(0, underscoreIndex);
                                const cname =
                                  underscoreIndex === -1 ? '' : val.substring(underscoreIndex + 1);

                                return {
                                  id: id === '' ? 0 : id,
                                  label:
                                    cname === '' || cname === ' '
                                      ? 'Name not found'
                                      : id + ') ' + cname,
                                };
                              });
                              console.log(data);

                              // console.log(objectsArray);
                              setRows(objectsArray);
                              setClientLoading(1);
                              setSeriesClinicalValue(objectsArray.length);
                              setOptionsClinicalValue(objectsArray.length);
                              setSeriesClinical([objectsArray.length]);
                            }
                          );
                          setActiveTab('Clinical');
                        }}
                      >
                        Clinical({clientCount})
                      </Tab>
                      <Tab onClick={() => setActiveTab('Mainstream')}>
                        Mainstream Schools({msSchools.length})
                      </Tab>
                      <Tab onClick={() => setActiveTab('SEN')}>SEN Schools({ssSchools.length})</Tab>
                      <Tab onClick={() => setActiveTab('Therapists')}>
                        Therapists({therapists.length})
                      </Tab>
                      <Tab onClick={() => setActiveTab('Subscriber')}>
                        Subscriber({subscriber.length})
                      </Tab>

                      {/* <Tab onClick={() => setActiveTab('ETL')}>ETL Processes</Tab>
                      <Tab onClick={() => setActiveTab('ReportData')}>ETL Report Data</Tab> */}

                      <Tab onClick={() => setActiveTab('Messaging')}>Messaging</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <Box width={'100%'} bg={'gray.100'} borderRadius={20}>
                          <SimpleGrid
                            columns={[3, 3, 3, 3]}
                            spacing={5}
                            bg={'gray.100'}
                            p={10}
                            borderRadius={20}
                            // display={'flex'}
                            // flexDirection={'row'}
                            justifyContent={'center'}
                            justifyItems="center"
                            alignItems={'center'}
                          >
                            <CountUpTool
                              color={'#D6D944'}
                              amount={clientCount}
                              title={'Clinical Clients'}
                            />
                            <CountUpTool color={'#40A83D'} amount={2} title={'Profile Updates'} />
                            <CountUpTool
                              color={'#0C7348'}
                              amount={46}
                              title={'Mainstream Schools'}
                            />
                            <CountUpTool color={'#0C7348'} amount={27} title={'SEN Schools'} />
                            <CountUpTool color={'#40A83D'} amount={134} title={'Therapists'} />
                            <CountUpTool color={'#D6D944'} amount={2} title={'UL Academy'} />

                            {/* <Box fontSize={20}>
                              <CountUp start={0} end={optionsClinicalValue} duration={2} />
                            </Box>
                            <Box fontSize={20}>
                              <CountUp start={0} end={optionsProfileUpdatesValue} duration={2} />
                            </Box>
                            <Box fontSize={20}>
                              <CountUp start={0} end={optionsMsSchoolsValue} duration={2} />
                            </Box> */}

                            {/* <ReactApexChart
                              options={optionsClinical}
                              series={seriesClinical}
                              type="radialBar"
                            />

                            <ReactApexChart
                              options={optionsProfileUpdates}
                              series={seriesProfileUpdates}
                              type="radialBar"
                            />
                            <ReactApexChart
                              options={optionsMsSchools}
                              series={seriesMsSchools}
                              type="radialBar"
                            /> */}
                          </SimpleGrid>
                        </Box>
                      </TabPanel>
                      <TabPanel>
                        <Heading onClick={() => setApproveOrReject(1)}>Profile approvals</Heading>

                        <Thead>
                          <Tr>
                            <Th>ID</Th>
                            <Th>Client ID</Th>
                            <Th>Type</Th>
                            <Th>Status</Th>
                            <Th>Approve/Reject</Th>
                            <Th>First Name</Th>
                            <Th>Last Name</Th>
                            <Th>Email</Th>
                            <Th>Contact Number</Th>
                            <Th>Full address & Postcode</Th>
                            <Th>Parent's First Name</Th>
                            <Th>Parent's Last Name</Th>
                            <Th>Phone</Th>
                            <Th>Mobile 1</Th>
                            <Th>Email 1</Th>
                            <Th>Email 2</Th>
                            <Th>Address</Th>
                            <Th>Access level</Th>
                            <Th>Additional party involved</Th>
                            <Th>Additional party details</Th>
                            <Th>Therapy Frequency</Th>
                            <Th>Therapy Length</Th>
                            <Th>Therapy Rate</Th>
                            <Th>Invoice Frequency</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {profileApprovals &&
                            profileApprovals
                              // .sort((a: any, b: any) => b.id - a.id)
                              .map((i: any, k: any) => (
                                <Tr key={k}>
                                  <Td>{i.id}</Td>
                                  <Td>{i.fields[`Client ID`]}</Td>
                                  <Td>{i.fields[`Type`]}</Td>
                                  <Td>
                                    {i.fields['Approved?'] === false ? (
                                      <Button
                                        variant={'defaultButton'}
                                        bg={'brnad.300'}
                                        _hover={{ background: 'brand.200' }}
                                        onClick={() => {
                                          const requestBodyApprove = {
                                            this_id: i.id,
                                            type: i.fields[`Type`],
                                            client_id: i.fields[`Client ID`],
                                            email: i.fields[`Email`],
                                            firstname: i.fields[`First Name`],
                                            lastName: i.fields[`Last Name`],
                                            phone: i.fields[`Contact Number`],
                                            address: i.fields[`Full address & Postcode`],
                                            parentfirst: i.fields[`Parent's First Name`],
                                            parentlast: i.fields[`Parent's Last Name`],
                                            phone2: i.fields[`Phone`],
                                            mobile1: i.fields[`Mobile 1`],
                                            email1: i.fields[`Email 1`],
                                            email2: i.fields[`Email 2`],
                                            address2: i.fields[`Address`],
                                            party: i.fields[`Additional party involved`],
                                            partydetails: i.fields[`Additional party details`],
                                            therapyfreq: i.fields[`Therapy Frequency`],
                                            therapylength: i.fields[`Therapy Length`],
                                            invoicefreq: i.fields[`Invoice Frequency`],
                                          };
                                          sendDataToMake(requestBodyApprove, approveProfileMakeEP);
                                          console.log('Approved changes');
                                        }}
                                      >
                                        <IconButton
                                          aria-label="Search database"
                                          icon={<BiCheck />}
                                          bg={'transparent'}
                                          color={'white'}
                                          ml={-2}
                                          _hover={{ background: 'transparent', color: 'white' }}
                                        />
                                        Approve{' '}
                                      </Button>
                                    ) : (
                                      <Text>Changes approved</Text>
                                    )}
                                  </Td>
                                  <Td>
                                    {i.fields['Approved?'] === false ? (
                                      <Button
                                        variant={'defaultButton'}
                                        bg={'red.600'}
                                        _hover={{ background: 'red.700' }}
                                        onClick={() => {
                                          const requestBodyApprove = {
                                            this_id: i.id,
                                            type: i.fields[`Type`],
                                            client_id: i.fields[`Client ID`],
                                            email: i.fields[`Email`],
                                            firstname: i.fields[`First Name`],
                                            lastName: i.fields[`Last Name`],
                                            phone: i.fields[`Contact Number`],
                                            address: i.fields[`Full address & Postcode`],
                                            parentfirst: i.fields[`Parent's First Name`],
                                            parentlast: i.fields[`Parent's Last Name`],
                                            phone2: i.fields[`Phone`],
                                            mobile1: i.fields[`Mobile 1`],
                                            email1: i.fields[`Email 1`],
                                            email2: i.fields[`Email 2`],
                                            address2: i.fields[`Address`],
                                            party: i.fields[`Additional party involved`],
                                            partydetails: i.fields[`Additional party details`],
                                            therapyfreq: i.fields[`Therapy Frequency`],
                                            therapylength: i.fields[`Therapy Length`],
                                            invoicefreq: i.fields[`Invoice Frequency`],
                                          };
                                          sendDataToMake(requestBodyApprove, rejectProfileMakeEP);
                                          console.log('Rejected changes');
                                        }}
                                      >
                                        <IconButton
                                          aria-label="Search database"
                                          icon={<BiCross />}
                                          bg={'transparent'}
                                          color={'white'}
                                          ml={-2}
                                          _hover={{ background: 'transparent', color: 'white' }}
                                        />
                                        Reject{' '}
                                      </Button>
                                    ) : (
                                      <Text>Changes rejected</Text>
                                    )}
                                  </Td>
                                  <Td>{i.fields[`First Name`]}</Td>
                                  <Td>{i.fields[`Last Name`]}</Td>
                                  <Td>{i.fields[`Email`]}</Td>
                                  <Td>{i.fields[`Contact Number`]}</Td>
                                  <Td>{i.fields[`Full address & Postcode`]}</Td>
                                  <Td>{i.fields[`Parent's First Name`]}</Td>
                                  <Td>{i.fields[`Parent's Last Name`]}</Td>
                                  <Td>{i.fields[`Phone`]}</Td>
                                  <Td>{i.fields[`Mobile 1`]}</Td>
                                  <Td>{i.fields[`Email 1`]}</Td>
                                  <Td>{i.fields[`Email 2`]}</Td>
                                  <Td>{i.fields[`Address`]}</Td>
                                  <Td>{i.fields[`Access level`]}</Td>
                                  <Td>{i.fields[`Additional party involved`]}</Td>
                                  <Td>{i.fields[`Additional party details`]}</Td>
                                  <Td>{i.fields[`Therapy Frequency`]}</Td>
                                  <Td>{i.fields[`Therapy Length`]}</Td>
                                  <Td>{i.fields[`Therapy Rate`]}</Td>
                                  <Td>{i.fields[`Invoice Frequency`]}</Td>
                                </Tr>
                              ))}
                        </Tbody>
                      </TabPanel>
                      <TabPanel>
                        {/* <Box onClick={() => setOffset(offset + 100)} cursor={'pointer'}>
                          Next 100
                        </Box> */}
                        <Box>
                          <Box width={'100%'} bg={'gray.100'} p={5}>
                            <Box width={'700px'}>
                              <SelectRS
                                options={rows && rows}
                                onChange={(value: any) => {
                                  // setAllSchoolFilter(Number(value.value));
                                  // console.log(value);
                                  navigate(
                                    '/therapist-client-session/' +
                                      value.id +
                                      '?schoolType=RegularSchool'
                                  );
                                }}
                                placeholder={'Select from all clients (' + clientCount + ')'}
                              />
                            </Box>
                          </Box>
                          <Thead>
                            <Tr>
                              <Th>ID</Th>
                              <Th>First name + Last name</Th>
                              {/* <Th>Last name</Th>
                            <Th>Primary email</Th>
                            <Th>Contact number</Th>
                            <Th>Client type</Th> */}
                              <Th>View client</Th>
                              <Th>Sign up link</Th>
                              <Th>Express Onboarding</Th>
                            </Tr>
                          </Thead>
                          {clientLoading === 0 ? (
                            '... Loading clients'
                          ) : (
                            <Tbody>
                              {rows &&
                                rows.map((i: any, k: any) => (
                                  <Tr key={k}>
                                    <Td>{i.id}</Td>
                                    <Td>{i.label}</Td>
                                    <Td>
                                      <Button
                                        variant={'defaultButton'}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `https://portal.unlockinglanguage.co.uk/private-client-clinical-sign-up?ul_cl=${i.id}`
                                          );
                                        }}
                                      >
                                        <IconButton
                                          aria-label="Search database"
                                          icon={<BiSearch />}
                                          bg={'transparent'}
                                          color={'white'}
                                          ml={-2}
                                          _hover={{ background: 'transparent', color: 'white' }}
                                        />
                                        View client
                                      </Button>
                                      {copied}
                                    </Td>
                                    {/* <Td>{i.fields['First Name']}</Td>
     <Td>{i.fields['Last Name']}</Td>
     <Td>{i.fields['Primary Email Address']}</Td>
     <Td>{i.fields['Mobile 1']}</Td>
     <Td>{i.fields['Client type']}</Td> */}
                                    <Td>
                                      <Button
                                        variant={'defaultButton'}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `https://portal.unlockinglanguage.co.uk/private-client-clinical-sign-up?ul_cl=${i.id}`
                                          );
                                        }}
                                      >
                                        <IconButton
                                          aria-label="Search database"
                                          icon={<BiCopy />}
                                          bg={'transparent'}
                                          color={'white'}
                                          ml={-2}
                                          _hover={{ background: 'transparent', color: 'white' }}
                                        />
                                        Sign up link
                                      </Button>
                                      {copied}
                                    </Td>
                                    <Td>
                                      <Button
                                        variant={'defaultButton'}
                                        bg={'purple.700'}
                                        _hover={{ background: 'purple.500' }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `https://portal.unlockinglanguage.co.uk/private-client-clinical-express-onboarding?ul_cl=${i.id}`
                                          );
                                        }}
                                      >
                                        <IconButton
                                          aria-label="Search database"
                                          icon={<BiCopy />}
                                          bg={'transparent'}
                                          color={'white'}
                                          ml={-2}
                                          _hover={{ background: 'transparent', color: 'white' }}
                                        />
                                        Express link
                                      </Button>
                                      {copiedExp}
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel>
                        <TableContainer>
                          {localStorage.getItem('accountType') !== undefined &&
                            localStorage.getItem('accountType') === 'Admin' && (
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th
                                      style={{
                                        position: 'sticky',
                                        left: '0',
                                        width: '200px',
                                        background: '#edf2f7',
                                        zIndex: 9,
                                        borderTopLeftRadius: 20,
                                      }}
                                    >
                                      School Name
                                    </Th>
                                    <Th>Onboarding Link</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {msSchools &&
                                    msSchools.map((i: any, k: any) => {
                                      return (
                                        <Tr key={k} style={{ cursor: 'pointer' }}>
                                          <Td
                                            style={{
                                              position: 'sticky',
                                              left: '0',
                                              width: '250px',
                                              background: '#edf2f7',
                                              zIndex: 9,
                                            }}
                                          >
                                            {i.id + ') ' + i?.fields['School Name']}
                                          </Td>
                                          <Td>
                                            <Button
                                              variant={'defaultButton'}
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `https://portal.unlockinglanguage.co.uk/school-sign-up?schoolID=${i.id}&schoolType=RegularSchool`
                                                );
                                              }}
                                            >
                                              <IconButton
                                                aria-label="Search database"
                                                icon={<BiCopy />}
                                                bg={'transparent'}
                                                color={'white'}
                                                ml={-2}
                                                _hover={{
                                                  background: 'transparent',
                                                  color: 'white',
                                                }}
                                              />
                                              Onboarding link
                                            </Button>
                                          </Td>
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              View Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              Edit Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              TOMS Rating
                                            </Button>
                                          </Td> */}
                                        </Tr>
                                      );
                                    })}
                                  {}
                                </Tbody>
                                {msSchools === undefined && (
                                  <TableCaption>Your sessions history</TableCaption>
                                )}
                              </Table>
                            )}
                        </TableContainer>
                      </TabPanel>
                      <TabPanel>
                        <TableContainer>
                          {localStorage.getItem('accountType') !== undefined &&
                            localStorage.getItem('accountType') === 'Admin' && (
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th
                                      style={{
                                        position: 'sticky',
                                        left: '0',
                                        width: '200px',
                                        background: '#edf2f7',
                                        zIndex: 9,
                                        borderTopLeftRadius: 20,
                                      }}
                                    >
                                      School Name
                                    </Th>
                                    <Th>Onboarding Link</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {ssSchools &&
                                    ssSchools.map((i: any, k: any) => {
                                      return (
                                        <Tr key={k} style={{ cursor: 'pointer' }}>
                                          <Td
                                            style={{
                                              position: 'sticky',
                                              left: '0',
                                              width: '250px',
                                              background: '#edf2f7',
                                              zIndex: 9,
                                            }}
                                          >
                                            {i.id + ') ' + i?.fields['School Name']}
                                          </Td>
                                          <Td>
                                            <Button
                                              variant={'defaultButton'}
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `https://portal.unlockinglanguage.co.uk/school-sign-up?schoolID=${i.id}&schoolType=SENSchool`
                                                );
                                              }}
                                            >
                                              <IconButton
                                                aria-label="Search database"
                                                icon={<BiCopy />}
                                                bg={'transparent'}
                                                color={'white'}
                                                ml={-2}
                                                _hover={{
                                                  background: 'transparent',
                                                  color: 'white',
                                                }}
                                              />
                                              Onboarding link
                                            </Button>
                                          </Td>
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              View Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              Edit Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              TOMS Rating
                                            </Button>
                                          </Td> */}
                                        </Tr>
                                      );
                                    })}
                                  {}
                                </Tbody>
                                {ssSchools === undefined && (
                                  <TableCaption>Your sessions history</TableCaption>
                                )}
                              </Table>
                            )}
                        </TableContainer>
                      </TabPanel>
                      <TabPanel>
                        <TableContainer>
                          {localStorage.getItem('accountType') !== undefined &&
                            localStorage.getItem('accountType') === 'Admin' && (
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th
                                      style={{
                                        position: 'sticky',
                                        left: '0',
                                        width: '50px',
                                        background: '#edf2f7',
                                        zIndex: 9,
                                        borderTopLeftRadius: 20,
                                      }}
                                    >
                                      TXID
                                    </Th>
                                    <Th
                                      style={{
                                        position: 'sticky',
                                        left: '0',
                                        width: '250px',
                                        background: '#edf2f7',
                                        zIndex: 9,
                                      }}
                                    >
                                      First name
                                    </Th>
                                    <Th
                                      style={{
                                        position: 'sticky',
                                        left: '250px',
                                        width: '250px',
                                        background: '#edf2f7',
                                        zIndex: 9,
                                      }}
                                    >
                                      Last name
                                    </Th>
                                    <Th>Email</Th>
                                    <Th>Working with us</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {therapists &&
                                    therapists.map((i: any, k: any) => {
                                      return (
                                        <Tr key={k} style={{ cursor: 'pointer' }}>
                                          <Td
                                            style={{
                                              position: 'sticky',
                                              left: '0',
                                              width: '50px',
                                              background: '#edf2f7',
                                              zIndex: 9,
                                            }}
                                          >
                                            {i.id}
                                          </Td>
                                          <Td
                                            style={{
                                              position: 'sticky',
                                              left: '50',
                                              width: '250px',
                                              background: '#edf2f7',
                                              zIndex: 9,
                                            }}
                                          >
                                            {i?.fields['First Name']}
                                          </Td>
                                          <Td
                                            style={{
                                              position: 'sticky',
                                              left: '0',
                                              width: '300px',
                                              background: '#edf2f7',
                                              zIndex: 9,
                                            }}
                                          >
                                            {i?.fields['Surname']}
                                          </Td>
                                          <Td>
                                            <a href={'mailto:' + i?.fields['Main Email']}>
                                              <Button
                                                backgroundColor={'brand.300'}
                                                color={'white'}
                                                _hover={{
                                                  background: 'brand.400',
                                                  color: 'white',
                                                }}
                                              >
                                                <BsFillEnvelopeAtFill />
                                                &nbsp;
                                                {' ' + i?.fields['Main Email']}
                                              </Button>
                                            </a>
                                          </Td>
                                          <Td>
                                            {i?.fields['Working with us'] === true ? 'Yes' : 'No'}
                                          </Td>
                                          {/* <Td> */}

                                          {/* <IconButton
                                              aria-label="Search database"
                                              icon={<BiCopy />}
                                              // onClick={onCopy}
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `https://portal.unlockinglanguage.co.uk/school-sign-up?schoolID=${i.id}&schoolType=SENSchool`
                                                );
                                                // setCopied('Copied');
                                              }}
                                            /> */}
                                          {/* </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              View Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              Edit Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              TOMS Rating
                                            </Button>
                                          </Td> */}
                                        </Tr>
                                      );
                                    })}
                                  {}
                                </Tbody>
                                {ssSchools === undefined && (
                                  <TableCaption>Your sessions history</TableCaption>
                                )}
                              </Table>
                            )}
                        </TableContainer>
                      </TabPanel>
                      <TabPanel>
                        <TableContainer>
                          {localStorage.getItem('accountType') !== undefined &&
                            localStorage.getItem('accountType') === 'Admin' && (
                              <Table variant="simple">
                                <Thead>
                                  <Tr>
                                    <Th
                                      style={{
                                        position: 'sticky',
                                        left: '0',
                                        width: '200px',
                                        background: '#edf2f7',
                                        zIndex: 9,
                                        borderTopLeftRadius: 20,
                                      }}
                                    >
                                      <Th>Subscriber Name</Th>
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {subscriber &&
                                    subscriber.map((i: any, k: any) => {
                                      return (
                                        <Tr key={k} style={{ cursor: 'pointer' }}>
                                          <Td
                                            style={{
                                              position: 'sticky',
                                              left: '0',
                                              width: '250px',
                                              background: '#edf2f7',
                                              zIndex: 9,
                                            }}
                                          >
                                            {i.id + ') ' + i?.fields['First name']}
                                          </Td>
                                          <Td>
                                            {' '}
                                            {i?.fields['Last name']}
                                            {/* <IconButton
                                              aria-label="Search database"
                                              icon={<BiCopy />}
                                              // onClick={onCopy}
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  `https://portal.unlockinglanguage.co.uk/school-sign-up?schoolID=${i.id}&schoolType=SENSchool`
                                                );
                                                // setCopied('Copied');
                                              }}
                                            /> */}
                                          </Td>

                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/therapist-session/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              View Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/edit-student/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              Edit Student
                                            </Button>
                                          </Td> */}
                                          {/* <Td>
                                            <Button
                                              bg={'brand.300'}
                                              color={'white'}
                                              _hover={{
                                                background: 'brand.200',
                                              }}
                                              onClick={() => {
                                                tabIndex === 0
                                                  ? navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=RegularSchool'
                                                    )
                                                  : navigate(
                                                      '/toms-rating/' +
                                                        i.id +
                                                        '?schoolType=SENSchool'
                                                    );
                                              }}
                                            >
                                              TOMS Rating
                                            </Button>
                                          </Td> */}
                                        </Tr>
                                      );
                                    })}
                                  {}
                                </Tbody>
                                {subscriber === undefined && (
                                  <TableCaption>Your sessions history</TableCaption>
                                )}
                              </Table>
                            )}
                        </TableContainer>
                      </TabPanel>
                      {/* <TabPanel>
                        <Tabs colorScheme="green">
                          <TabList>
                            <Tab>Case Load Import</Tab>
                            <Tab>Case Note Import</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <EtlProcessCaseLoads />
                            </TabPanel>
                            <TabPanel>
                              <EtlProcessCaseNotes />
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </TabPanel> */}
                      {/* <TabPanel>
                        <Tabs colorScheme="green">
                          <TabList>
                            <Tab>Report Data</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel>
                              <EtlProcessReportTabData />
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </TabPanel> */}
                      <TabPanel>
                        <MessagesContent
                          signedInUserId={signedInUserId}
                          signedInUserEmail={signedInUserEmail}
                          userMeta={userMeta}
                          navWidth={navWidth}
                          setNavWidth={setNavWidth}
                          signedInUserType={signedInUserType}
                          setSignedInUserType={setSignedInUserType}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminContent;
